import { RadioGroup, RadioOption } from '@renderer-ui-library/molecules'
import { Typography } from '@renderer-ui-library/mui'
import { localeNamespace } from '@internal/utils/machine/i18n/Locale'
import { translations } from 'i18n/translations'
import { useTranslation } from 'next-i18next'
import { memo, useMemo } from 'react'
import styles from './notificationFrequencyForm.module.scss'
import {
  NotificationFrequency,
  TNotificationFrequency,
} from '@internal/utils/searchRequest/searchRequest'

type Props = {
  notificationFrequency: TNotificationFrequency
  frequencyFormSubtitle: string
  setNotificationFrequency: (frequency: TNotificationFrequency) => void
}

export const NotificationFrequencyForm = memo(
  ({
    notificationFrequency,
    frequencyFormSubtitle,
    setNotificationFrequency,
  }: Props) => {
    const { t } = useTranslation(localeNamespace.common)
    const translatedFrequency = useMemo(
      () => ({
        daily: t(
          translations.searchRequestPreferenceUpdateFrequencyAnswerDaily
        ),
        weekly: t(
          translations.searchRequestPreferenceUpdateFrequencyAnswerWeekly
        ),
      }),
      [t]
    )

    const options: RadioOption[] = useMemo(
      () => [
        {
          label: translatedFrequency.daily,
          value: NotificationFrequency.Daily,
        },
        {
          label: translatedFrequency.weekly,
          value: NotificationFrequency.Weekly,
        },
      ],
      [translatedFrequency]
    )

    return (
      <div className={styles.formContainer}>
        <div className={styles.subline}>
          <Typography variant='body1' marginTop={'0.5em'} fontSize='inherit'>
            {frequencyFormSubtitle}
          </Typography>
        </div>
        <RadioGroup
          onChange={(value) => {
            setNotificationFrequency(value as TNotificationFrequency)
          }}
          options={options}
          value={notificationFrequency}
        />
      </div>
    )
  }
)

NotificationFrequencyForm.displayName = 'NotificationFrequencyForm'
