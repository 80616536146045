import {
  isValidMachineSubType,
  isValidMachineType,
} from '@internal/utils/machine/isValidMachineType'
import { MachineType, SubTypes } from '@internal/utils/machine/MachineType'

export const getCleanMachineType = (
  machineType: MachineType | string | undefined | null
): MachineType | undefined =>
  !!machineType && isValidMachineType(machineType)
    ? (machineType as MachineType)
    : undefined

export const getCleanMachineSubType = (
  machineSubType: SubTypes[keyof SubTypes] | string | undefined | null,
  machineType: MachineType
): SubTypes[keyof SubTypes] | undefined =>
  machineSubType &&
  isValidMachineSubType(machineSubType as SubTypes[keyof SubTypes], machineType)
    ? (machineSubType as SubTypes[keyof SubTypes])
    : undefined
