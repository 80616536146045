import { SearchModel } from '@internal/blocks/SearchResultsBlock/TSearchParams'
import { getMachinesCount } from '@internal/services/apiClient'
import { MachineType } from '@internal/utils/machine/MachineType'
import { localeNamespace } from '@internal/utils/machine/i18n/Locale'
import { DefaultQueryParams } from '@internal/utils/routing/urls/DefaultQueryParams'
import { TFilterableMachineAttribute } from '@internal/utils/search/TFilterableMachineAttribute'
import { UserContext } from '@internal/utils/user/UserContext'
import { Search } from '@mui/icons-material'
import { Button } from '@renderer-ui-library/atoms'
import {
  BrandSelectFilter,
  MachineTypeSelectFilter,
  TFilterOption,
} from '@renderer-ui-library/molecules'
import { CircularProgress, Grid } from '@renderer-ui-library/mui'
import { OverlayColor } from '@renderer-ui-library/mui/base/useCreateTheme'
import { TRangeFiltersMap } from 'blocks/SearchResultsBlock/TRangeFiltersMap'
import {
  aboveMaxSearchResultsLabel,
  maxSearchResults,
} from 'blocks/SearchResultsBlock/maxSearchResults'
import { rangeFiltersMapToSearchFilters } from 'blocks/SearchResultsBlock/rangeFiltersMapToSearchFilters'
import { useSearchResultsRouting } from 'blocks/SearchResultsBlock/useSearchResultsRouting'
import { translations } from 'i18n/translations'
import isEqual from 'lodash/isEqual'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { NonSERPMachineFilterEvent } from 'utils/tracking/TTrackingEvent'
import { createMachineFilterEvent } from 'utils/tracking/createMachineFilterEvent'
import { tracker } from 'utils/tracking/tracker'
import { ModelSelectFilter } from '../ModelSelectFilter'
import { RangeSelectFilter, RangeSelectValue } from '../RangeSelectFilter'
import { sanitizeAppliedRangeFilters } from '../sanitizeAppliedRangeFilters'
import { useSearchCoverBlockRangeFilters } from '../useSearchCoverBlockRangeFilters'

type Props = {
  brand: string | null
  mappedModel: string | null
  machineType: MachineType
  onClose?: () => void
  colorOverlay: OverlayColor
  placement: NonSERPMachineFilterEvent['filter_placement']
}

export const SearchCoverBlockFields: React.FC<Props> = React.memo((props) => {
  const { t } = useTranslation(localeNamespace.common)
  const [machineType, setMachineType] = useState<MachineType>(props.machineType)

  const [brands, setBrands] = useState<TFilterOption[]>([])
  const [model, setModel] = useState<SearchModel | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [appliedRangeFilters, setAppliedRangeFilters] =
    useState<TRangeFiltersMap>({})
  const [resultsCount, setResultsCount] = useState<number | null>(
    1000 /** just show that we have many of the default machinery type */
  )
  const { getRangeFilters } = useSearchCoverBlockRangeFilters()
  const rangeFilters = getRangeFilters(machineType)
  const { gotoSearchResults } = useSearchResultsRouting()
  const { query } = useRouter()
  const { ip } = query as DefaultQueryParams

  useEffect(() => {
    const sanitizedRangeFilters = sanitizeAppliedRangeFilters(
      rangeFilters,
      appliedRangeFilters
    )

    if (!isEqual(appliedRangeFilters, sanitizedRangeFilters)) {
      setAppliedRangeFilters(sanitizedRangeFilters)
    }
  }, [rangeFilters, appliedRangeFilters])

  useEffect(() => {
    if (props.brand) {
      const newBrands = [
        {
          id: props.brand,
          label: props.brand,
        },
      ]
      setBrands(newBrands)
    }

    if (props.mappedModel) {
      const newModel: SearchModel = {
        name: props.mappedModel,
        type: 'mapped',
      }
      setModel(newModel)
    }
  }, [props.brand, props.mappedModel])

  const [abortController, setAbortController] =
    useState<AbortController | null>(null)
  const { user } = useContext(UserContext)

  const updateSearchResults = useCallback(
    (params: {
      machineType: MachineType
      brands: string[] | null
      appliedRangeFilters: TRangeFiltersMap
      model: SearchModel | null
    }) => {
      if (abortController && !abortController.signal.aborted) {
        abortController.abort()
      }
      setIsLoading(true)

      const newAbortController = new AbortController()
      setAbortController(newAbortController)
      getMachinesCount({
        search: {
          machineType: params.machineType,
          brands: params.brands ? params.brands : undefined,
          model: params.model ?? undefined,
          filters: rangeFiltersMapToSearchFilters(params.appliedRangeFilters),
        },
        abortController: newAbortController,
        userCountry: user?.country ?? null,
        ip: ip ?? null,
        noCache: false,
        refreshCache: false,
      })
        .then(setResultsCount)
        .catch(() => setResultsCount(null))
        .finally(() => setIsLoading(false))
    },
    [abortController, ip, user?.country]
  )

  const onChangeMachineType = useCallback(
    async (machineType: MachineType | undefined) => {
      if (!machineType) {
        return
      }

      setMachineType(machineType)
      setModel(null)
      setBrands([])
      setIsLoading(true)
      updateSearchResults({
        machineType,
        brands: null,
        model: null,
        appliedRangeFilters: sanitizeAppliedRangeFilters(
          getRangeFilters(machineType),
          appliedRangeFilters
        ),
      })
    },
    [updateSearchResults, getRangeFilters, appliedRangeFilters]
  )

  const onChangeBrand = useCallback(
    (brands: TFilterOption[]) => {
      setBrands(brands)

      if (!machineType) {
        return
      }

      updateSearchResults({
        machineType,
        brands: brands.map((brand) => brand.id),
        model: null,
        appliedRangeFilters,
      })
    },
    [machineType, updateSearchResults, appliedRangeFilters]
  )

  const onChangeModel = useCallback(
    (model: SearchModel | null) => {
      setModel(model)
      if (!machineType) {
        return
      }

      updateSearchResults({
        machineType,
        brands: brands.map((brand) => brand.id),
        model: model ?? null,
        appliedRangeFilters,
      })
    },
    [updateSearchResults, machineType, brands, appliedRangeFilters]
  )

  const onChangeRange = useCallback(
    (
      machineAttribute: TFilterableMachineAttribute,
      value: {
        min?: RangeSelectValue
        max?: RangeSelectValue
      }
    ) => {
      const newAppliedRangeFilters: TRangeFiltersMap = {
        ...appliedRangeFilters,
        [machineAttribute]: {
          ...appliedRangeFilters[machineAttribute],
          ...value,
        },
      }

      setAppliedRangeFilters(newAppliedRangeFilters)

      if (!machineType) {
        return
      }

      updateSearchResults({
        machineType,
        brands: brands.map((brand) => brand.id),
        model: model ?? null,
        appliedRangeFilters: newAppliedRangeFilters,
      })
    },
    [updateSearchResults, machineType, brands, model, appliedRangeFilters]
  )

  const handleButtonClick = useCallback(() => {
    if (!machineType) {
      return
    }

    props.onClose?.()

    tracker.trackEvent(
      createMachineFilterEvent(
        {
          name: 'filter-applied',
          filter_placement: props.placement,
          filter_machine_type: machineType,
          filter_model: model ? model.name : undefined,
        },
        {
          filterBrands: brands.map((brand) => brand.id),
          rangeFilters: appliedRangeFilters,
        }
      )
    )
    gotoSearchResults(
      {
        machineType,
        brands: brands.map((brand) => brand.id),
        model: model ?? undefined,
        rangeFilters: appliedRangeFilters,
      },
      false
    )
  }, [
    machineType,
    props,
    brands,
    model,
    appliedRangeFilters,
    gotoSearchResults,
  ])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <MachineTypeSelectFilter
          selectedMachineType={machineType}
          onChange={onChangeMachineType}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <BrandSelectFilter
          selectedMachineType={machineType}
          selectedBrands={brands}
          onChange={onChangeBrand}
          disabled={!machineType}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <ModelSelectFilter
          disabled={brands?.length !== 1 || !machineType}
          colorOverlay={props.colorOverlay}
          onChange={onChangeModel}
          brands={brands}
          model={model}
        />
      </Grid>

      {rangeFilters.map((rangeFilter) => (
        <Grid
          item
          xs={12}
          md={rangeFilters.length === 3 ? 3 : 4}
          key={rangeFilter.machineAttribute}
        >
          <RangeSelectFilter
            onChange={onChangeRange}
            config={rangeFilter}
            min={appliedRangeFilters[rangeFilter.machineAttribute]?.min}
            max={appliedRangeFilters[rangeFilter.machineAttribute]?.max}
            disabled={!machineType}
            testId={`search-cover-block-range-filter-${rangeFilter.machineAttribute}`}
          />
        </Grid>
      ))}

      <Grid item xs={12} md={rangeFilters.length === 3 ? 3 : 4}>
        <Button
          size='large'
          color='secondary'
          fullWidth
          startIcon={
            isLoading ? (
              <CircularProgress size={22} color='inherit' />
            ) : (
              <Search />
            )
          }
          disabled={!machineType}
          onClick={handleButtonClick}
          data-testid='search-cover-block-button'
        >
          {resultsCount === null || !machineType
            ? t(translations.searchCoverBlockButtonTextSearch)
            : t(translations.searchCoverBlockButtonText, {
                count: resultsCount,
                amount:
                  resultsCount >= maxSearchResults
                    ? aboveMaxSearchResultsLabel
                    : resultsCount,
              })}
        </Button>
      </Grid>
    </Grid>
  )
})

SearchCoverBlockFields.displayName = 'SearchCoverBlockFields'
