import { TLocale } from '@internal/utils/machine/i18n/TLocale'
import { MachineType } from '@internal/utils/machine/MachineType'
import { useRouter } from 'next/router'
import { useCallback } from 'react'
import { useMachineTypeName } from 'utils/machine/useMachineTypeName'
import { PageTypeKey } from 'utils/page/PageTypeKey'
import { urlFor } from '../urls/urlFor'

export const useMachineCategoryPageUrl = () => {
  const { getMachineTypeName } = useMachineTypeName()
  const {
    query: { ip },
  } = useRouter()

  const getMachineCategoryPageUrl = useCallback(
    (params: {
      locale: TLocale
      machineType: MachineType
      modelSlug?: string
      brandSlug?: string
    }) =>
      urlFor(params.locale, {
        key: PageTypeKey.MachineCategoryPage,
        machineTypeName: getMachineTypeName(params.machineType, 'plural'),
        modelSlug: params.modelSlug,
        brandSlug: params.brandSlug,
        queryParams: { ip },
      }),
    [getMachineTypeName, ip]
  )

  return { getMachineCategoryPageUrl }
}
