import { MachineType, SubTypes } from '@internal/utils/machine/MachineType'
import { TFilterableBaleChamberOption } from '@internal/utils/search/TFilterableBalerChamberOption'
import { TFilterableHeaderTypeOption } from '@internal/utils/search/TFilterableHeaderTypeOption'
import { TFilterableMachineAttribute } from '@internal/utils/search/TFilterableMachineAttribute'
import { SearchResultsSortKey } from './SearchResultsSortKey'
import { TRangeFiltersMap } from './TRangeFiltersMap'

export type SearchModel = {
  name: string
  type: 'raw' | 'mapped' | 'base'
}

export const mappedModelSuffix = '-mapped'

export type TSearchParams = {
  model?: SearchModel | undefined
  brands: string[]
  machineType: MachineType | undefined
  machineSubType: SubTypes[keyof SubTypes] | undefined
  baleChamber: TFilterableBaleChamberOption | undefined
  headerType: TFilterableHeaderTypeOption | undefined
  amount: number
  features: TFilterableMachineAttribute[]
  rangeFilters: TRangeFiltersMap
  locations: string[]
  sort: SearchResultsSortKey
}
