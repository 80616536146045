import { Machine } from '@internal/utils/machine/Machine'
import { Button } from '@renderer-ui-library/atoms'
import { FormControlLabel, Switch } from '@renderer-ui-library/mui'
import { CompareMachineHeader } from '@renderer-ui-library/organisms'
import { TDisplayDefinitionCategory } from '@internal/utils/displayDefinition/TDisplayDefinitionCategory'
import { TLocale, localeNamespace } from '@internal/utils/machine/i18n/Locale'
import { translations } from 'i18n/translations'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { memo, useCallback, useEffect, useState } from 'react'
import { useMachineUrl } from 'utils/routing/hooks/useMachineUrl'
import { DetailsTable } from './DetailsTable/DetailsTable'
import styles from './compareMachinesDetails.module.scss'

interface Props {
  locale: TLocale
  machines: Machine[]
  removeMachine: (machineGroupId: string) => void
  basicDataDefinition: TDisplayDefinitionCategory[]
  featuresDefinition: TDisplayDefinitionCategory[]
  furtherConfigurationDisplayDefinition: TDisplayDefinitionCategory[]
}

export const CompareMachinesDetails: React.FC<Props> = memo(
  ({
    locale,
    machines,
    removeMachine,
    basicDataDefinition,
    featuresDefinition,
    furtherConfigurationDisplayDefinition,
  }) => {
    const [showOnlyDifferent, setShowOnlyDifferent] = useState(false)
    const { getMachineUrl } = useMachineUrl()
    const { t } = useTranslation(localeNamespace.common)

    useEffect(() => {
      if (machines.length === 1) {
        setShowOnlyDifferent(false)
      }
    }, [machines.length])

    const handleChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) =>
        setShowOnlyDifferent(event.target.checked),
      []
    )

    return (
      <div className={styles.wrapper}>
        <div>
          <div className={styles.slides}>
            <div className={styles.control}>
              <FormControlLabel
                control={
                  <Switch
                    checked={showOnlyDifferent}
                    onChange={handleChange}
                    disabled={machines.length === 1}
                  />
                }
                label={t(translations.compareMachinesToggleLabel)}
              />
            </div>

            <div className={styles.images}>
              {machines.map((machine) => (
                <CompareMachineHeader
                  key={machine.machineGroupId}
                  machine={machine}
                  locale={locale}
                  removeMachine={removeMachine}
                  canRemove={machines.length > 1}
                />
              ))}
            </div>
          </div>

          <div className={styles.content}>
            <div className={styles.container}>
              {basicDataDefinition?.length > 0 && (
                <DetailsTable
                  locale={locale}
                  machines={machines}
                  displayDefinition={basicDataDefinition}
                  title={t(translations.compareMachinesBasicDataTableTitle)}
                  showOnlyDifferent={showOnlyDifferent}
                  showCategoriesTitle={false}
                  noPadding
                  noMargin
                />
              )}

              {featuresDefinition.length > 0 && (
                <DetailsTable
                  locale={locale}
                  machines={machines}
                  displayDefinition={featuresDefinition}
                  title={t(translations.compareMachinesFeaturesTableTitle)}
                  showOnlyDifferent={showOnlyDifferent}
                  showCategoriesTitle
                  noPadding
                  noMargin
                />
              )}

              {furtherConfigurationDisplayDefinition.length > 0 && (
                <DetailsTable
                  locale={locale}
                  machines={machines}
                  displayDefinition={furtherConfigurationDisplayDefinition}
                  title={t(
                    translations.compareMachinesFurtherMachineConfigurationTableTitle
                  )}
                  showOnlyDifferent={showOnlyDifferent}
                  showCategoriesTitle
                  noPadding
                  noMargin
                />
              )}
            </div>
          </div>

          <div className={styles.buttons}>
            <div className={styles.buttonCell} />
            {machines.map((machine, index) => (
              <div className={styles.buttonCell} key={machine.machineGroupId}>
                <Link
                  passHref
                  prefetch={false}
                  href={getMachineUrl(locale, machine)}
                >
                  <Button
                    noMinWidth
                    size='small'
                    component='span'
                    color='secondary'
                    className={styles.button}
                  >
                    {t(translations.searchResultsDetailsLabel)}
                  </Button>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }
)

CompareMachinesDetails.displayName = 'CompareMachinesDetails'
