import { muiClassFixPositionOnModalOpen } from '@internal/utils/constants'
import { useMachineTypeName } from '@internal/utils/machine/useMachineTypeName'
import { PageTypeKey } from '@internal/utils/page/PageTypeKey'
import { urlFor } from '@internal/utils/routing/urls/urlFor'
import { TLocale } from '@internal/utils/machine/i18n/TLocale'
import { MachineType } from '@internal/utils/machine/MachineType'
import classNames from 'classnames'
import React, { useMemo } from 'react'
import { TNavigation } from 'utils/navigation/TNavigation'
import { TSearchParams } from '../SearchOverlay/SearchOverlay'
import styles from './desktopHeader.module.scss'
import { DesktopNavigationBar } from './DesktopNavigationBar/DesktopNavigationBar'
import { DesktopSearchBar } from './DesktopSearchBar/DesktopSearchBar'

interface Props {
  primaryNavigation: TNavigation
  secondaryNavigation: TNavigation
  brandsNavigation: TNavigation
  locale: TLocale
  searchParams: TSearchParams
}

export const DesktopHeader: React.FC<Props> = React.memo((props) => {
  const {
    locale,
    primaryNavigation,
    secondaryNavigation,
    brandsNavigation,
    searchParams,
  } = props

  const { getMachineTypeName } = useMachineTypeName()
  const allOffersUrl = useMemo(
    () =>
      urlFor(locale, {
        key: PageTypeKey.MachineCategoryPage,
        machineTypeName: getMachineTypeName(MachineType.TRACTOR, 'plural'),
      }),
    [getMachineTypeName, locale]
  )

  return (
    <header
      className={classNames(
        styles.headerContainer,
        muiClassFixPositionOnModalOpen
      )}
    >
      <DesktopSearchBar locale={locale} searchParams={searchParams} />
      <DesktopNavigationBar
        locale={locale}
        primaryNavigation={primaryNavigation}
        secondaryNavigation={secondaryNavigation}
        allOffersUrl={allOffersUrl}
        brandsNavigation={brandsNavigation}
      />
    </header>
  )
})

DesktopHeader.displayName = 'DesktopHeader'
