import { Machine } from './Machine'
import { createContext } from 'react'

type TMachineContext = {
  machine: Machine | null
  updateMachine: (machine: Machine | null) => void
}

export const MachineContext = createContext<TMachineContext>({
  machine: null,
  updateMachine: () => {},
})
