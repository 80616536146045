import { TSalesManager } from '@internal/services/TSalesManager'
import React from 'react'
import { SalesManagerContext } from './SalesManagerContext'

interface SalesManagerProviderProps {
  salesManagers: TSalesManager[] | null
  children?: React.ReactNode
}

export const SalesManagerProvider: React.FC<SalesManagerProviderProps> =
  React.memo(({ children, salesManagers }) => (
    <SalesManagerContext.Provider value={{ salesManagers }}>
      {children}
    </SalesManagerContext.Provider>
  ))

SalesManagerProvider.displayName = 'SalesManagerProvider'
