import { Locale } from '@internal/utils/machine/i18n/Locale'
import { MachineType } from '@internal/utils/machine/MachineType'

export const defaultLocale = Locale.en
export const undefinedLocale = 'default'

export const onChangeDebounceTime = 500

export const fallbackMachineType = MachineType.TRACTOR

// Via MaterialUI, scrolling is blocked as soon as a modal is opened. This prevents interacting with the background when the modal
// should be the only interactive content. However, removing the scrollbar can make your fixed positioned elements move.
// In this situation, you can apply a global .mui-fixed class name to tell Material UI to handle those elements.
export const muiClassFixPositionOnModalOpen = 'mui-fixed'
