import { MachineType } from '@internal/utils/machine/MachineType'
import { localeNamespace } from '@internal/utils/machine/i18n/Locale'
import { translations } from 'i18n/translations'
import { useTranslation } from 'next-i18next'
import { useCallback, useMemo } from 'react'
import { PageTypeKey } from 'utils/page/PageTypeKey'
import { SystemPageKey } from './SystemPageKey'
import { TPageData } from './TPageData'
import { TPagePropsMetaData } from './TPagePropsMetaData'

export const usePageDescription = (
  pageData: TPageData,
  meta: TPagePropsMetaData
) => {
  const machine = meta.pdp?.machine
  const { t } = useTranslation(localeNamespace.common)
  const name = machine?.name ?? ''

  const pageDescriptionMapper = useMemo<
    Record<'TRACTOR_AND_HARVESTER' | 'ANY_MACHINE', string>
  >(() => {
    return {
      TRACTOR_AND_HARVESTER: t(translations.metaTractorsCombinesDescription, {
        name,
      }),
      ANY_MACHINE: t(translations.metaAllOtherMachinesDescription, {
        name,
      }),
    }
  }, [name, t])

  const getMachineTypeForPageDescription:
    | 'TRACTOR_AND_HARVESTER'
    | 'ANY_MACHINE' = useMemo(() => {
    switch (machine?.machineType) {
      case MachineType.TRACTOR || MachineType.COMBINE_HARVESTER:
        return 'TRACTOR_AND_HARVESTER'
      default:
        return 'ANY_MACHINE'
    }
  }, [machine?.machineType])

  const getPageDescription = useCallback(
    (description: string) => {
      if (
        pageData.type === PageTypeKey.SystemPage &&
        pageData.key === SystemPageKey.PDP &&
        machine
      ) {
        return pageDescriptionMapper[getMachineTypeForPageDescription]
      }
      return description
    },
    [pageData, machine, getMachineTypeForPageDescription, pageDescriptionMapper]
  )
  return { getPageDescription }
}
