import { TDisplayDefinitionEntryNumber } from '@internal/utils/displayDefinition/entry/DisplayDefinitionEntryNumber'
import { TRangeFiltersMap } from 'blocks/SearchResultsBlock/TRangeFiltersMap'

export const sanitizeAppliedRangeFilters = (
  rangeFilters: TDisplayDefinitionEntryNumber[],
  appliedRangeFilters: TRangeFiltersMap
): TRangeFiltersMap =>
  rangeFilters.reduce<TRangeFiltersMap>((map, filter) => {
    if (filter.machineAttribute in appliedRangeFilters) {
      map[filter.machineAttribute] =
        appliedRangeFilters[filter.machineAttribute]
    }
    return map
  }, {})
