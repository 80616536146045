import { TDisplayDefinitionEntry } from './TDisplayDefinitionEntry'
import { TLocale } from '@internal/utils/machine/i18n/TLocale'
import { displayDefinitionEntryToString } from './displayDefinitionEntryToString'
import { Machine } from '@internal/utils/machine/Machine'

export const displayDefinitionEntriesToStringList = (
  locale: TLocale,
  machine: Machine,
  entries: TDisplayDefinitionEntry[],
  glue: string
) =>
  entries
    .map((entry) =>
      displayDefinitionEntryToString(locale, machine, entry, glue)
    )
    .filter((result) => typeof result === 'string') as string[]
