import { TLocale } from '@internal/utils/machine/i18n/TLocale'
import slugify from 'slugify'
import { TMachineDetailsRouteParams } from './routingTable'
import { getSlugifyOptions } from './slugs/getSlugifyOptions'

export const buildMachineUrlPathParams = (
  locale: TLocale,
  params: {
    machineTypeName: string
    brand: string
    model: string
    machineGroupId: string
  }
): TMachineDetailsRouteParams['pathParams'] => {
  const slugifyOptions = getSlugifyOptions(locale)

  return {
    machineGroupId: params.machineGroupId.toLowerCase(),
    brand: slugify(params.brand, slugifyOptions) || '-',
    model: slugify(params.model, slugifyOptions) || '-',
    machineType: slugify(params.machineTypeName, slugifyOptions),
  }
}
