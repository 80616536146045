import ContactWhiteSVG from '@renderer-ui-library/assets/contact-white.svg'
import { Button } from '@renderer-ui-library/atoms'
import { Typography } from '@renderer-ui-library/mui'
import { localeNamespace } from '@internal/utils/machine/i18n/Locale'
import { translations } from 'i18n/translations'
import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import React, { MouseEvent } from 'react'
import { ImageLoader } from 'utils/media/ImageLoader'
import styles from './multipleFloatingButton.module.scss'

interface Props {
  handleClick: (event: MouseEvent<HTMLButtonElement>) => void
}

export const MultipleFloatingButtonDetailsOverlay: React.FC<Props> = React.memo(
  ({ handleClick }) => {
    const { t } = useTranslation(localeNamespace.common)
    return (
      <div className={styles.mobileDetailsWrapper}>
        <Button
          noMinWidth
          fullWidth
          fullHeight
          size='small'
          color='secondary'
          onClick={handleClick}
          variant='contained'
        >
          <div className={styles.iconContainer}>
            <Image src={ContactWhiteSVG} alt='' loader={ImageLoader} />
          </div>

          <Typography variant='caption'>
            {t(
              translations.contactInformationWizardContactUsTitleAndButtonText
            )}
          </Typography>
        </Button>
      </div>
    )
  }
)

MultipleFloatingButtonDetailsOverlay.displayName =
  'MultipleFloatingButtonDetailsOverlay'
