import { TNavigation } from '@internal/utils/navigation/TNavigation'

import { TLocale } from '@internal/utils/machine/i18n/TLocale'
import classNames from 'classnames'
import React from 'react'
import { allCategoriesNavigationKey } from '../../../NavigationKeys'
import styles from './navigation.module.scss'
import { NavigationItem } from './NavigationItem/NavigationItem'
import { AllMachinesSubnavigation } from './NavigationItem/Subnavigation/AllMachinesSubnavigation'

interface NavigationProps {
  locale: TLocale
  items: TNavigation
  brandsNavigation: TNavigation
  allOffersUrl: string
}

export const Navigation: React.FC<NavigationProps> = (props) => {
  const { locale, items, allOffersUrl, brandsNavigation } = props
  return (
    <nav className={styles.navigation}>
      {items.entries.map((entry, index) => {
        if (entry.key === allCategoriesNavigationKey) {
          return (
            <div className={classNames(styles.entry)} key={entry.id}>
              <AllMachinesSubnavigation
                locale={locale}
                brandsNavigation={brandsNavigation}
                allOffersUrl={allOffersUrl}
                entry={entry}
              />
            </div>
          )
        }
        return (
          <div
            className={classNames(styles.entry, {
              [styles.hideOnLargeDesktop]: index > 2,
              [styles.hideOnSmallDesktop]: index > 1,
            })}
            key={entry.id}
          >
            <NavigationItem locale={locale} entry={entry} />
          </div>
        )
      })}
    </nav>
  )
}

Navigation.displayName = 'Navigation'
