import { TSalesManager } from '@internal/services/TSalesManager'
import { ImageLoader } from '@internal/utils/media/ImageLoader'
import { Button } from '@renderer-ui-library/atoms'
import {
  AgreementText,
  CountryCodeAutoComplete,
  TopicsChecklist,
} from '@renderer-ui-library/molecules'
import MachineAlertItaly from '@renderer-ui-library/molecules/MachineAlertItaly/MachineAlertItaly'
import { useIsNotRegisterableInItaly } from '@renderer-ui-library/molecules/MachineAlertItaly/useIsNotRegisterableInItaly'
import {
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from '@renderer-ui-library/mui'
import { localeNamespace } from '@internal/utils/machine/i18n/Locale'
import { translations } from 'i18n/translations'
import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import React, { ChangeEvent, FormEvent, SyntheticEvent } from 'react'
import { IForms } from 'services/convertFormsPayload'
import { CountryCodeType } from 'utils/contact/phoneCountryCodes'
import { getResponsiveSmallMediaSrc } from 'utils/media/getMediaSrc'
import SandClock from '../../assets/sand-clock-timer.gif'
import styles from './callBackWizard.module.scss'

export interface TCallBackDataState {
  areaCode: CountryCodeType
  phone: number | string
  topic: string
  smsAgreement: boolean
  firstName?: string
  lastName?: string
}

interface Props {
  contact: TSalesManager
  data: TCallBackDataState
  onSubmit: (e: FormEvent<HTMLFormElement> | undefined) => void
  onChange: (e: ChangeEvent) => void
  onBlur: (e: ChangeEvent) => void
  onChangeCountryCode: (
    e: SyntheticEvent,
    newValue: CountryCodeType | null
  ) => void
  onTopicChange: (newValue: string) => void
  isValid: boolean
  phoneError: string | false | undefined
  topicError: string | false | undefined
  firstNameError: string | false | undefined
  lastNameError: string | false | undefined
  isMobile: boolean
  title: string
  description: string
  callBackTopics: IForms['callBackTopics']
  includeNameFields?: boolean
}

export const CallBackFormWizard: React.FC<Props> = ({
  contact,
  title,
  onSubmit,
  description,
  data,
  onChangeCountryCode,
  onChange,
  onBlur,
  phoneError,
  firstNameError,
  lastNameError,
  isMobile,
  isValid,
  callBackTopics,
  topicError,
  onTopicChange,
  includeNameFields,
}) => {
  const { t } = useTranslation(localeNamespace.common)
  const isNotRegisterableInItaly = useIsNotRegisterableInItaly()

  return (
    <>
      <div className={styles.contactWrapper}>
        <div className={styles.imageWrapper}>
          <img
            loading='lazy'
            key={contact.avatarImage.data.id}
            alt={contact.avatarImage.data.attributes.alternativeText}
            {...getResponsiveSmallMediaSrc(contact.avatarImage, {
              ratio: 1,
            })}
          />
        </div>
        <div className={styles.contactInfo}>
          <Typography variant='subtitle2' component='b' color='textSecondary'>
            {`${contact.firstName} ${contact.lastName}`}
            <Typography
              variant='subtitle2'
              component='span'
              color='textDisable'
            >
              {` / ${contact.jobTitle}`}
            </Typography>
          </Typography>

          <Typography variant='h4' color='textPrimary'>
            {title}
          </Typography>
        </div>
      </div>

      {isNotRegisterableInItaly && <MachineAlertItaly />}

      <form onSubmit={onSubmit}>
        <Typography variant='body1' color='textPrimary'>
          {description}
        </Typography>
        {includeNameFields && (
          <>
            <div className={styles.input}>
              <TextField
                size='small'
                id='wizard-first-name'
                name='firstName'
                label={t(
                  translations.requestInformationWizardContactFirstNameLabel
                )}
                value={data.firstName}
                onChange={onChange}
                onBlur={onBlur}
                variant='outlined'
                error={!!firstNameError}
                helperText={firstNameError}
                fullWidth
                autoComplete='given-name'
                required
              />
            </div>
            <div className={styles.input}>
              <TextField
                size='small'
                id='wizard-last-name'
                name='lastName'
                label={t(
                  translations.requestInformationWizardContactLastNameLabel
                )}
                value={data.lastName}
                onChange={onChange}
                onBlur={onBlur}
                variant='outlined'
                error={!!lastNameError}
                helperText={lastNameError}
                fullWidth
                autoComplete='family-name'
                required
              />
            </div>
          </>
        )}

        <div className={styles.inputGroupPhone}>
          <div className={styles.inputCountryCode}>
            <CountryCodeAutoComplete
              areaCode={data.areaCode}
              onChange={onChangeCountryCode}
            />
          </div>

          <div className={styles.inputPhone}>
            <TextField
              size='small'
              id='wizard-phone'
              name='phone'
              label={t(translations.requestInformationWizardContactPhoneLabel)}
              value={data.phone}
              onChange={onChange}
              onBlur={onBlur}
              variant='outlined'
              fullWidth
              type='number'
              autoComplete='tel-national'
              error={!!phoneError}
              helperText={phoneError}
              required
            />
          </div>
        </div>
        <div className={styles.input}>
          <FormControlLabel
            control={
              <Checkbox
                name='smsAgreement'
                id='smsAgreement'
                style={{ marginRight: 8 }}
                checked={data.smsAgreement}
                onChange={onChange}
              />
            }
            label={t(translations.newsletterWizardSMSAgreement).toString()}
            componentsProps={{ typography: { variant: 'caption' } }}
          />
        </div>

        <div className={styles.expectCall}>
          <Image
            loading='lazy'
            src={SandClock.src}
            alt=''
            width={24}
            height={24}
            loader={ImageLoader}
          />
          <Typography variant='body2' color='textSecondary'>
            {t(translations.requestCallBackWizardExpectCallBack)}*
          </Typography>
        </div>
        <TopicsChecklist
          callBackTopics={callBackTopics}
          topic={data.topic}
          topicError={topicError}
          onTopicChange={onTopicChange}
        />
        <AgreementText />

        <div className={styles.callBackFooter}>
          <div className={styles.disclaimerWrapper}>
            <Typography variant='body2' color='textSecondary'>
              * {t(translations.requestCallBackWizardExpectDelayHint)}
            </Typography>
            <Typography variant='body2' color='textSecondary'>
              {t(translations.requestCallBackWizardWorkingHoursText)}
            </Typography>
          </div>

          <Button
            type='submit'
            color='secondary'
            noMinWidth
            fullWidth={isMobile}
            disabled={!isValid}
          >
            {t(translations.requestCallBackWizardButton)}
          </Button>
        </div>
      </form>
    </>
  )
}

CallBackFormWizard.displayName = 'CallBackFormWizard'
