import { TDisplayDefinitionEntryNumber } from '@internal/utils/displayDefinition/entry/DisplayDefinitionEntryNumber'
import { DisplayDefinitionEntryType } from '@internal/utils/displayDefinition/entry/DisplayDefinitionEntryType'
import { localeNamespace } from '@internal/utils/machine/i18n/Locale'
import { KnownMachineAttribute } from '@internal/utils/machine/KnownMachineAttribute'
import { MachineType } from '@internal/utils/machine/MachineType'
import { translations } from 'i18n/translations'
import { useTranslation } from 'next-i18next'
import { useCallback, useMemo } from 'react'

const machineTypesWithHP = new Set([
  MachineType.ANIMAL_HUSBANDRY_EQUIPMENT,
  MachineType.BEET_HARVESTING_EQUIPMENT,
  MachineType.CHASER_BIN,
  MachineType.COMBINE_HARVESTER,
  MachineType.COMPLEMENTARY_SEEDER,
  MachineType.CONCRETE_MIXER,
  MachineType.CONSTRUCTION_MACHINERY_OTHER,
  MachineType.CUTTERS_FLAIL_MOWER_OTHER,
  MachineType.DIRECT_SOWING_MACHINE,
  MachineType.DRAINAGE,
  MachineType.DRILLING_MACHINE_COMBINATION,
  MachineType.DUMPER,
  MachineType.EXCAVATOR,
  MachineType.EXCAVATORS_OTHER,
  MachineType.FEEDER_OTHER,
  MachineType.FERTILISER_SPREADER,
  MachineType.FORAGE_HARVESTER_SELF_PROPELLED,
  MachineType.FORAGE_HARVESTER_TRAILED_MOUNTED,
  MachineType.FORAGE_TRANSPORT,
  MachineType.FORESTRY_EQUIPMENT,
  MachineType.FORESTRY_HARVESTER,
  MachineType.FORESTRY_TRAILER,
  MachineType.FORKLIFT,
  MachineType.FORWARDER,
  MachineType.GRAIN_STORAGE_HANDLING,
  MachineType.GRAPE_HARVESTER,
  MachineType.HEDGE_MOWER,
  MachineType.HOP_EQUIPMENT,
  MachineType.IRRIGATION,
  MachineType.LAWN_MOWER,
  MachineType.MECHANICAL_WEED_CONTROL,
  MachineType.MIXER_FEEDERS,
  MachineType.MOTOR_VEHICLE,
  MachineType.MUNICIPAL_WINTER_SERVICE_EQUIPMENT,
  MachineType.ORCHARD_AND_VINEYARD_EQUIPMENT_OTHER,
  MachineType.PLANTER,
  MachineType.POTATO_EQUIPMENT,
  MachineType.POTATO_HARVESTER,
  MachineType.ROAD_ROLLER,
  MachineType.SAWS_AND_SPLITTERS,
  MachineType.SELF_PROPELLED_LIQUID_MANURE_SPREADER,
  MachineType.SELF_PROPELLED_PLATFORM,
  MachineType.SILAGE_CUTTERS_FEEDER,
  MachineType.SKID_STEER_LOADER,
  MachineType.SLURRY_ATTACHMENT,
  MachineType.SLURRY_EQUIPMENT_OTHER,
  MachineType.SLURRY_LOGISTIC,
  MachineType.SLURRY_TANKER,
  MachineType.SOWING_MACHINE_OTHER,
  MachineType.STONE_CRUSHER,
  MachineType.TANKER,
  MachineType.TELE_WHEEL_LOADER,
  MachineType.TELEHANDLER,
  MachineType.TOPPER,
  MachineType.TRACTOR,
  MachineType.VEGETABLE_CLEANING_PROCESSING_EQUIPMENT,
  MachineType.VEGETABLE_HARVESTER,
  MachineType.VERGE_MOWER,
  MachineType.VIBRATING_PLATE,
  MachineType.WHEEL_LOADER,
  MachineType.WOOD_CHIPPER_GRINDER,
])

const rangeFilterAttributes = {
  hp: KnownMachineAttribute.ENGINE_HP,
  year: KnownMachineAttribute.YEAR_OF_PRODUCTION,
  price: KnownMachineAttribute.PRICE_NET_EUR,
}

export const useSearchCoverBlockRangeFilters = () => {
  const { t } = useTranslation(localeNamespace.common)

  const rangeFilterYear = useMemo<TDisplayDefinitionEntryNumber>(
    () => ({
      type: DisplayDefinitionEntryType.Number,
      label: t(translations.searchResultsFiltersYearLabel),
      machineAttribute: rangeFilterAttributes.year,
    }),
    [t]
  )

  const rangeFilterHP = useMemo<TDisplayDefinitionEntryNumber>(
    () => ({
      type: DisplayDefinitionEntryType.Number,
      label: t(translations.searchResultsFiltersPowerLabel),
      machineAttribute: rangeFilterAttributes.hp,
    }),
    [t]
  )

  const rangeFilterPrice = useMemo<TDisplayDefinitionEntryNumber>(
    () => ({
      type: DisplayDefinitionEntryType.Number,
      label: t(translations.searchResultsFiltersPriceLabel),
      machineAttribute: rangeFilterAttributes.price,
    }),
    [t]
  )

  const rangeFiltersWithHP = useMemo(
    () => [rangeFilterYear, rangeFilterHP, rangeFilterPrice],
    [rangeFilterYear, rangeFilterHP, rangeFilterPrice]
  )

  const rangeFiltersWithoutHP = useMemo(
    () => [rangeFilterYear, rangeFilterPrice],
    [rangeFilterYear, rangeFilterPrice]
  )

  const getRangeFilters = useCallback(
    (machineType: MachineType | null) => {
      if (!machineType || machineTypesWithHP.has(machineType)) {
        return rangeFiltersWithHP
      }

      return rangeFiltersWithoutHP
    },
    [rangeFiltersWithHP, rangeFiltersWithoutHP]
  )

  return { getRangeFilters, rangeFilterAttributes }
}
