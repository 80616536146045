import {
  FinancingData,
  FinancingProvider,
} from '@internal/utils/machine/leasing'
import { ImageLoader } from '@internal/utils/media/ImageLoader'
import DllLogo from '@renderer-ui-library/assets/companyLogos/dllLogo.png'
import EFarmFinancingLogo from '@renderer-ui-library/assets/companyLogos/efarmFinancing.svg'
import { Button } from '@renderer-ui-library/atoms'
import { Typography } from '@renderer-ui-library/mui'
import { ContactWizardContext } from '@renderer-ui-library/organisms'
import { localeNamespace } from '@internal/utils/machine/i18n/Locale'
import { translations } from 'i18n/translations'
import { useTranslation } from 'next-i18next'
import Image, { StaticImageData } from 'next/image'
import { useCallback, useContext } from 'react'
import { tracker } from 'utils/tracking/tracker'
import styles from './leasingQualifiedButton.module.scss'

type Props = {
  financingData: FinancingData
}

const Logos: Record<FinancingProvider, StaticImageData> = {
  [FinancingProvider.Dll]: DllLogo,
  [FinancingProvider.EFarm]: EFarmFinancingLogo,
}

export const LeasingQualifiedButton = (props: Props) => {
  const { t } = useTranslation(localeNamespace.common)
  const { openLeasingOption } = useContext(ContactWizardContext)

  const handleButtonClick = useCallback(() => {
    tracker.trackEvent({
      name: 'click',
      target_name: 'contact.pdp.financing',
      is_interaction: true,
    })
    openLeasingOption()
  }, [openLeasingOption])

  const { financingData } = props

  const img = Logos[financingData.provider]

  return (
    <div className={styles.container}>
      <div className={styles.logoWrapper}>
        <Image
          src={img.src}
          alt={financingData.provider}
          width={159}
          height={51}
          loader={ImageLoader}
        />

        <Typography className={styles.leasingText} variant='body1'>
          {t(translations.leasingQualifiedText)}
        </Typography>
      </div>
      {false && (
        <Typography variant='subtitle2' paddingBottom={2}>
          <span className={styles.leasingPriceInfoIcon}>*</span>
          {t(translations.leasingDisclaimerText)}
        </Typography>
      )}
      <div className={styles.buttonContainer}>
        <Button
          color='info'
          variant='outlined'
          fullWidth
          className={styles.button}
          onClick={handleButtonClick}
        >
          {t(translations.leasingQualifiedButtonCTA)}
        </Button>
      </div>
    </div>
  )
}
