import { TLocale } from '@internal/utils/machine/i18n/TLocale'
import { SystemPageKey } from 'page/SystemPageKey'
import { urlFor } from './urlFor'

const fallbackStartPageUrlGenerator = (locale: TLocale) =>
  urlFor(locale, {
    key: SystemPageKey.StartPage,
  })

const urlGenerators: Record<SystemPageKey, (locale: TLocale) => string> = {
  [SystemPageKey.StartPage]: fallbackStartPageUrlGenerator,
  [SystemPageKey.PDP]: fallbackStartPageUrlGenerator,
  [SystemPageKey.NotFound]: fallbackStartPageUrlGenerator,
  [SystemPageKey.InternalServerError]: fallbackStartPageUrlGenerator,
  [SystemPageKey.CompareMachines]: (locale) =>
    urlFor(locale, {
      key: SystemPageKey.CompareMachines,
      pathParams: {},
      queryParams: {},
    }),
  [SystemPageKey.SearchResults]: fallbackStartPageUrlGenerator,
}

export const urlForSystemPageWithoutParams = (
  locale: TLocale,
  systemPageKey: SystemPageKey
) => urlGenerators[systemPageKey](locale)
