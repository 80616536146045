import { TSalesManager } from '@internal/services/TSalesManager'
import { MailOutline } from '@mui/icons-material'
import { Button, Chip } from '@renderer-ui-library/atoms'
import { BoxContainer } from '@renderer-ui-library/molecules'
import { Typography } from '@renderer-ui-library/mui'
import { localeNamespace } from '@internal/utils/machine/i18n/Locale'
import { TLocale } from '@internal/utils/machine/i18n/TLocale'
import { contactPageRoutes } from 'i18n/staticPageRoutes'
import { translations } from 'i18n/translations'
import { useTranslation } from 'next-i18next'
import { getMediaSrc } from 'utils/media/getMediaSrc'
import styles from './noResultsContact.module.scss'

type Props = {
  contact: TSalesManager
  locale: TLocale
}

export const NoResultsContact: React.FC<Props> = ({ contact, locale }) => {
  const { t } = useTranslation(localeNamespace.common)

  return (
    <BoxContainer>
      <Typography variant='h4'>
        {t(translations.searchResultsNoResultsContactHeadline)}
      </Typography>
      <Typography color='textSecondary'>
        {t(translations.searchResultsNoResultsContactSubtitle)}
      </Typography>

      <div className={styles.person}>
        <div className={styles.imageWrapper}>
          <img
            loading='lazy'
            className={styles.image}
            key={contact.avatarImage.data.id}
            alt={contact.avatarImage.data.attributes.alternativeText}
            {...getMediaSrc(contact.avatarImage, {
              ratio: 1 / 1,
            })}
          />
        </div>
        <div>
          <Typography variant='subtitle1'>
            {contact.firstName} {contact.lastName}
          </Typography>
          <Chip
            label={t(translations.searchResultsNoResultsContactLanguage)}
            variant='outlined'
            size='small'
          />
        </div>
      </div>
      {contact.email && (
        <Button
          variant='outlined'
          href={contactPageRoutes[locale]}
          startIcon={<MailOutline />}
        >
          {t(translations.searchResultsNoResultsContactButton)}
        </Button>
      )}
    </BoxContainer>
  )
}
