import { translations } from '@internal/i18n/translations'
import { SystemPageKey } from '@internal/page/SystemPageKey'
import { suggest } from '@internal/services/apiClient'
import { useMachineTypeName } from '@internal/utils/machine/useMachineTypeName'
import { buildMachineUrlPathParams } from '@internal/utils/routing/buildMachineUrlPathParams'
import { getSearchResultsUrl } from '@internal/utils/routing/getSearchResultsUrl'
import { urlFor } from '@internal/utils/routing/urls/urlFor'
import { AutocompleteRenderInputParams } from '@mui/material'
import { RemoteAutoComplete } from '@renderer-ui-library/molecules/RemoteAutocomplete/RemoteAutoComplete'
import { SelectFilterOption } from '@renderer-ui-library/molecules/RemoteAutocomplete/SelectFilterOption'
import { localeNamespace } from '@internal/utils/machine/i18n/Locale'
import { TLocale } from '@internal/utils/machine/i18n/TLocale'
import { MachineType } from '@internal/utils/machine/MachineType'
import uniqBy from 'lodash/uniqBy'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useCallback } from 'react'
import { SearchInput } from '../../SearchInput/SearchInput'

type Props = {
  locale: TLocale
}

const getCountLabel = (count: number) => {
  if (count === 0) return '(0)'
  const roundedCount = Math.max(0, Math.floor((count - 1) / 10) * 10)
  return count > 9 ? `(${roundedCount}+)` : `(~${count})`
}

export const SearchAutosuggest: React.FC<Props> = ({ locale }) => {
  const { t } = useTranslation(localeNamespace.common)
  const { getMachineTypeName } = useMachineTypeName()
  const { push } = useRouter()

  const getLabel = useCallback(
    (options: {
      brand?: string
      model?: string
      machineType?: MachineType
      machineGroupId?: string
      count?: number
    }) => {
      const brandLabel = options.brand ? `${options.brand} ` : ''
      const modelLabel = options.model ? `${options.model} ` : ''
      const machineTypeLabel = options.machineType
        ? `${getMachineTypeName(options.machineType, 'plural')} `
        : ''
      const countLabel =
        typeof options.count === 'number' ? getCountLabel(options.count) : ''
      const machineGroupIdLabel = options.machineGroupId
        ? `[ID: ${options.machineGroupId}] `
        : ''
      return `${brandLabel}${modelLabel}${machineTypeLabel}${countLabel}${machineGroupIdLabel}`
    },
    [getMachineTypeName]
  )

  const getUrl = useCallback(
    (options: {
      baseModel?: string
      model?: string
      brand?: string
      machineType?: MachineType
    }) =>
      getSearchResultsUrl(locale, {
        baseModel: options.baseModel,
        model: options.model
          ? { name: options.model, type: 'mapped' }
          : undefined,
        brands: options.brand ? [options.brand] : [],
        machineTypeName: getMachineTypeName(
          options.machineType ? options.machineType : MachineType.TRACTOR,
          'plural'
        ),
      }),
    [getMachineTypeName, locale]
  )

  const getMachineUrl = useCallback(
    (options: {
      machineGroupId: string
      model: string
      brand: string
      machineType: MachineType
    }) =>
      urlFor(locale, {
        key: SystemPageKey.PDP,
        pathParams: {
          ...buildMachineUrlPathParams(locale, {
            machineGroupId: options.machineGroupId,
            brand: options.brand,
            model: options.model,
            machineTypeName: getMachineTypeName(options.machineType, 'plural'),
          }),
        },
        queryParams: {},
      }),
    [getMachineTypeName, locale]
  )

  const handleChange = useCallback(
    (value: string | SelectFilterOption | null) => {
      if (!value) {
        return
      }
      if (typeof value === 'string') {
        push(value)
      } else {
        push(value.value)
      }
    },
    [push]
  )

  const loadValue = useCallback(
    async (
      input: string,
      abortSignal: AbortSignal
    ): Promise<SelectFilterOption[]> => {
      return suggest(input, locale, abortSignal).then((res) => {
        const entries: SelectFilterOption[] = []
        if (res.idMatch.length > 0) {
          const model =
            res.idMatch[0].machine.MODEL ||
            res.idMatch[0].machine.BASE_MODEL ||
            res.idMatch[0].machine.RAW_MODEL

          entries.push(
            ...res.idMatch.map((idMatch) => ({
              label: getLabel({
                brand: idMatch.machine.SEARCH_BRAND,
                model: model,
                machineGroupId: idMatch.machine.MACHINE_GROUP_ID,
              }),
              value: getMachineUrl({
                brand: idMatch.machine.SEARCH_BRAND,
                model: model,
                machineGroupId: idMatch.machine.MACHINE_GROUP_ID,
                machineType: idMatch.machine.MACHINE_TYPE as MachineType,
              }),
            }))
          )
        }
        if (res.modelMatch) {
          entries.push({
            label: getLabel({
              brand: res.modelMatch.BRAND,
              model:
                'MODEL' in res.modelMatch
                  ? res.modelMatch.MODEL
                  : res.modelMatch.BASE_MODEL,
              count: res.modelMatch.count,
            }),
            value: getUrl({
              baseModel: res.modelMatch.BASE_MODEL,
              model:
                'MODEL' in res.modelMatch ? res.modelMatch.MODEL : undefined,
              brand: res.modelMatch.BRAND,
              machineType: res.modelMatch.MACHINE_TYPE as
                | MachineType
                | undefined,
            }),
          })
        }
        if (res.modelSuggestions) {
          entries.push(
            ...res.modelSuggestions.map((modelSuggestion) => ({
              label: getLabel({
                brand: modelSuggestion.BRAND,
                model: modelSuggestion.MODEL,
                count: modelSuggestion.count,
              }),
              value: getUrl({
                brand: modelSuggestion.BRAND,
                model: modelSuggestion.MODEL,
              }),
            }))
          )
        }
        if (res.brandAndMachineTypeMatch) {
          entries.push({
            label: getLabel({
              brand: res.brandAndMachineTypeMatch.BRAND,
              machineType: res.brandAndMachineTypeMatch
                .MACHINE_TYPE as MachineType,
              count: res.brandAndMachineTypeMatch.count,
            }),
            value: getUrl({
              brand: res.brandAndMachineTypeMatch.BRAND,
              machineType: res.brandAndMachineTypeMatch
                .MACHINE_TYPE as MachineType,
            }),
          })
        }
        if (res.brandMatch) {
          entries.push({
            label: getLabel({
              brand: res.brandMatch.BRAND,
              count: res.brandMatch.count,
            }),
            value: getUrl({
              brand: res.brandMatch.BRAND,
            }),
          })
        }
        if (res.topMachineTypesByBrand) {
          entries.push(
            ...res.topMachineTypesByBrand.map((machineType) => ({
              label: getLabel({
                brand: machineType.BRAND,
                machineType: machineType.MACHINE_TYPE as MachineType,
                count: machineType.count,
              }),
              value: getUrl({
                brand: machineType.BRAND,
                machineType: machineType.MACHINE_TYPE as MachineType,
              }),
            }))
          )
        }
        if (res.brandSuggestions) {
          entries.push(
            ...res.brandSuggestions.map((suggestion) => ({
              label: getLabel({
                brand: suggestion.BRAND,
                count: suggestion.count,
              }),
              value: getUrl({
                brand: suggestion.BRAND,
              }),
            }))
          )
        }
        if (!res.brandAndMachineTypeMatch && res.machineTypeMatch) {
          entries.push({
            label: getLabel({
              machineType: res.machineTypeMatch.MACHINE_TYPE as MachineType,
              count: res.machineTypeMatch.count,
            }),
            value: getUrl({
              machineType: res.machineTypeMatch.MACHINE_TYPE as MachineType,
            }),
          })
        }
        if (res.topBrandsByMachineType) {
          entries.push(
            ...res.topBrandsByMachineType.map((brand) => ({
              label: getLabel({
                brand: brand.BRAND,
                machineType: brand.MACHINE_TYPE as MachineType,
                count: brand.count,
              }),
              value: getUrl({
                brand: brand.BRAND,
                machineType: brand.MACHINE_TYPE as MachineType,
              }),
            }))
          )
        }
        if (res.machineTypeSuggestions) {
          entries.push(
            ...res.machineTypeSuggestions.map((suggestion) => ({
              label: getLabel({
                machineType: suggestion.MACHINE_TYPE as MachineType,
                count: suggestion.count,
              }),
              value: getUrl({
                machineType: suggestion.MACHINE_TYPE as MachineType,
              }),
            }))
          )
        }
        if (res.modelSearch) {
          entries.push({
            label: t(translations.keywordSearchLabel, {
              value: res.modelSearch.query,
            }),
            value: '/', // TODO Implement actual url for search by query
          })
        }

        return uniqBy(entries, (e) => e.label)
      })
    },
    [getLabel, getMachineUrl, getUrl, locale, t]
  )

  // TODO Enable icon button click to trigger search by query
  const renderInput = useCallback(
    (params: AutocompleteRenderInputParams) => (
      <SearchInput
        locale={locale}
        containerRef={params.InputProps.ref}
        inputProps={params.inputProps}
      />
    ),
    [locale]
  )

  return (
    <RemoteAutoComplete<SelectFilterOption>
      type='single'
      value={null}
      onChange={handleChange}
      loadValues={loadValue}
      renderInput={renderInput}
      inputPlaceholder={'Test'}
      freeSolo
      clearOnBlur
      grouping
      listboxProps={{
        height: 550,
      }}
      transparentBackground
    />
  )
}
