import { Banner } from '@internal/utils/banner/Banner'
import { useMediaQuery } from '@renderer-ui-library/mui/utils/mediaQuery/useMediaQuery'
import { TLocale } from '@internal/utils/machine/i18n/TLocale'
import React from 'react'
import { TNavigation } from 'utils/navigation/TNavigation'
import { AuctionBanner } from './AuctionBanner/AuctionBanner'
import { DesktopHeader } from './DesktopHeader/DesktopHeader'
import { GenericBanner } from './GenericBanner/GenericBanner'
import { MobileHeader } from './MobileHeader/MobileHeader'
import { TSearchParams } from './SearchOverlay/SearchOverlay'
interface HeaderProps {
  primaryNavigation: TNavigation
  secondaryNavigation: TNavigation
  footerNavigation: TNavigation
  brandsNavigation: TNavigation
  locale: TLocale
  searchParams: TSearchParams
  banner: Banner | null
}

export const Header: React.FC<HeaderProps> = React.memo((props) => {
  const {
    locale,
    primaryNavigation,
    secondaryNavigation,
    footerNavigation,
    brandsNavigation,
    searchParams,
    banner,
  } = props

  const { isMobile } = useMediaQuery()

  return (
    <>
      {isMobile && (
        <MobileHeader
          primaryNavigation={primaryNavigation}
          secondaryNavigation={secondaryNavigation}
          footerNavigation={footerNavigation}
          brandsNavigation={brandsNavigation}
          locale={locale}
          searchParams={searchParams}
        />
      )}
      {!isMobile && (
        <DesktopHeader
          primaryNavigation={primaryNavigation}
          secondaryNavigation={secondaryNavigation}
          brandsNavigation={brandsNavigation}
          locale={locale}
          searchParams={searchParams}
        />
      )}
      <AuctionBanner locale={props.locale} />
      {banner && <GenericBanner banner={banner} />}
    </>
  )
})

Header.displayName = 'Header'
