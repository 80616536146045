import { Locale } from '@internal/utils/machine/i18n/Locale'
import { TLocale } from '@internal/utils/machine/i18n/TLocale'
import { MachineType } from '@internal/utils/machine/MachineType'
import bgJson from './machineTypeNames/bg.json'
import csJson from './machineTypeNames/cs.json'
import daJson from './machineTypeNames/da.json'
import deJson from './machineTypeNames/de.json'
import elJson from './machineTypeNames/el.json'
import enJson from './machineTypeNames/en.json'
import esJson from './machineTypeNames/es.json'
import frJson from './machineTypeNames/fr.json'
import huJson from './machineTypeNames/hu.json'
import itJson from './machineTypeNames/it.json'
import ltJson from './machineTypeNames/lt.json'
import plJson from './machineTypeNames/pl.json'
import roJson from './machineTypeNames/ro.json'
import ruJson from './machineTypeNames/ru.json'
import slJson from './machineTypeNames/sl.json'
import srJson from './machineTypeNames/sr.json'

const translations: Record<
  TLocale,
  Record<MachineType, { one: string; many: string }>
> = {
  [Locale.bg]: bgJson,
  [Locale.cs]: csJson,
  [Locale.da]: daJson,
  [Locale.de]: deJson,
  [Locale.el]: elJson,
  [Locale.en]: enJson,
  [Locale.es]: esJson,
  [Locale.fr]: frJson,
  [Locale.hu]: huJson,
  [Locale.it]: itJson,
  [Locale.lt]: ltJson,
  [Locale.pl]: plJson,
  [Locale.ro]: roJson,
  [Locale.ru]: ruJson,
  [Locale.sl]: slJson,
  [Locale.sr]: srJson,
}

// we don't need server side translations for anythingelse than for machine types
// but I want to keep this code in case we need it in the future
// const getServerSideTranslations = (
//   locale: TLocale,
//   machineType: MachineType,
//   options?: { count?: number; [key: string]: number | string | undefined }
// ) => {
//   const count = options?.count ?? Plural

//   let translation =
//     count === Singular
//       ? translations[locale][machineType].one
//       : translations[locale][machineType].other

//   for (let key in options) {
//     const value = options[key]
//     if (key === 'count' || value === undefined) {
//       continue
//     }

//     translation = translation.replace(`{{${key}}}`, `${value}`)
//   }

//   return translation
// }

export const getSSRMachineTypeName = (
  locale: TLocale,
  machineType: MachineType,
  type: 'singular' | 'plural'
) => {
  const value =
    type === 'singular'
      ? translations[locale][machineType].one
      : translations[locale][machineType].many
  return value
}
