import { TSalesManager } from '@internal/services/TSalesManager'
import { createContext } from 'react'

interface SalesManagerContext {
  salesManagers: TSalesManager[] | null
}

export const SalesManagerContext = createContext<SalesManagerContext>({
  salesManagers: null,
})
