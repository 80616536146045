import { translations } from '@internal/i18n/translations'
import { useEnterPressHandler } from '@internal/utils/keyboardEvents/useEnterPressHandler'
import { useMachineTypeName } from '@internal/utils/machine/useMachineTypeName'
import { Search } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { localeNamespace } from '@internal/utils/machine/i18n/Locale'
import { TLocale } from '@internal/utils/machine/i18n/TLocale'
import { MachineType } from '@internal/utils/machine/MachineType'
import { useTranslation } from 'next-i18next'
import React, { InputHTMLAttributes, Ref, useCallback, useMemo } from 'react'
import styles from './searchInput.module.scss'
import { useTypewriterEffect } from './useTypewriterEffect'

interface SearchInputProps {
  locale: TLocale
  containerRef: Ref<any>
  inputProps?: InputHTMLAttributes<HTMLInputElement>
}

export const SearchInput: React.FC<SearchInputProps> = (props) => {
  const { locale, containerRef, inputProps } = props
  const { t } = useTranslation(localeNamespace.common)
  const { getMachineTypeName, lowerCaseMachineTypeName } = useMachineTypeName()

  const handleSearch = useCallback(() => {
    console.log('search')
  }, [])

  const { handleEnterPress } = useEnterPressHandler(handleSearch, [
    handleSearch,
  ])

  const placeholderTranslation = t(translations.searchInputPlaceholder)
  const words = useMemo(
    () => [
      lowerCaseMachineTypeName(
        getMachineTypeName(MachineType.TRACTOR, 'plural'),
        locale
      ),
      lowerCaseMachineTypeName(
        getMachineTypeName(MachineType.COMBINE_HARVESTER, 'plural'),
        locale
      ),
      lowerCaseMachineTypeName(
        getMachineTypeName(MachineType.BALER, 'plural'),
        locale
      ),
      t(translations.searchInputBrandsValue),
    ],
    [getMachineTypeName, locale, lowerCaseMachineTypeName, t]
  )
  const placeholderText = useTypewriterEffect(placeholderTranslation, words)

  // strips the className from inputProps to avoid overriding of the styles
  const { className, ...inputPropsRest } =
    inputProps ?? ({} as InputHTMLAttributes<HTMLInputElement>)

  return (
    <div ref={containerRef} className={styles.searchInputWrapper}>
      <input
        className={styles.searchInput}
        placeholder={placeholderText}
        onKeyDown={handleEnterPress}
        {...inputPropsRest}
      />
      <IconButton className={styles.iconButton} onClick={handleSearch}>
        <Search fontSize='small' />
      </IconButton>
    </div>
  )
}

SearchInput.displayName = 'SearchInput'
