import { getOptionsFromTranslation } from '@internal/i18n/getOptionsFromTranslation'
import { WizardDataProtectionDisclaimer } from '@renderer-ui-library/atoms'
import { CountryCodeAutoComplete } from '@renderer-ui-library/molecules'
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@renderer-ui-library/mui'
import { localeNamespace } from '@internal/utils/machine/i18n/Locale'
import { translations } from 'i18n/translations'
import { useTranslation } from 'next-i18next'
import { ChangeEvent, SyntheticEvent, useMemo } from 'react'
import { CountryCodeType } from 'utils/contact/phoneCountryCodes'
import MachineAlertItaly from '../MachineAlertItaly/MachineAlertItaly'
import { useIsNotRegisterableInItaly } from '../MachineAlertItaly/useIsNotRegisterableInItaly'

interface TContactData {
  areaCode: CountryCodeType
  phone: number | string
  mail: string
  firstName: string
  lastName: string
  message?: string
  newsletter: boolean
  smsAgreement: boolean
  budget: string
  timing: string
  reason: string
}

interface Props {
  onChange: (ev: ChangeEvent) => void
  onChangeCountryCode: (
    ev: SyntheticEvent,
    newValue: CountryCodeType | null
  ) => void
  onBudgetChange: (ev: SelectChangeEvent) => void
  onTimingChange: (ev: SelectChangeEvent) => void
  onReasonChange: (ev: SelectChangeEvent) => void
  onBlur: (ev: ChangeEvent) => void
  data: TContactData
  phoneError?: string | false
  mailError?: string | false
  firstNameError?: string | false
  lastNameError?: string | false
}

export const WizardContactStep: React.FC<Props> = ({
  data,
  onChange,
  onChangeCountryCode,
  onBudgetChange,
  onTimingChange,
  onReasonChange,
  onBlur,
  phoneError,
  mailError,
  firstNameError,
  lastNameError,
}) => {
  const { t } = useTranslation(localeNamespace.common)
  const isNotRegisterableInItaly = useIsNotRegisterableInItaly()

  const bugdetOptions = useMemo(
    () => getOptionsFromTranslation(t(translations.wizardContactBudgetOptions)),
    [t]
  )

  const timingOptions = useMemo(
    () =>
      getOptionsFromTranslation(
        t(translations.wizardContactMachineTimingOptions)
      ),
    [t]
  )

  const reasonOptions = useMemo(
    () =>
      getOptionsFromTranslation(
        t(translations.wizardContactMachineReasonOptions)
      ),
    [t]
  )

  return (
    <>
      <Grid container spacing={2}>
        {isNotRegisterableInItaly && (
          <Grid item xs={12}>
            <MachineAlertItaly />
          </Grid>
        )}

        <Grid item xs={12} sm={6}>
          <TextField
            size='small'
            id='wizard-first-name'
            name='firstName'
            label={t(
              translations.requestInformationWizardContactFirstNameLabel
            )}
            value={data.firstName}
            onChange={onChange}
            onBlur={onBlur}
            variant='outlined'
            error={!!firstNameError}
            helperText={firstNameError}
            fullWidth
            autoComplete='given-name'
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            size='small'
            id='wizard-last-name'
            name='lastName'
            label={t(translations.requestInformationWizardContactLastNameLabel)}
            value={data.lastName}
            onChange={onChange}
            onBlur={onBlur}
            variant='outlined'
            error={!!lastNameError}
            helperText={lastNameError}
            fullWidth
            autoComplete='family-name'
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            size='small'
            id='wizard-mail'
            name='mail'
            label={t(translations.requestInformationWizardContactEmailLabel)}
            value={data.mail}
            onChange={onChange}
            onBlur={onBlur}
            variant='outlined'
            error={!!mailError}
            helperText={mailError}
            fullWidth
            type='email'
            autoComplete='email'
            required
          />
        </Grid>
        <Grid item xs={12} container spacing={2}>
          <Grid item xs='auto'>
            <CountryCodeAutoComplete
              areaCode={data.areaCode}
              onChange={onChangeCountryCode}
            />
          </Grid>
          <Grid item xs>
            <TextField
              size='small'
              id='wizard-phone'
              name='phone'
              label={t(translations.requestInformationWizardContactPhoneLabel)}
              value={data.phone}
              onChange={onChange}
              onBlur={onBlur}
              variant='outlined'
              error={!!phoneError}
              helperText={phoneError}
              fullWidth
              type='tel'
              autoComplete='tel-national'
              required
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <TextField
            id='wizard-message'
            name='message'
            label={t(translations.requestInformationWizardMessageInputLabel)}
            multiline
            minRows={3}
            maxRows={6}
            value={data.message}
            onChange={onChange}
            onBlur={onBlur}
            variant='outlined'
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <FormControl fullWidth size='small'>
            <InputLabel id='budgetLabel'>
              {t(translations.wizardContactBudgetLabel)}
            </InputLabel>
            <Select
              labelId='budgetLabel'
              label={t(translations.wizardContactBudgetLabel)}
              id='budget'
              onChange={onBudgetChange}
              value={data.budget}
            >
              {Object.entries(bugdetOptions).map(([key, value]) => (
                <MenuItem value={key} key={key}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={4}>
          <FormControl fullWidth size='small'>
            <InputLabel id='timingLabel'>
              {t(translations.wizardContactMachineTimingLabel)}
            </InputLabel>
            <Select
              labelId='timingLabel'
              label={t(translations.wizardContactMachineTimingLabel)}
              id='timing'
              onChange={onTimingChange}
              value={data.timing}
            >
              {Object.entries(timingOptions).map(([key, value]) => (
                <MenuItem value={key} key={key}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={4}>
          <FormControl fullWidth size='small'>
            <InputLabel id='reasonLabel'>
              {t(translations.wizardContactMachineReasonLabel)}
            </InputLabel>
            <Select
              labelId='reasonLabel'
              label={t(translations.wizardContactMachineReasonLabel)}
              id='reason'
              onChange={onReasonChange}
              value={data.reason}
            >
              {Object.entries(reasonOptions).map(([key, value]) => (
                <MenuItem value={key} key={key}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                name='newsletter'
                id='newsletter'
                style={{ marginRight: 4 }}
                checked={!!data.newsletter}
                onChange={onChange}
              />
            }
            label={t(translations.newsletterCheckboxLabelText)}
          />

          <FormControlLabel
            control={
              <Checkbox
                name='smsAgreement'
                id='smsAgreement'
                style={{ marginRight: 4 }}
                checked={data.smsAgreement}
                onChange={onChange}
              />
            }
            label={t(translations.newsletterWizardSMSAgreement)}
          />
        </Grid>
      </Grid>
      <WizardDataProtectionDisclaimer />
    </>
  )
}
