import { Banner } from '@internal/utils/banner/Banner'
import { usePrioritizedUrl } from '@internal/utils/routing/urls/usePrioritizedUrl'
import { convertCmsTrackingClickEvent } from '@internal/utils/tracking/convertCmsTrackingEvents'
import { tracker } from '@internal/utils/tracking/tracker'
import { Typography } from '@mui/material'
import { Text } from '@renderer-ui-library/atoms'
import classNames from 'classnames'
import Link from 'next/link'
import React, { useCallback, useMemo } from 'react'
import { ReactMarkdownOptions } from 'react-markdown/lib/react-markdown'
import { HeaderContentWrapper } from '../HeaderContentWrapper/HeaderContentWrapper'
import styles from './genericBanner.module.scss'

export const GenericBanner: React.FC<{ banner: Banner }> = React.memo(
  ({ banner }) => {
    const { getPrioritizedUrl } = usePrioritizedUrl()

    const url = useMemo(
      () => banner.cta && getPrioritizedUrl(banner.cta),
      [banner.cta, getPrioritizedUrl]
    )

    const handleClick = useCallback(() => {
      if (banner.cta?.trackingClickEvent) {
        tracker.trackEvent({
          ...convertCmsTrackingClickEvent(banner.cta.trackingClickEvent),
        })
      }
    }, [banner.cta?.trackingClickEvent])

    const components = useMemo(
      (): ReactMarkdownOptions['components'] => ({
        p: (props) => (
          <Typography variant='body1' gutterBottom style={{ fontWeight: 600 }}>
            {props.children}
          </Typography>
        ),
      }),
      []
    )

    if (banner.cta) {
      return (
        <Link
          className={classNames(styles.outerWrapper, styles.clickable)}
          onClick={handleClick}
          href={url ?? ''}
        >
          <HeaderContentWrapper className={styles.wrapper}>
            <Text
              text={banner.text}
              highlight={false}
              color='dark'
              alignment='center'
              components={components}
            />
          </HeaderContentWrapper>
        </Link>
      )
    }

    return (
      <div className={styles.outerWrapper}>
        <HeaderContentWrapper className={styles.wrapper}>
          <Text
            text={banner.text}
            highlight={false}
            color='dark'
            alignment='center'
            components={components}
          />
        </HeaderContentWrapper>
      </div>
    )
  }
)

GenericBanner.displayName = 'GenericBanner'
