import { Machine } from '@internal/utils/machine/Machine'
import { TTableRow } from '@renderer-ui-library/molecules'
import { TDisplayDefinitionEntry } from '@internal/utils/displayDefinition/entry/TDisplayDefinitionEntry'
import { TLocale } from '@internal/utils/machine/i18n/TLocale'
import { mapMachinesAttributesToTableRow } from './mapMachinesAttributesToTableRow'

export const mapMachineAttributesToTableRow = (
  machine: Machine,
  entries: TDisplayDefinitionEntry[],
  locale: TLocale
): TTableRow[] =>
  mapMachinesAttributesToTableRow([machine], entries, locale).map((row) => ({
    key: row.key,
    marker: row.marker,
    value: row.values[0],
  }))
