import { CustomerServiceNumber } from '@internal/page/SettingsContext'
import { ArrowForward } from '@mui/icons-material'
import { Button } from '@renderer-ui-library/atoms'
import { TMediaFile } from '@renderer-ui-library/molecules'
import { Typography } from '@renderer-ui-library/mui'
import { localeNamespace } from '@internal/utils/machine/i18n/Locale'
import { translations } from 'i18n/translations'
import { useTranslation } from 'next-i18next'
import { useCallback } from 'react'
import { getMediaSrc } from 'utils/media/getMediaSrc'
import { tracker } from 'utils/tracking/tracker'
import styles from './contactUsCard.module.scss'

interface Props {
  name: string
  jobTitle: string
  phoneNumber?: CustomerServiceNumber
  languagesSpoken: string
  image: { data: TMediaFile }
  email: string
}

export const ContactUsCard = (props: Props) => {
  const { t } = useTranslation(localeNamespace.common)
  const { name, languagesSpoken, image, email, phoneNumber, jobTitle } = props

  const handleCallClick = useCallback(() => {
    tracker.trackEvent({
      name: 'call-click',
      target_name: 'contact_block.call_button',
      is_interaction: true,
    })
  }, [])

  return (
    <>
      {image.data && (
        <div className={styles.imageWrapper}>
          <img
            loading='lazy'
            alt={name}
            {...getMediaSrc(image, { ratio: 3 / 2 })}
          />
        </div>
      )}

      <div className={styles.contentWrapper}>
        <Typography variant='h6' color='textPrimary' marginBottom={0}>
          {name}
        </Typography>

        <Typography variant='body1' color='textSecondary' marginBottom={2}>
          {jobTitle}
        </Typography>

        <div className={styles.contentInfoWrapper}>
          <Typography variant='body1' fontWeight={'bold'}>
            {`${t(translations.languagesKey)}: `}
          </Typography>
          <Typography variant='body1'>{languagesSpoken}</Typography>
        </div>

        {phoneNumber && (
          <div className={styles.contentInfoWrapper}>
            <Typography variant='body1' fontWeight={'bold'}>
              {`${t(translations.phoneKey)}: `}
            </Typography>
            <a href={`tel:${phoneNumber.linkNumber}`}>
              <Typography variant='body1' className={styles.link}>
                {phoneNumber.displayNumber}
              </Typography>
            </a>
          </div>
        )}

        <div className={styles.contentInfoWrapper}>
          <Typography variant='body1' fontWeight={'bold'}>
            {`${t(translations.emailKey)}: `}
          </Typography>
          <a href={`mailto: ${email}`}>
            <Typography color={'primaryLight'} className={styles.link}>
              {email}
            </Typography>
          </a>
        </div>

        {phoneNumber && (
          <Button
            variant='text'
            size='small'
            noMinWidth
            color={'primary'}
            endIcon={<ArrowForward />}
            sx={{ marginTop: '16px' }}
            href={`tel:${phoneNumber.linkNumber}`}
            onClick={handleCallClick}
          >
            {t(translations.callKey, { name: name.split(' ')[0] })}
          </Button>
        )}
      </div>
    </>
  )
}

ContactUsCard.displayName = 'ContactUsCard'
