import { Button } from '@renderer-ui-library/atoms'
import { ButtonProps } from '@renderer-ui-library/mui'
import { ContactWizardContext } from '@renderer-ui-library/organisms'
import { localeNamespace } from '@internal/utils/machine/i18n/Locale'
import { translations } from 'i18n/translations'
import { useTranslation } from 'next-i18next'
import React, { useCallback, useContext } from 'react'

type ContactUsButtonProps = ButtonProps & {
  text: string | undefined
  noMinWidth?: boolean
  fullHeight?: boolean
  color?: string
}

export const ContactUsButton: React.FC<ContactUsButtonProps> = React.memo(
  ({ text, onClick, ...rest }) => {
    const { openContactUs } = useContext(ContactWizardContext)
    const { t } = useTranslation(localeNamespace.common)

    const handleClick = useCallback(
      (ev: React.MouseEvent<HTMLButtonElement>) => {
        if (onClick) {
          onClick(ev)
        }
        openContactUs()
      },
      [onClick, openContactUs]
    )
    return (
      <Button color='secondary' onClick={handleClick} {...rest}>
        {text ||
          t(translations.contactInformationWizardContactUsTitleAndButtonText)}
      </Button>
    )
  }
)

ContactUsButton.displayName = 'ContactUsButton'
