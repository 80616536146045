import { MachineSubTypeSelectFilter } from '@renderer-ui-library/molecules'
import { SubTypes } from '@internal/utils/machine/MachineType'
import { localeNamespace } from '@internal/utils/machine/i18n/Locale'
import { useSearchParams } from 'blocks/SearchResultsBlock/useSearchParams'
import { useSearchResultsRouting } from 'blocks/SearchResultsBlock/useSearchResultsRouting'
import { translations } from 'i18n/translations'
import { useTranslation } from 'next-i18next'
import React, { useCallback } from 'react'
import { SearchFilterCategory } from './SearchFilterCategory/SearchFilterCategory'

interface MachineSubTypeFilterProps {
  openSubTypeFilter: boolean
}

export const MachineSubTypeFilter: React.FC<MachineSubTypeFilterProps> =
  React.memo((props: MachineSubTypeFilterProps) => {
    const { openSubTypeFilter } = props
    const { machineSubType, machineType, brands, sort } = useSearchParams()
    const { gotoSearchResults } = useSearchResultsRouting()
    const { t } = useTranslation(localeNamespace.common)

    const handleMachineSubTypeChange = useCallback(
      (machineSubType: SubTypes[keyof SubTypes]) => {
        gotoSearchResults({
          machineType,
          machineSubType,
          brands,
          features: [],
          locations: [],
          sort,
        })
      },
      [brands, gotoSearchResults, machineType, sort]
    )

    return (
      <>
        {machineType && openSubTypeFilter && (
          <SearchFilterCategory
            title={t(translations.searchResultsFiltersMachineSubTypeLabel)}
          >
            <MachineSubTypeSelectFilter
              machineType={machineType}
              selectedMachineSubType={machineSubType}
              onChange={handleMachineSubTypeChange}
            />
          </SearchFilterCategory>
        )}
      </>
    )
  })

MachineSubTypeFilter.displayName = 'MachineSubTypeFilter'
