import { Alert } from '@mui/material'

const MachineAlertItaly: React.FC = () => {
  return (
    <Alert
      severity='warning'
      sx={{ marginBottom: '16px', marginTop: { xs: '16px', md: 0 } }}
    >
      Questa macchina non è disponibile per il mercato Italiano
    </Alert>
  )
}

export default MachineAlertItaly
