import { InputAdornment, TextField, Typography } from '@renderer-ui-library/mui'
import { localeNamespace } from '@internal/utils/machine/i18n/Locale'
import { RangeSelectValue } from 'blocks/SearchCoverBlock/RangeSelectFilter'
import { translations } from 'i18n/translations'
import debounce from 'lodash/debounce'
import { useTranslation } from 'next-i18next'
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { onChangeDebounceTime } from 'utils/constants'
import styles from './rangeInput.module.scss'

export type RangeInputProps = {
  minLimit: number
  maxLimit: number
  minValue?: number
  maxValue?: number
  step?: number
  minPlaceholder?: string
  maxPlaceholder?: string
  minLabel?: string
  maxLabel?: string
  onChange: (value: { min?: RangeSelectValue; max?: RangeSelectValue }) => void
  unit?: string
  onEnterPress?: () => void
  noSeparator?: boolean
}

export const RangeInput: React.FC<RangeInputProps> = React.memo(
  ({ onEnterPress, ...props }) => {
    const [min, setMin] = useState<RangeSelectValue>(props.minValue)
    const [max, setMax] = useState<RangeSelectValue>(props.maxValue)
    const [errorMessage, setErrorMessage] = useState<string | undefined>()
    const { t } = useTranslation(localeNamespace.common)

    useEffect(() => {
      setMin(props.minValue)
      setErrorMessage(undefined)
    }, [props.minValue])
    useEffect(() => {
      setMax(props.maxValue)
      setErrorMessage(undefined)
    }, [props.maxValue])

    const step = props.step ?? 1

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const submitChanges = useCallback(
      debounce((value: { min?: RangeSelectValue; max?: RangeSelectValue }) => {
        const newValue: {
          min?: RangeSelectValue
          max?: RangeSelectValue
        } = {}
        const { min, max } = value

        if (min && max && min >= max) {
          setErrorMessage(t(translations.rangeInputMinLowerMaxErrorMessage))
          return
        }

        setErrorMessage(undefined)

        const hasMin = 'min' in value
        const hasMax = 'max' in value

        if (
          hasMin &&
          (min === undefined ||
            (props.minLimit <= min && min <= props.maxLimit))
        ) {
          newValue.min = min
        }

        if (
          hasMax &&
          (max === undefined ||
            (props.minLimit <= max && max <= props.maxLimit))
        ) {
          newValue.max = max
        }

        if (Object.keys(newValue).length) {
          props.onChange(newValue)
        }
      }, onChangeDebounceTime),
      [props.onChange, props.minLimit, props.maxLimit]
    )

    const handleMinBlur = useCallback(() => {
      submitChanges.flush()

      setMin((min) => {
        if (min === undefined) {
          return min
        }

        const sanitizedMin = Math.min(
          Math.max(props.minLimit, min),
          props.maxLimit - 1
        )

        if (sanitizedMin !== min) {
          submitChanges({ min: sanitizedMin, max })
        }

        return sanitizedMin
      })
    }, [submitChanges, props.minLimit, props.maxLimit, max])

    const handleMaxBlur = useCallback(() => {
      submitChanges.flush()

      setMax((max) => {
        if (max === undefined) {
          return max
        }

        const sanitizedMax = Math.max(
          Math.min(props.maxLimit, max),
          props.minLimit + 1
        )
        if (sanitizedMax !== max) {
          submitChanges({ max: sanitizedMax, min })
        }
        return sanitizedMax
      })
    }, [submitChanges, props.minLimit, props.maxLimit, min])

    useEffect(() => {
      // flush on unmount
      return () => submitChanges.flush()
    }, [submitChanges])

    const handleMaxChange = useCallback(
      (ev: ChangeEvent<HTMLInputElement>) => {
        let max: RangeSelectValue = parseInt(ev.target.value)
        max = isNaN(max) ? undefined : max

        setMax(max)
        submitChanges({ max, min })
      },
      [submitChanges, min]
    )

    const handleMinChange = useCallback(
      (ev: ChangeEvent<HTMLInputElement>) => {
        let min: RangeSelectValue = parseInt(ev.target.value)
        min = isNaN(min) ? undefined : min

        setMin(min)
        submitChanges({ min, max })
      },
      [submitChanges, max]
    )

    const handleKeyPress = useCallback(
      (ev: React.KeyboardEvent) => {
        if (ev.key === 'Enter') {
          submitChanges.flush()
          onEnterPress?.()
        }
      },
      [onEnterPress, submitChanges]
    )

    return (
      <>
        <form
          className={styles.wrapper}
          onBlur={submitChanges.flush}
          onKeyPress={handleKeyPress}
        >
          <TextField
            size='small'
            label={props.minLabel}
            type='number'
            inputProps={{
              step: step,
              min: props.minLimit,
              max: Math.min(max ? max - 1 : 999999, props.maxLimit - 1),
            }}
            value={min ?? ''}
            placeholder={props.minPlaceholder}
            onChange={handleMinChange}
            onBlur={handleMinBlur}
            error={!!errorMessage}
            InputProps={{
              endAdornment: props.unit && (
                <InputAdornment position='end'>{props.unit}</InputAdornment>
              ),
            }}
            autoComplete='off'
          />
          <span className={styles.separator}>
            {props.noSeparator ? '' : '-'}
          </span>
          <TextField
            size='small'
            label={props.maxLabel}
            type='number'
            inputProps={{
              step: step,
              min: Math.max(min ? min + 1 : 0, props.minLimit + 1),
              max: props.maxLimit,
            }}
            value={max ?? ''}
            placeholder={props.maxPlaceholder}
            onChange={handleMaxChange}
            onBlur={handleMaxBlur}
            error={!!errorMessage}
            InputProps={{
              endAdornment: props.unit && (
                <InputAdornment position='end'>{props.unit}</InputAdornment>
              ),
            }}
            autoComplete='off'
          />
        </form>
        {errorMessage && (
          <Typography
            className={styles.errorMessage}
            color='error'
            fontSize='small'
          >
            {errorMessage}
          </Typography>
        )}
      </>
    )
  }
)

RangeInput.displayName = 'RangeInput'
