import { DisplayDefinitionEntryType } from './DisplayDefinitionEntryType'
import { TDisplayDefinitionEntry } from './TDisplayDefinitionEntry'
import { TLocale } from '@internal/utils/machine/i18n/TLocale'
import { KnownMachineAttribute } from '@internal/utils/machine/KnownMachineAttribute'
import { formatDate } from 'utils/date/formatDate'
import { formatNumber } from 'utils/numbers/formatNumber'
import { Machine, MachineData } from '@internal/utils/machine/Machine'

export const displayDefinitionEntryToString = (
  locale: TLocale,
  machine: Machine,
  entry: TDisplayDefinitionEntry,
  glue: string,
  valueOnly?: boolean
): string | null => {
  const value = machine.data[entry.machineAttribute as keyof MachineData]
  if (value === undefined) {
    return null
  }
  const label = valueOnly ? '' : `${entry.label}: `
  switch (entry.type) {
    case DisplayDefinitionEntryType.Boolean: {
      return value ? entry.label : null
    }
    case DisplayDefinitionEntryType.String: {
      return value ? `${label}${value}` : null
    }

    case DisplayDefinitionEntryType.Date: {
      return value ? `${label}${formatDate(locale, `${value}`)}` : null
    }

    case DisplayDefinitionEntryType.Number: {
      if (value) {
        switch (entry.machineAttribute) {
          case KnownMachineAttribute.YEAR_OF_PRODUCTION: {
            return `${label}${value}`
          }
          case KnownMachineAttribute.HEADER_YEAR_OF_PRODUCTION: {
            return `${label}${value}`
          }
          case KnownMachineAttribute.WHEEL_FRONT_LEFT_DEPTH:
          case KnownMachineAttribute.WHEEL_FRONT_RIGHT_DEPTH:
          case KnownMachineAttribute.WHEEL_REAR_LEFT_DEPTH:
          case KnownMachineAttribute.WHEEL_REAR_RIGHT_DEPTH: {
            if (typeof value === 'number') {
              return `${Math.floor(value * 100)}%`
            }
            break
          }
        }
        return `${label}${
          typeof value === 'number' ? formatNumber(locale, value) : value
        }`
      }
      return null
    }

    case DisplayDefinitionEntryType.Enum: {
      if (value) {
        return (
          entry.options.find(
            (option) => `${value}`.toUpperCase() === option.key.toUpperCase()
          )?.name ?? null
        )
      }
      return null
    }

    case DisplayDefinitionEntryType.EnumList: {
      if (Array.isArray(value)) {
        const values = (value as string[]).map((v) => `${v}`.toUpperCase())
        return values
          .map(
            (v) =>
              entry.options.find((option) => option.key.toUpperCase() === v)
                ?.name ?? null
          )
          .filter((s) => !!s)
          .join(glue)
      }
      return null
    }
  }
}
