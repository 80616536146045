export enum BalerSubType {
  BALER_WRAPPER_COMBINATION = 'baler_wrapper_combination',
  HIGH_DENSITY_BALER = 'high_density_baler',
  OTHER = 'other',
  ROUND_BALER = 'round_baler',
  SQUARE_BALER = 'square_baler',
}

export enum MachineType {
  ANIMAL_HUSBANDRY_EQUIPMENT = 'animal_husbandry_equipment',
  ATTACHMENT = 'attachment',
  BALE_WRAPPER = 'bale_wrapper',
  BACK_HOE_LOADER = 'back_hoe_loader',
  BALER = 'baler',
  BEET_HARVESTING_EQUIPMENT = 'beetharvester',
  CHASER_BIN = 'chaser_bin',
  COMBINE_HARVESTER = 'combine',
  COMPLEMENTARY_SEEDER = 'complementary_seeder',
  CONCRETE_MIXER = 'concrete_mixer',
  CONSTRUCTION_MACHINERY_OTHER = 'construction_machinery_other',
  CULTIVATOR = 'cultivator',
  CUTTERS_FLAIL_MOWER_OTHER = 'cutters_flail_mower_other',
  DIRECT_SOWING_MACHINE = 'direct_sowing_machine',
  DISC_HARROW_AND_COMPACT_DISC = 'compactdischarrow',
  DRILL = 'drill',
  DRILLING_MACHINE_COMBINATION = 'drillingcombination',
  DUMPER = 'dumper',
  EXCAVATOR = 'excavator',
  EXCAVATOR_CRAWLER = 'excavator_crawler',
  EXCAVATOR_MINI = 'excavator_mini',
  EXCAVATORS_OTHER = 'excavators_other',
  EXCAVATOR_WHEELED = 'excavator_wheeled',
  FARMSHOP = 'farmshop',
  FEEDER_OTHER = 'feeder_other',
  FERTILISER_SPREADER = 'fertiliserspreaders',
  FORAGE_HARVESTER_SELF_PROPELLED = 'harvester',
  FORAGE_HARVESTER_TRAILED_MOUNTED = 'forage_harvester_trailed_mounted',
  FORAGE_TRANSPORT = 'forage_transport',
  FORAGING_EQUIPMENT_OTHER = 'foraging_equipment_other',
  FORESTRY_EQUIPMENT = 'forestry_equipment',
  FORESTRY_HARVESTER = 'forestry_harvester',
  FORESTRY_MOWER = 'forestry_mower',
  FORESTRY_TRAILER = 'forestry_trailer',
  FORKLIFT = 'stapler',
  FORWARDER = 'forwarder',
  GPS_SYSTEMS_PRECISION_FARMING = 'gps_systems_precision_farming',
  GRAIN_STORAGE_HANDLING = 'grain_storage_handling',
  GRAPE_HARVESTER = 'grape_harvester',
  HAULM_TOPPER = 'haulm_topper',
  HEADER = 'header',
  HEDGE_MOWER = 'hedge_mower',
  HOP_EQUIPMENT = 'hop_equipment',
  IRRIGATION = 'sprinklerequipment',
  LAWN_MOWER = 'lawn_mower',
  MANURE_COMPOST_SPREADER = 'manure_compost_spreader',
  MECHANICAL_WEED_CONTROL = 'mechanical_weed_control',
  METRAC = 'metrac',
  MINI_LOADER = 'mini_loader',
  MILKING_EQUIPMENT = 'milking_equipment',
  MIXER_FEEDERS = 'feedingwagon',
  MOTOR_VEHICLE = 'motor_vehicle',
  MOWER = 'mowingdevice',
  MULI = 'muli',
  MUNICIPAL_WINTER_SERVICE_EQUIPMENT = 'municipal_winter_service_equipment',
  ORCHARD_AND_VINEYARD_EQUIPMENT_OTHER = 'orchard_and_vineyard_equipment_other',
  PARTS = 'parts',
  PLANTER = 'planter',
  PLOUGH = 'plough',
  POTATO_EQUIPMENT = 'potato_equipment',
  POTATO_HARVESTER = 'potatoharvester',
  POWER_HARROW = 'circularharrow',
  PRECISION_DRILL = 'singlegrainsowingmachine',
  PRESSES_AND_ROLLER = 'presses_and_roller',
  PUSH_OFF_TRAILER = 'push_off_trailer',
  ROAD_ROLLER = 'road_roller',
  ROTARY_RAKE_WINDROWER = 'windrower',
  SAWS_AND_SPLITTERS = 'saws_and_splitters',
  SEEDBED_COMBINATION = 'seedbedcombination',
  SELF_PROPELLED_LIQUID_MANURE_SPREADER = 'self_propelled_liquid_manure_spreader',
  SELF_PROPELLED_PLATFORM = 'self_propelled_platform',
  SILAGE_CUTTERS_FEEDER = 'silagecutterandspreader',
  SKID_STEER_LOADER = 'skid_steer_loader',
  SLURRY_ATTACHMENT = 'slurry_attachment',
  SLURRY_EQUIPMENT_OTHER = 'slurry_equipment_other',
  SLURRY_LOGISTIC = 'slurry_logistic',
  SLURRY_TANKER = 'slurry_tanker',
  SOWING_MACHINE_OTHER = 'sowing_machine_other',
  SPRAYER = 'sprayers',
  STONE_CRUSHER = 'stone_crusher',
  TANKER = 'tanker',
  TEDDER = 'rotaryhaymaker',
  TELE_WHEEL_LOADER = 'tele_wheel_loader',
  TELEHANDLER = 'telehandler',
  TILLAGE_OTHER = 'tillage_other',
  TIPPER = 'tipper',
  TOPPER = 'topper',
  TRACTOR = 'tractor',
  TRAILER = 'trailer',
  VEGETABLE_CLEANING_PROCESSING_EQUIPMENT = 'vegetable_cleaning_processing_equipment',
  VEGETABLE_HARVESTER = 'vegetable_harvester',
  VERGE_MOWER = 'verge_mower',
  VIBRATING_PLATE = 'vibrating_plate',
  WHEEL_AND_TRACK = 'wheel_and_track',
  WHEEL_LOADER = 'compactloader',
  WOOD_CHIPPER_GRINDER = 'wood_chipper_grinder',
  DRAINAGE = 'drainage',
  HORIZONTAL_AXIS_SHREDDER = 'horizontal_axis_shredder',
  SPREADING_SPRAYING_EQUIPMENT_OTHER = 'spreading_spraying_equipment_other',
  VERTICAL_AXIS_SHREDDER = 'vertical_axis_shredder',
}

export const KnownMachineTypesForProductDataFeed: MachineType[] = [
  MachineType.BACK_HOE_LOADER,
  MachineType.BALER,
  MachineType.BALE_WRAPPER,
  MachineType.COMBINE_HARVESTER,
  MachineType.COMPLEMENTARY_SEEDER,
  MachineType.CULTIVATOR,
  MachineType.CUTTERS_FLAIL_MOWER_OTHER,
  MachineType.DIRECT_SOWING_MACHINE,
  MachineType.DISC_HARROW_AND_COMPACT_DISC,
  MachineType.DRILL,
  MachineType.DRILLING_MACHINE_COMBINATION,
  MachineType.FERTILISER_SPREADER,
  MachineType.FORAGE_HARVESTER_SELF_PROPELLED,
  MachineType.FORAGE_HARVESTER_TRAILED_MOUNTED,
  MachineType.HEADER,
  MachineType.MINI_LOADER,
  MachineType.MIXER_FEEDERS,
  MachineType.PLOUGH,
  MachineType.POTATO_EQUIPMENT,
  MachineType.POTATO_HARVESTER,
  MachineType.POWER_HARROW,
  MachineType.PRECISION_DRILL,
  MachineType.ROTARY_RAKE_WINDROWER,
  MachineType.SEEDBED_COMBINATION,
  MachineType.SPRAYER,
  MachineType.TELE_WHEEL_LOADER,
  MachineType.TELEHANDLER,
  MachineType.TILLAGE_OTHER,
  MachineType.TRACTOR,
  MachineType.TRAILER,
  MachineType.VEGETABLE_HARVESTER,
  MachineType.WHEEL_LOADER,
]

export enum LegacyMachineType {
  EXPORT_CONSTRUCTION_MACHINERY_OTHER = 'export construction_machinery_other',
}

export const LegacyMachineTypeMapper: Record<LegacyMachineType, MachineType> = {
  [LegacyMachineType.EXPORT_CONSTRUCTION_MACHINERY_OTHER]:
    MachineType.CONSTRUCTION_MACHINERY_OTHER,
}

export const SubTypes = { ...BalerSubType }
export type SubTypes = typeof SubTypes

export const MachineSubType: {
  [index: string]: { [index: string]: BalerSubType }
} = {
  [MachineType.BALER]: { ...BalerSubType },
}
export type MachineSubType = typeof MachineSubType
