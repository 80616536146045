import { TLocale } from '@internal/utils/machine/i18n/TLocale'

export const trustPilotLocaleMapper: Record<TLocale, string> = {
  en: 'en-GB',
  de: 'de-DE',
  fr: 'fr-FR',
  it: 'it-IT',
  pl: 'pl-PL',
  es: 'es-ES',
  da: 'da-DK',

  ru: 'en-GB',
  bg: 'en-GB',
  sl: 'en-GB',
  el: 'en-GB',
  ro: 'en-GB',
  hu: 'en-GB',
  sr: 'en-GB',
  cs: 'en-GB',
  lt: 'en-GB',
}
