import { KnownMachineAttribute } from '@internal/utils/machine/KnownMachineAttribute'
import { TRangeFiltersMap } from 'blocks/SearchResultsBlock/TRangeFiltersMap'
import { NonSERPMachineFilterEvent } from './TTrackingEvent'

export type MachineFilterEventCategory =
  | 'machine-type'
  | 'brand'
  | 'model'
  | 'hp'
  | 'hours'
  | 'year'
  | 'price'
  | 'feature'
  | 'location'
export const createMachineFilterEvent = (
  event: Omit<
    NonSERPMachineFilterEvent,
    | 'filter_category'
    | 'filter_category_count'
    | 'filter_brand'
    | 'filter_brand_count'
    | 'is_interaction'
    | 'filter_hp_min'
    | 'filter_hp_max'
    | 'filter_year_min'
    | 'filter_year_max'
    | 'filter_price_min'
    | 'filter_price_max'
  >,
  params: {
    filterBrands: string[]
    rangeFilters: TRangeFiltersMap
  }
): NonSERPMachineFilterEvent => {
  const filterCategories: MachineFilterEventCategory[] = [
    'machine_type',
    params.filterBrands.length > 0 && 'brand',
    event.filter_model && 'model',
    params.rangeFilters[KnownMachineAttribute.ENGINE_HP] && 'hp',
    params.rangeFilters[KnownMachineAttribute.YEAR_OF_PRODUCTION] && 'year',
    params.rangeFilters[KnownMachineAttribute.PRICE_NET_EUR] && 'price',
  ].filter((entry): entry is MachineFilterEventCategory => !!entry)

  const fullEvent: NonSERPMachineFilterEvent = {
    ...event,
    filter_brand: params.filterBrands
      .map((brand) => brand.toLowerCase())
      .join(','),
    filter_brand_count: params.filterBrands.length,
    filter_category: filterCategories.join(','),
    filter_category_count: filterCategories.length,
    filter_hp_min: params.rangeFilters[KnownMachineAttribute.ENGINE_HP]?.min,
    filter_hp_max: params.rangeFilters[KnownMachineAttribute.ENGINE_HP]?.max,
    filter_year_min:
      params.rangeFilters[KnownMachineAttribute.YEAR_OF_PRODUCTION]?.min,
    filter_year_max:
      params.rangeFilters[KnownMachineAttribute.YEAR_OF_PRODUCTION]?.max,
    filter_price_min:
      params.rangeFilters[KnownMachineAttribute.PRICE_NET_EUR]?.min,
    filter_price_max:
      params.rangeFilters[KnownMachineAttribute.PRICE_NET_EUR]?.max,
    is_interaction: true,
  }

  return fullEvent
}
