import { ImageLoader } from '@internal/utils/media/ImageLoader'

import { SystemPageKey } from '@internal/page/SystemPageKey'
import { useFeatureFlag } from '@internal/utils/featureFlags/useFeatureFlag'
import { urlForSystemPageWithoutParams } from '@internal/utils/routing/urls/urlForSystemPageWithoutParams'
import { Grid } from '@mui/material'
import Logo from '@renderer-ui-library/assets/logo.svg'
import Breakpoints from '@renderer-ui-library/mui/base/breakpoints.module.scss'
import { TLocale } from '@internal/utils/machine/i18n/TLocale'
import Image from 'next/image'
import React, { useMemo } from 'react'
import { HeaderLocales } from '../../HeaderLocales/HeaderLocales'
import { SearchAutosuggest } from '../../SearchBar/SearchAutosuggest/SearchAutosuggest'
import { SearchOverlay, TSearchParams } from '../../SearchOverlay/SearchOverlay'
import styles from './desktopSearchBar.module.scss'

export const portalSlotDesktopId = 'navigation-portal-slot-desktop'
interface SearchBarProps {
  locale: TLocale
  searchParams: TSearchParams
}

export const DesktopSearchBar: React.FC<SearchBarProps> = (props) => {
  const { locale, searchParams } = props
  const { hasFeature } = useFeatureFlag()
  const isHeaderSearchInputEnabled = hasFeature('headerSearchInput')

  const startPageUrl = useMemo(
    () => urlForSystemPageWithoutParams(locale, SystemPageKey.StartPage),
    [locale]
  )

  return (
    <div className={styles.desktopSearchBar}>
      <Grid
        container
        spacing={0}
        alignItems='center'
        maxWidth={parseInt(Breakpoints.lg)}
        className={styles.container}
      >
        <Grid item md={6}>
          <div style={{ display: 'flex' }}>
            <a href={startPageUrl} className={styles.logo}>
              <Image
                loading='lazy'
                src={Logo.src}
                alt='E-FARM GmbH'
                loader={ImageLoader}
                fill
              />
            </a>
          </div>
        </Grid>
        <Grid
          item
          md={5}
          display='flex'
          alignItems='center'
          justifyContent='flex-end'
        >
          <div id={portalSlotDesktopId} />
          {isHeaderSearchInputEnabled && <SearchAutosuggest locale={locale} />}
          {!isHeaderSearchInputEnabled && (
            <SearchOverlay searchParams={searchParams} />
          )}
        </Grid>
        <Grid item md={1} className={styles.localesWrapper}>
          <HeaderLocales locale={locale} className={styles.locales} />
        </Grid>
      </Grid>
    </div>
  )
}

DesktopSearchBar.displayName = 'DesktopSearchBar'
