import { TFilterOption } from '@renderer-ui-library/molecules'
import { KnownBaleChamberType } from '@internal/utils/machine/KnownBalerChamberType'
import { KnownMachineHeaderType } from '@internal/utils/machine/KnownMachineHeaderType'
import { localeNamespace } from '@internal/utils/machine/i18n/Locale'
import { translations } from 'i18n/translations'
import { useTranslation } from 'next-i18next'
import { useMemo } from 'react'

export const useBaleChamberTypeOptions = () => {
  const { t } = useTranslation(localeNamespace.common)

  const baleChamberTypeOptions = useMemo<TFilterOption[]>(
    () => [
      {
        id: KnownBaleChamberType.FIXED_CHAMBER,
        label: t(translations.fixedChamber),
      },
      {
        id: KnownBaleChamberType.SEMI_VARIABLE_CHAMBER,
        label: t(translations.semiVariableChamber),
      },
      {
        id: KnownBaleChamberType.VARIABLE_CHAMBER,
        label: t(translations.variableChamber),
      },
    ],
    [t]
  )

  const machineHeaderTypeOptions = useMemo<TFilterOption[]>(
    () => [
      {
        id: KnownMachineHeaderType.FRONT_END_EXTENSION,
        label: t(translations.frontEndExtension),
      },
      {
        id: KnownMachineHeaderType.GRAIN_HEADER,
        label: t(translations.grainHeader),
      },
      {
        id: KnownMachineHeaderType.GRASS_SEED_HEADER,
        label: t(translations.grassSeedHeader),
      },
      {
        id: KnownMachineHeaderType.MAIZE_HEADER,
        label: t(translations.maizeHeader),
      },
      {
        id: KnownMachineHeaderType.RAPESEED_HEADER,
        label: t(translations.rapeseedHeader),
      },
      {
        id: KnownMachineHeaderType.SUNFLOWER_HEADER,
        label: t(translations.sunflowerHeader),
      },
      {
        id: KnownMachineHeaderType.PICK_UP,
        label: t(translations.headerPickUp),
      },
      {
        id: KnownMachineHeaderType.PICKER,
        label: t(translations.headerPicker),
      },
      {
        id: KnownMachineHeaderType.OTHER,
        label: t(translations.headerOther),
      },
      {
        id: KnownMachineHeaderType.GPS_CUTTING_UNIT,
        label: t(translations.gpsCuttingUnit),
      },
      {
        id: KnownMachineHeaderType.HEADER,
        label: t(translations.header),
      },
      {
        id: KnownMachineHeaderType.MAIZE_PICKER,
        label: t(translations.maizePicker),
      },
    ],
    [t]
  )

  return { baleChamberTypeOptions, machineHeaderTypeOptions }
}
