import { TMedia } from '@renderer-ui-library/molecules'
import { getLocalImage } from 'utils/media/getLocalImage'
import { MachineType } from '../MachineType'

export const MachinePlaceholderImageMapper = (
  machineType: MachineType,
  imageType: 'icon' | 'image'
): TMedia => {
  switch (machineType) {
    case MachineType.TRACTOR:
      return imageType === 'icon'
        ? getLocalImage(
            require('./images/Placeholder_Tractor.svg'),
            'image/svg+xml'
          )
        : getLocalImage(require('./icons/icon_Tractor.png'), 'image/png')

    case MachineType.BALER:
      return imageType === 'icon'
        ? getLocalImage(
            require('./images/Placeholder_Baler.svg'),
            'image/svg+xml'
          )
        : getLocalImage(require('./icons/icon_Baler.png'), 'image/png')

    case MachineType.COMBINE_HARVESTER:
      return imageType === 'icon'
        ? getLocalImage(
            require('./images/Placeholder_Combine.svg'),
            'image/svg+xml'
          )
        : getLocalImage(require('./icons/icon_Combine.png'), 'image/png')

    case MachineType.FORESTRY_HARVESTER:
    case MachineType.POTATO_HARVESTER:
    case MachineType.VEGETABLE_HARVESTER:
    case MachineType.FORAGE_HARVESTER_TRAILED_MOUNTED:
    case MachineType.FORAGE_HARVESTER_SELF_PROPELLED:
      return imageType === 'icon'
        ? getLocalImage(
            require('./images/Placeholder_Harvester.svg'),
            'image/svg+xml'
          )
        : getLocalImage(require('./icons/icon_Harvester.png'), 'image/png')

    case MachineType.SPRAYER:
      return imageType === 'icon'
        ? getLocalImage(
            require('./images/Placeholder_Sprayer.svg'),
            'image/svg+xml'
          )
        : getLocalImage(require('./icons/icon_Sprayer.png'), 'image/png')

    case MachineType.TELEHANDLER:
      return imageType === 'icon'
        ? getLocalImage(
            require('./images/Placeholder_Telehandler.svg'),
            'image/svg+xml'
          )
        : getLocalImage(require('./icons/icon_Telehandler.png'), 'image/png')

    default:
      return getLocalImage(
        require('./images/Placeholder_Generic.svg'),
        'image/svg+xml'
      )
  }
}
