import { SystemPageKey } from '@internal/page/SystemPageKey'
import { ImageLoader } from '@internal/utils/media/ImageLoader'
import { urlForSystemPageWithoutParams } from '@internal/utils/routing/urls/urlForSystemPageWithoutParams'
import { Grid } from '@mui/material'
import Logo from '@renderer-ui-library/assets/logo.svg'
import { TLocale } from '@internal/utils/machine/i18n/TLocale'
import Image from 'next/image'
import React, { useMemo } from 'react'
import { HeaderLocales } from '../../HeaderLocales/HeaderLocales'
import styles from './mobileLogoBar.module.scss'

interface Props {
  locale: TLocale
}

export const portalSlotMobileId = 'navigation-portal-slot-mobile'

export const MobileLogoBar: React.FC<Props> = React.memo((props) => {
  const { locale } = props

  const startPageUrl = useMemo(
    () => urlForSystemPageWithoutParams(locale, SystemPageKey.StartPage),
    [locale]
  )

  return (
    <header className={styles.mobileLogoBar}>
      <Grid
        container
        rowGap={3}
        alignItems='center'
        className={styles.container}
      >
        <Grid item xs={6}>
          <a href={startPageUrl} className={styles.logo}>
            <Image
              loading='lazy'
              src={Logo.src}
              alt='E-FARM GmbH'
              loader={ImageLoader}
              fill
            />
          </a>
        </Grid>
        <Grid item xs={6} className={styles.localesWrapper}>
          <div id={portalSlotMobileId}></div>
          <HeaderLocales locale={locale} className={styles.locales} />
        </Grid>
      </Grid>
    </header>
  )
})

MobileLogoBar.displayName = 'MobileLogoBar'
