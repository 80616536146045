import { TSalesManager } from '@internal/services/TSalesManager'
import { Close } from '@mui/icons-material'
import { Typography } from '@renderer-ui-library/mui'
import { useMediaQuery } from '@renderer-ui-library/mui/utils/mediaQuery/useMediaQuery'
import { localeNamespace } from '@internal/utils/machine/i18n/Locale'
import { TLocale } from '@internal/utils/machine/i18n/TLocale'
import { translations } from 'i18n/translations'
import { useTranslation } from 'next-i18next'
import { SystemPageKey } from 'page/SystemPageKey'
import React, {
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { createPortal } from 'react-dom'
import { PageTypeKey } from 'utils/page/PageTypeKey'
import { TPageType } from 'utils/page/TPageType'
import { tracker } from 'utils/tracking/tracker'
import { ContactButtons } from '../ContactButtons/ContactButtons'
import MachineAlertItaly from '../MachineAlertItaly/MachineAlertItaly'
import { useIsNotRegisterableInItaly } from '../MachineAlertItaly/useIsNotRegisterableInItaly'
import { SalesManagerContact } from '../SaleManagerContact/SalesManagerContact'
import { MultipleFloatingButtonDetailsOverlay } from './MultipleFloatingButtonDetailsOverlay'
import { PopoverFloatingButtonOverlay } from './PopoverFloatingButtonOverlay'
import { DynamicMobileFloatingButtonOverlay } from './dynamicMobileFloatingButtonOverlay'
import { DynamicMultipleFloatingButtonOverlay } from './dynamicMultipleFloatingButtonOverlay'
import styles from './multipleFloatingButton.module.scss'

export interface MultipleFloatingButtonProps {
  locale: TLocale
  pageType: TPageType
  salesManagers: TSalesManager[] | null
  availability?: boolean
  trackPageSource: (page: string) => void
}

export const MultipleFloatingButton: React.FC<MultipleFloatingButtonProps> =
  React.memo(
    ({ salesManagers, pageType, locale, availability, trackPageSource }) => {
      const { isMobile } = useMediaQuery()
      const { t } = useTranslation(localeNamespace.common)
      const isNotRegisterableInItaly = useIsNotRegisterableInItaly()
      const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

      const [isMachineDetailsPage, setIsMachineDetailsPage] =
        useState<boolean>(false)

      useEffect(() => {
        pageType.type === PageTypeKey.SystemPage &&
          pageType?.key === SystemPageKey.PDP &&
          setIsMachineDetailsPage(true)
      }, [pageType])

      const handleClick = useCallback(
        (event: MouseEvent<HTMLButtonElement>) => {
          setAnchorEl(event.currentTarget)
          tracker.trackEvent({
            name: 'click',
            target_name: 'contact.floating_button',
            is_interaction: true,
          })
        },
        []
      )
      const handleClose = useCallback(() => {
        setAnchorEl(null)
        trackPageSource('pdp')
      }, [trackPageSource])

      const open = Boolean(anchorEl)
      const id = open ? 'contact-popover' : undefined

      const content = useMemo(
        () => (
          <div className={styles.wrapper}>
            <div className={styles.header}>
              <Typography variant='h4'>
                {t(translations.contactButtonText)}
              </Typography>
              <Close className={styles.close} onClick={handleClose} />
            </div>
            {isNotRegisterableInItaly && <MachineAlertItaly />}
            <div className={styles.description}>
              <Typography variant='body1'>
                {t(translations.contactMessageDescription)}
              </Typography>
            </div>

            {salesManagers && (
              <div className={styles.salesManagerContactWrapper}>
                {salesManagers.map((manager, index) => {
                  return (
                    <SalesManagerContact key={index} salesManager={manager} />
                  )
                })}
              </div>
            )}
            <ContactButtons
              locale={locale}
              pageType={pageType}
              salesManager={salesManagers?.[0] || null}
              availability={availability}
            />
          </div>
        ),
        [
          t,
          handleClose,
          isNotRegisterableInItaly,
          salesManagers,
          locale,
          pageType,
          availability,
        ]
      )

      const renderOverlayContent = useMemo(() => {
        if (isMobile && isMachineDetailsPage) {
          return (
            <MultipleFloatingButtonDetailsOverlay handleClick={handleClick} />
          )
        }

        return (
          <>
            {!isMachineDetailsPage && (
              <DynamicMultipleFloatingButtonOverlay
                id={id}
                isMobile={isMobile}
                handleClick={handleClick}
              />
            )}
          </>
        )
      }, [handleClick, id, isMachineDetailsPage, isMobile])

      return (
        <>
          {isMobile ? (
            <DynamicMobileFloatingButtonOverlay
              open={open}
              handleClose={handleClose}
            >
              {content}
            </DynamicMobileFloatingButtonOverlay>
          ) : (
            <PopoverFloatingButtonOverlay
              id={id}
              open={open}
              anchorEl={anchorEl}
              handleClose={handleClose}
            >
              {content}
            </PopoverFloatingButtonOverlay>
          )}

          {createPortal(renderOverlayContent, document.body)}
        </>
      )
    }
  )

MultipleFloatingButton.displayName = 'MultipleFloatingButton'
