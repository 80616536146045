import { LightbulbOutlined } from '@mui/icons-material'
import { Text } from '@renderer-ui-library/atoms'
import { BoxContainer } from '@renderer-ui-library/molecules'
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@renderer-ui-library/mui'
import { localeNamespace } from '@internal/utils/machine/i18n/Locale'
import { translations } from 'i18n/translations'
import { useTranslation } from 'next-i18next'
import React from 'react'
import styles from './noResultsTips.module.scss'

const ListItemComponent: React.FC = (props: { children?: React.ReactNode }) => (
  <ListItem>
    <ListItemIcon>
      <LightbulbOutlined fontSize='small' color='disabled' />
    </ListItemIcon>
    <Typography component={ListItemText} color='textSecondary' variant='body2'>
      {props.children}
    </Typography>
  </ListItem>
)

export const NoResultsTips: React.FC = () => {
  const { t } = useTranslation(localeNamespace.common)

  return (
    <BoxContainer>
      <Typography variant='h4'>
        {t(translations.searchResultsNoResultsTipHeadline)}
      </Typography>
      <Typography color='textSecondary'>
        {t(translations.searchResultsNoResultsTipSubtitle)}
      </Typography>
      <div className={styles.list}>
        <Text
          components={{ li: ListItemComponent }}
          variant='body2'
          text={t(translations.searchResultsNoResultsTips)}
        ></Text>
      </div>
    </BoxContainer>
  )
}
