import { localeNamespace } from './i18n/Locale'
import { TLocale } from './i18n/TLocale'
import { MachineType } from './MachineType'
import { useTranslation } from 'next-i18next'
import { useCallback } from 'react'

export const useMachineTypeName = () => {
  const { t } = useTranslation(localeNamespace.machineTypeNames)

  const getMachineTypeName = useCallback(
    (machineType: MachineType, type: 'plural' | 'singular') => {
      const translation: { one: string; many: string } = t(machineType, {
        returnObjects: true,
      })
      const value = type === 'singular' ? translation.one : translation.many
      return value
    },
    [t]
  )

  const lowerCaseMachineTypeName = useCallback(
    (machineType: string, locale: TLocale) =>
      !['de'].includes(locale) ? machineType.toLowerCase() : machineType,
    []
  )

  return { getMachineTypeName, lowerCaseMachineTypeName }
}
