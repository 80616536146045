import { localeNamespace } from '@internal/utils/machine/i18n/Locale'
import { FilterableMachineAttributes } from '@internal/utils/search/FilterableMachineAttributes'
import { TFilterableMachineAttribute } from '@internal/utils/search/TFilterableMachineAttribute'
import { translations } from 'i18n/translations'
import { useTranslation } from 'next-i18next'
import { useMemo } from 'react'

const yearNow = new Date().getFullYear()

export const useRangeFilterOptions = (
  machineAttribute: TFilterableMachineAttribute,
  defaultLabel: string
) => {
  const { t } = useTranslation(localeNamespace.common)

  return useMemo(() => {
    let label = defaultLabel
    let unit: string | undefined
    let minLabel = t(translations.searchResultsFiltersRangeMinLabel)
    let minPlaceholder = t(translations.searchResultsFiltersRangeMinPlaceholder)
    let maxLabel = t(translations.searchResultsFiltersRangeMaxLabel)
    let maxPlaceholder = t(translations.searchResultsFiltersRangeMaxPlaceholder)
    let minLimit: number = 0
    let maxLimit: number = Number.MAX_VALUE

    switch (machineAttribute) {
      case FilterableMachineAttributes.ENGINE_HP:
        label = t(translations.searchResultsFiltersPowerLabel)
        unit = t(translations.enginePowerUnitHP)
        minLimit = 1
        maxLimit = 1000
        break

      case FilterableMachineAttributes.YEAR_OF_PRODUCTION:
        label = t(translations.searchResultsFiltersYearLabel)
        minLimit = 1970
        maxLimit = yearNow
        minLabel = t(translations.searchResultsFiltersRangeMinYearLabel)
        maxLabel = t(translations.searchResultsFiltersRangeMaxYearLabel)
        break

      case FilterableMachineAttributes.PRICE_NET_EUR:
        label = t(translations.searchResultsFiltersPriceLabel)
        minLimit = 1
        maxLimit = 1000000

        unit = '€'
        break

      case FilterableMachineAttributes.ENGINE_HOURS:
        unit = t(translations.hoursUnit)
        break
    }

    return {
      label,
      unit,
      minLabel,
      minPlaceholder,
      maxLabel,
      maxPlaceholder,
      minLimit,
      maxLimit,
    }
  }, [machineAttribute, defaultLabel, t])
}
