import { useMachineTypeName } from '@internal/utils/machine/useMachineTypeName'
import {
  getSearchResultsUrl,
  SearchResultsParams,
} from '@internal/utils/routing/getSearchResultsUrl'
import { TLocale } from '@internal/utils/machine/i18n/Locale'
import { useRouter } from 'next/router'
import { useCallback } from 'react'
import { defaultLocale } from 'utils/constants'

export const useSearchResultsRouting = () => {
  const { push, replace, locale: routerLocale } = useRouter()
  const locale = (routerLocale ?? defaultLocale) as TLocale
  const { getMachineTypeName } = useMachineTypeName()

  const gotoSearchResults = useCallback(
    (
      params: SearchResultsParams & {
        replace?: boolean
      },
      shallow = true
    ) => {
      const { replace: doReplace, machineType, ...p } = params
      const url = getSearchResultsUrl(locale, {
        ...p,
        machineTypeName: params.machineType
          ? getMachineTypeName(params.machineType, 'plural')
          : undefined,
      })

      if (doReplace) {
        replace(url, undefined, { shallow })
      } else {
        push(url, undefined, { shallow })
      }
    },
    [getMachineTypeName, locale, push, replace]
  )

  return { gotoSearchResults }
}
