import { FilterableBaleChamberOption } from './FilterableBaleChamberOptions'
import { FilterableHeaderTypeOptions } from './FilterableHeaderTypeOptions'
import { TFilterableBaleChamberOption } from './TFilterableBalerChamberOption'
import { TFilterableHeaderTypeOption } from './TFilterableHeaderTypeOption'

export const isValidBaleChamberOption = (
  option: string | TFilterableBaleChamberOption
): option is TFilterableBaleChamberOption =>
  Object.values(FilterableBaleChamberOption).includes(option)

export const isValidHeaderTypeOption = (
  option: string | TFilterableHeaderTypeOption
): option is TFilterableHeaderTypeOption =>
  Object.values(FilterableHeaderTypeOptions).includes(option)
