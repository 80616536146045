import {
  BrandSelectFilter,
  TFilterOption,
} from '@renderer-ui-library/molecules'
import { useMediaQuery } from '@renderer-ui-library/mui/utils/mediaQuery/useMediaQuery'
import { localeNamespace } from '@internal/utils/machine/i18n/Locale'
import { MachineType } from '@internal/utils/machine/MachineType'
import { useSearchParams } from 'blocks/SearchResultsBlock/useSearchParams'
import { useSearchResultsRouting } from 'blocks/SearchResultsBlock/useSearchResultsRouting'
import { translations } from 'i18n/translations'
import { useTranslation } from 'next-i18next'
import React, { useCallback } from 'react'
import { tracker } from 'utils/tracking/tracker'
import { SearchFilterCategory } from './SearchFilterCategory/SearchFilterCategory'

type Props = {
  selectedMachineType: MachineType
}

export const BrandsFilter: React.FC<Props> = React.memo(
  ({ selectedMachineType }) => {
    const { brands, ...searchParams } = useSearchParams()
    const { gotoSearchResults } = useSearchResultsRouting()
    const { t } = useTranslation(localeNamespace.common)
    const { isMobile } = useMediaQuery()

    const handleBrandsSelectOnChange = useCallback(
      (newBrands: TFilterOption[] | null) => {
        const brands = newBrands ? newBrands.map((brand) => brand.id) : []
        gotoSearchResults(
          {
            ...searchParams,
            brands,
            amount: undefined,
          },
          isMobile
        )

        tracker.trackEvent({
          name: 'filter-applied',
          filter_placement: 'serp',
          filter_category: 'brand',
          filter_brand: brands.join(','),
          is_interaction: true,
        })
      },
      [gotoSearchResults, isMobile, searchParams]
    )

    return (
      <SearchFilterCategory
        title={t(translations.searchResultsFiltersBrandsLabel)}
      >
        <BrandSelectFilter
          selectedMachineType={selectedMachineType}
          selectedBrands={brands.map((b) => ({
            id: b,
            label: b,
          }))}
          onChange={handleBrandsSelectOnChange}
        />
      </SearchFilterCategory>
    )
  }
)

BrandsFilter.displayName = 'BrandsFilter'
