import { MachineSubType, MachineType, SubTypes } from './MachineType'

export const isValidMachineType = (
  machineType: string
): machineType is MachineType =>
  Object.values(MachineType).indexOf(machineType as MachineType) > -1

export const isValidMachineSubType = (
  machineSubType: SubTypes[keyof SubTypes],
  machineType: MachineType
): machineSubType is SubTypes[keyof SubTypes] => {
  return Object.values(MachineSubType[machineType]).indexOf(machineSubType) > -1
}
