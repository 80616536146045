import { Text } from '@renderer-ui-library/atoms'
import { localeNamespace } from '@internal/utils/machine/i18n/Locale'
import { translations } from 'i18n/translations'
import { useTranslation } from 'next-i18next'
import React, { memo } from 'react'

interface AgreementProps {
  className?: string
}

export const AgreementText: React.FC<AgreementProps> = memo(({ className }) => {
  const { t } = useTranslation(localeNamespace.common)

  return (
    <div className={className}>
      <Text variant='body2' text={t(translations.searchRequestAgreementText)} />
    </div>
  )
})

AgreementText.displayName = 'AgreementText'
