import { TNavigation } from '@internal/utils/navigation/TNavigation'
import { TNavigationEntry } from '@internal/utils/navigation/TNavigationEntry'
import { KeyboardArrowDown } from '@mui/icons-material'
import {
  ClickAwayListener,
  Paper,
  Popover,
  Typography,
} from '@renderer-ui-library/mui'
import Colors from '@renderer-ui-library/mui/base/colors.module.scss'
import { TLocale } from '@internal/utils/machine/i18n/TLocale'
import classNames from 'classnames'
import Link from 'next/link'
import { useCallback, useState } from 'react'
import { AllMachinesFooter } from '../../../../../AllMachinesFooter/AllMachinesFooter'
import { AllMachinesMenu } from '../../../../../AllMachinesMenu/AllMachinesMenu'
import { getNavigationItemLabel } from '../../../../../getNavigationItemLabel'
import styles from './subnavigation.module.scss'

export type Props = {
  locale: TLocale
  brandsNavigation: TNavigation
  allOffersUrl: string
  entry: TNavigationEntry
}

export const AllMachinesSubnavigation: React.FC<Props> = (props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const { locale, entry, brandsNavigation, allOffersUrl } = props

  const toggleOpen = useCallback(
    (ev: React.MouseEvent<HTMLElement>) =>
      setAnchorEl((anchorEl) => {
        if (anchorEl) {
          return null
        }
        return ev.currentTarget as HTMLElement
      }),
    []
  )
  const handleClickEntry = useCallback(() => setAnchorEl(null), [])
  const close = useCallback(() => setAnchorEl(null), [])

  const handleLinkClick = useCallback((event: React.MouseEvent) => {
    const openLink = event.ctrlKey || event.metaKey
    if (openLink) {
      return
    }
    event.preventDefault()
  }, [])

  const isOpen = !!anchorEl

  return (
    <div className={styles.wrapper}>
      {/* We're wrapping this in a Link for SEO */}
      <Link
        href={entry.route}
        prefetch={false}
        locale={locale}
        onClick={handleLinkClick}
      >
        <Typography
          className={styles.button}
          onClick={toggleOpen}
          color={Colors.textPrimary}
          variant={'body1'}
          component='div'
        >
          {getNavigationItemLabel(entry)}
          <div className={classNames(styles.icon, { [styles.open]: isOpen })}>
            <KeyboardArrowDown fontSize='inherit' />
          </div>
        </Typography>
      </Link>
      <Popover
        disableScrollLock
        anchorEl={anchorEl}
        open={isOpen}
        anchorOrigin={{
          vertical: 36,
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        className={styles.popover}
      >
        <ClickAwayListener onClickAway={close}>
          <Paper elevation={4}>
            <AllMachinesMenu onClick={handleClickEntry} />
            <AllMachinesFooter
              onClick={handleClickEntry}
              brandsNavigation={brandsNavigation}
              allOffersUrl={allOffersUrl}
            />
          </Paper>
        </ClickAwayListener>
      </Popover>
    </div>
  )
}

AllMachinesSubnavigation.displayName = 'AllMachinesSubnavigation'
