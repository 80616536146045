import { RatioContainer } from '@renderer-ui-library/atoms'
import { TMedia } from '@renderer-ui-library/molecules'
import {
  CardActionArea,
  CardContent,
  Card as MuiCard,
  Typography,
} from '@renderer-ui-library/mui'
import { ImageRatio } from '@renderer-ui-library/mui/utils/media/ImageRatio'
import classNames from 'classnames'
import React from 'react'
import { getResponsiveSmallMediaSrc } from 'utils/media/getMediaSrc'
import styles from './card.module.scss'

export type Props = {
  media: TMedia
  title: string
  children: React.ReactNode
  isLink?: boolean
  grow?: boolean
  overlay?: boolean
  overlayImage?: string
}

const Content: React.FC<Props> = (props) => {
  return (
    <>
      <RatioContainer ratio={ImageRatio.Landscape}>
        {props.overlay && props.overlayImage && (
          <>
            <img
              src={props.overlayImage}
              alt='Sold Stamp'
              className={styles.overlay}
            />
            <img
              loading='lazy'
              className={classNames(styles.media, styles.grayscale)}
              alt={props.media.attributes.alternativeText}
              {...getResponsiveSmallMediaSrc(
                { data: props.media },
                {
                  ratio: ImageRatio.Landscape,
                  desktopViewportPercentage: 4,
                }
              )}
            />
          </>
        )}
        {!props.overlay && (
          <img
            loading='lazy'
            className={styles.media}
            alt={props.media.attributes.alternativeText}
            {...getResponsiveSmallMediaSrc(
              { data: props.media },
              {
                ratio: ImageRatio.Landscape,
              }
            )}
          />
        )}
      </RatioContainer>
      <CardContent className={styles.content}>
        <>
          <Typography
            variant='h5'
            title={props.title}
            noWrap
            className={styles.title}
          >
            {props.title}
          </Typography>
          {props.children}
        </>
      </CardContent>
    </>
  )
}

export const Card: React.FC<Props> = React.memo((props) => {
  return (
    <div className={classNames({ [styles.grow]: props.grow })}>
      <MuiCard variant='outlined'>
        {props.isLink ? (
          <CardActionArea>
            <Content {...props} />
          </CardActionArea>
        ) : (
          <Content {...props} />
        )}
      </MuiCard>
    </div>
  )
})

Card.displayName = 'Card'
