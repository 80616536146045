import { AccountBalance } from '@mui/icons-material'
import { Button } from '@renderer-ui-library/atoms'
import { useMediaQuery } from '@renderer-ui-library/mui/utils/mediaQuery/useMediaQuery'
import { localeNamespace } from '@internal/utils/machine/i18n/Locale'
import { translations } from 'i18n/translations'
import { useTranslation } from 'next-i18next'
import React, { useContext } from 'react'
import { LeasingQualifiedContext } from './leasingQualifiedContext'
import styles from './leasingQualifiedFilter.module.scss'

export const LeasingQualifiedFilter = React.memo(() => {
  const { t } = useTranslation(localeNamespace.common)
  const { toggleLeasingQualified, isLeasingQualifiedEnabled } = useContext(
    LeasingQualifiedContext
  )
  const { isMobile } = useMediaQuery()

  return (
    <Button
      variant='text'
      noMinWidth
      color={isLeasingQualifiedEnabled ? 'secondary' : 'primary'}
      onClick={toggleLeasingQualified}
    >
      <AccountBalance
        className={styles.icon}
        color={isLeasingQualifiedEnabled ? 'secondary' : 'primary'}
      />
      {isMobile
        ? t(translations.searchResultsFilterLeasingQualifiedLabelMobile)
        : t(translations.searchResultsFilterLeasingQualifiedLabel)}
    </Button>
  )
})

LeasingQualifiedFilter.displayName = 'LeasingQualifiedFilter'
