import { Typography } from '@renderer-ui-library/mui'
import Colors from '@renderer-ui-library/mui/base/colors.module.scss'
import { TLocale } from '@internal/utils/machine/i18n/Locale'
import Link from 'next/link'
import { SyntheticEvent } from 'react'
import styles from './link.module.scss'

type Props = {
  href: string
  onClick?: (
    event: SyntheticEvent<HTMLAnchorElement> | SyntheticEvent<HTMLSpanElement>
  ) => void
  external?: boolean
  textColorVariant?: 'primary' | 'invertedPrimary' | 'info'
  textFontVariant?: 'primary' | 'secondary' | 'info'
  prefetch?: boolean
  children?: React.ReactNode
  locale?: TLocale
}

export const LinkComponent: React.FC<Props> = (props) => (
  <div className={styles.link}>
    <Link
      href={props.href}
      passHref
      prefetch={props.prefetch}
      locale={props.locale}
      onClick={props.onClick}
      target={props.external ? '_blank' : undefined}
      tabIndex={0}
    >
      <Typography
        variant={props.textFontVariant === 'secondary' ? 'body2' : 'body1'}
        color={
          props.textColorVariant === 'primary'
            ? Colors.textPrimary
            : Colors.textInvertedPrimary
        }
      >
        {props.children}
      </Typography>
    </Link>
  </div>
)
