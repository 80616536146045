import { FilterableMachineAttributes } from '@internal/utils/search/FilterableMachineAttributes'
import { TFilterableMachineAttribute } from '@internal/utils/search/TFilterableMachineAttribute'
import { RangeSelectValue } from 'blocks/SearchCoverBlock/RangeSelectFilter'
import { tracker } from '../../../utils/tracking/tracker'

export const trackRangeFilterChange = (
  machineAttribute: TFilterableMachineAttribute,
  value: { min?: RangeSelectValue; max?: RangeSelectValue }
) => {
  switch (machineAttribute) {
    case FilterableMachineAttributes.ENGINE_HOURS:
      tracker.trackEvent({
        name: 'filter-applied',
        filter_placement: 'serp',
        filter_category: 'hours',
        filter_hours_min: value.min,
        filter_hours_max: value.max,
        is_interaction: true,
      })
      break
    case FilterableMachineAttributes.YEAR_OF_PRODUCTION:
      tracker.trackEvent({
        name: 'filter-applied',
        filter_placement: 'serp',
        filter_category: 'year',
        filter_year_min: value.min,
        filter_year_max: value.max,
        is_interaction: true,
      })
      break
    case FilterableMachineAttributes.PRICE_NET_EUR:
      tracker.trackEvent({
        name: 'filter-applied',
        filter_placement: 'serp',
        filter_category: 'price',
        filter_price_min: value.min,
        filter_price_max: value.max,
        is_interaction: true,
      })
      break
    case FilterableMachineAttributes.ENGINE_HP:
      tracker.trackEvent({
        name: 'filter-applied',
        filter_placement: 'serp',
        filter_category: 'hp',
        filter_hp_min: value.min,
        filter_hp_max: value.max,
        is_interaction: true,
      })
      break
    default:
      tracker.trackEvent({
        name: 'filter-applied',
        filter_placement: 'serp',
        filter_category: 'range-filter-other',
        filter_min: value.min,
        filter_max: value.max,
        filter_machine_attribute: machineAttribute,
        is_interaction: true,
      })
  }
}
