import { Loader } from '@googlemaps/js-api-loader'
import { environment } from '@internal/environment/environment'
import { translations } from '@internal/i18n/translations'
import customers from '@internal/utils/geoLocation/customers.json'
import { LocationProps } from '@internal/utils/geoLocation/getGeoLocation'
import { tracker } from '@internal/utils/tracking/tracker'
import { DynamicTrustPilot } from '@renderer-ui-library/molecules'
import { Button, Typography } from '@renderer-ui-library/mui'
import { TLocale, localeNamespace } from '@internal/utils/machine/i18n/Locale'
import classNames from 'classnames'
import _ from 'lodash'
import { useTranslation } from 'next-i18next'
import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import styles from './customerMap.module.scss'

const { NEXT_PUBLIC_GOOGLE_MAPS_API_KEY } = environment

const mapId = '0c50c5c1-06cd-45b6-8cba-57959b6cece9'

interface Props {
  location: LocationProps
  locale: TLocale
}

export const CustomerMap: React.FC<Props> = memo(({ location, locale }) => {
  const [isMapExpanded, setMapExpanded] = useState(false)

  const handleCtaClick = useCallback(() => {
    setMapExpanded((prev) => !prev)

    tracker.trackEvent({
      name: 'click',
      target_name: 'block.happy_customer_map',
      is_interaction: true,
    })
  }, [])

  const { t } = useTranslation(localeNamespace.common)
  const containerRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const fn = async () => {
      if (!containerRef.current) {
        return
      }

      const loader = new Loader({
        apiKey: NEXT_PUBLIC_GOOGLE_MAPS_API_KEY!,
        version: 'weekly',
      })

      const { Map } = await loader.importLibrary('maps')
      const { AdvancedMarkerElement } = await loader.importLibrary('marker')

      const map = new Map(containerRef.current, {
        mapId,
        zoom: 7,
        maxZoom: 10,
      })

      map.setCenter({ lat: location.lat, lng: location.lng })

      _.uniqBy(customers.positions, 'roughGeoString').map(({ geo }) => {
        const marker = document.createElement('div')
        marker.className = styles.marker

        return new AdvancedMarkerElement({
          map,
          position: {
            lat: Math.round(geo.lat * 100) / 100,
            lng: Math.round(geo.lng * 100) / 100,
          },
          content: marker,
        })
      })
    }

    fn()
  })

  return (
    <>
      <div
        className={classNames(styles.mapContainer, {
          [styles.mapContainerExpanded]: isMapExpanded,
        })}
      >
        <div
          ref={containerRef}
          className={classNames(styles.map, {
            [styles.mapExpanded]: isMapExpanded,
          })}
        />
        <div
          className={classNames(styles.mapBackground, {
            [styles.mapBackgroundExpanded]: isMapExpanded,
          })}
        />

        <Button
          className={classNames(styles.mapTitleWrapper, {
            [styles.mapTitleWrapperExpanded]: isMapExpanded,
          })}
          onClick={handleCtaClick}
          size='small'
        >
          <Typography
            variant='h6'
            color='primary'
            className={styles.happyCustomerCta}
            component='span'
          >
            {t(translations.happyCustomerCta)}
          </Typography>
        </Button>
      </div>
      <div className={styles.trustPilotWrapper}>
        <DynamicTrustPilot theme='light' locale={locale} variant='small' />
      </div>
    </>
  )
})

CustomerMap.displayName = 'CustomerMap'
