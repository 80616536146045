import { Tooltip } from '@renderer-ui-library/mui'
import { localeNamespace } from '@internal/utils/machine/i18n/Locale'
import copy from 'copy-to-clipboard'
import { translations } from 'i18n/translations'
import { useTranslation } from 'next-i18next'
import { useCallback, useEffect, useRef, useState } from 'react'
import styles from './clickToCopyWrapper.module.scss'

type Props = {
  text: string
  children?: React.ReactNode
}
export const ClickToCopyWrapper: React.FC<Props> = ({ text, children }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [showCopiedInfo, setShowCopiedInfo] = useState(false)
  const { t } = useTranslation(localeNamespace.common)

  const mounted = useRef(true)

  const show = useCallback(() => setIsOpen(true), [])
  const hide = useCallback(() => {
    if (!showCopiedInfo) {
      setIsOpen(false)
    }
  }, [showCopiedInfo])
  const copyToClipboard = useCallback(() => {
    copy(text)
    setIsOpen(true)
    setShowCopiedInfo(true)

    setTimeout(() => {
      if (mounted.current) {
        setIsOpen(false)
      }
    }, 1500)
    setTimeout(() => {
      if (mounted.current) {
        setShowCopiedInfo(false)
      }
    }, 2000)
  }, [text, mounted])

  useEffect(() => {
    return () => {
      mounted.current = false
    }
  }, [])

  return (
    <Tooltip
      onMouseEnter={show}
      onMouseLeave={hide}
      open={isOpen}
      title={
        (showCopiedInfo
          ? t(translations.copiedHint)
          : t(translations.clickToCopyHint)) ?? ''
      }
      placement='top'
      arrow
    >
      <span onClick={copyToClipboard} className={styles.wrapper}>
        {children}
      </span>
    </Tooltip>
  )
}
