import { getGAClientId } from '@internal/utils/gaClient/gaClientId'
import { Machine } from '@internal/utils/machine/Machine'
import { Close, SendOutlined } from '@mui/icons-material'
import {
  WizardContactStep,
  WizardFinishedStep,
  WizardOverlay,
  WizardOverlayProps,
} from '@renderer-ui-library/molecules'
import { SelectChangeEvent } from '@renderer-ui-library/mui'
import { TFormStep } from '@renderer-ui-library/organisms'
import { FormType, IContactForm } from '@internal/utils/form/'
import { localeNamespace } from '@internal/utils/machine/i18n/Locale'
import { TLocale } from '@internal/utils/machine/i18n/TLocale'
import { useFormik } from 'formik'
import { translations } from 'i18n/translations'
import { useTranslation } from 'next-i18next'
import React, {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { getCountryCodeByLocale } from 'utils/contact/getCountryCodeByLocale'
import { CountryCodeType } from 'utils/contact/phoneCountryCodes'
import { useValidationSchema } from 'utils/form/useValidationSchema'
import { formatPrice } from 'utils/numbers/formatPrice'
import { trackConversion } from 'utils/tracking/trackConversion'
import { v4 as uuidV4 } from 'uuid'
import { postForm } from '@internal/services/apiClient'

export interface RequestContactWizardProps {
  machine?: Machine
  locale: TLocale
  machineImage: React.ReactNode | null
  open: boolean
  onCloseClick: () => void
}

interface RequestContactWizardFormValues {
  areaCode: CountryCodeType
  phone: number | string
  smsAgreement: boolean
  message: string
  firstName: string
  lastName: string
  mail: string
  newsletter: boolean
  budget: string
  timing: string
  reason: string
}

export const RequestContactWizard: React.FC<RequestContactWizardProps> =
  React.memo((props) => {
    const [step, setStep] = useState<TFormStep>(0)
    const { t } = useTranslation(localeNamespace.common)
    const { contactUsValidationSchema } = useValidationSchema()
    const areaCode = getCountryCodeByLocale(props.locale)
    const [gaClientId, setGAClientId] = useState<string | undefined>()

    const validateFormFields = useCallback(
      (values: RequestContactWizardFormValues) => {
        try {
          contactUsValidationSchema.validateSync(values, {
            abortEarly: false,
          })
          return {}
        } catch (errors) {
          return { name: 'Invalid input' }
        }
      },
      [contactUsValidationSchema]
    )

    useEffect(() => {
      getGAClientId().then((clientId) => setGAClientId(clientId as string))
    }, [])

    const formik = useFormik({
      initialValues: {
        areaCode: areaCode,
        message: props.machine
          ? (t(translations.requestInformationWizardMessageInputText, {
              machine: props.machine?.name,
              machineGroupId: props.machine?.machineGroupId,
            }) as string)
          : t(translations.contactInformationWizardMessageText),
        phone: '',
        firstName: '',
        lastName: '',
        mail: '',
        newsletter: false,
        smsAgreement: false,
        budget: '',
        timing: '',
        reason: '',
      },
      validate: validateFormFields,
      validateOnBlur: true,
      validateOnMount: true,
      onSubmit: (values) => {
        if (formik.isValid) {
          setStep('loading')

          const transactionId = uuidV4()
          const data: IContactForm = {
            formName: FormType.ContactUs,
            eFarmId: props.machine?.machineGroupId,
            locale: props.locale,
            email: values.mail,
            message: values.message,
            firstName: values.firstName,
            lastName: values.lastName,
            pageSource: location.href,
            phoneNumber: `+${values.areaCode.phone}${values.phone}`,
            newsletter: values.newsletter,
            smsAgreement: values.smsAgreement,
            GA_Client_ID: gaClientId,
            country: values.areaCode.label,
            // don't send empty strings
            budget: values.budget || undefined,
            timing: values.timing || undefined,
            reason: values.reason || undefined,
            transactionId,
          }

          postForm(data)
            .then(() => {
              trackConversion({
                formType: 'contact-form',
                machineGroupId: props.machine?.machineGroupId,
                machine: props.machine,
                transactionId,
              })
              setStep('success')
            })
            .catch(() => {
              setStep('error')
            })
            .finally(() => {
              formik.resetForm()
            })
        }
      },
    })

    const formattedPrice = props.machine?.price
      ? formatPrice(props.locale, props.machine.price)
      : t(translations.priceOnRequest)

    const propsOnCloseClick = props.onCloseClick
    const onCloseClick = useCallback(() => {
      setStep(0)
      formik.resetForm()
      propsOnCloseClick()
    }, [formik, propsOnCloseClick])

    const submitData = useCallback(() => {
      formik.handleSubmit()
    }, [formik])

    const handleCountryCodeChange = useCallback(
      (e: SyntheticEvent<Element, Event>, newValue: CountryCodeType | null) => {
        formik.setFieldValue('areaCode', newValue)
      },
      [formik]
    )

    const handleBudgetChange = useCallback(
      (e: SelectChangeEvent) => {
        formik.setFieldValue('budget', e.target.value)
      },
      [formik]
    )

    const handleTimingChange = useCallback(
      (e: SelectChangeEvent) => {
        formik.setFieldValue('timing', e.target.value)
      },
      [formik]
    )

    const handleReasonChange = useCallback(
      (e: SelectChangeEvent) => {
        formik.setFieldValue('reason', e.target.value)
      },
      [formik]
    )

    const wizardContent = useMemo<
      Omit<
        WizardOverlayProps,
        | 'open'
        | 'onCloseClick'
        | 'machineImage'
        | 'mobileHeaderTitle'
        | 'mobileHeaderPrice'
        | 'mobileHeaderInfo'
      >
    >(() => {
      switch (step) {
        case 0: {
          return {
            title: t(translations.requestInformationWizardMessageTitle),
            subtitle: t(translations.requestInformationWizardContactSubtitle),
            onPrevClick: onCloseClick,
            onNextClick: submitData,
            prevButtonLabel: t(translations.WizardFinishButtonLabel),
            nextButtonLabel: t(translations.WizardSubmitButtonLabel),
            prevButtonIcon: Close,
            nextButtonIcon: SendOutlined,
            disableNext: !formik.isValid || !formik.dirty,
            children: (
              <WizardContactStep
                onChange={formik.handleChange}
                onChangeCountryCode={handleCountryCodeChange}
                onBudgetChange={handleBudgetChange}
                onTimingChange={handleTimingChange}
                onReasonChange={handleReasonChange}
                onBlur={formik.handleBlur}
                data={formik.values}
                phoneError={formik.touched.phone && formik.errors.phone}
                mailError={formik.touched.mail && formik.errors.mail}
                firstNameError={
                  formik.touched.firstName && formik.errors.firstName
                }
                lastNameError={
                  formik.touched.lastName && formik.errors.lastName
                }
              />
            ),
          }
        }
        case 'success': {
          return {
            mobileCenterAlign: true,
            children: (
              <WizardFinishedStep
                title={t(translations.requestInspectionWizardSuccessTitle)}
                message={t(translations.requestInspectionWizardSuccessText)}
                buttonLabel={t(translations.WizardFinishButtonLabel)}
                onCloseClick={onCloseClick}
                variant='success'
              />
            ),
          }
        }
        case 'error': {
          return {
            mobileCenterAlign: true,
            children: (
              <WizardFinishedStep
                title={t(translations.requestInformationWizardErrorTitle)}
                message={t(translations.requestInformationWizardErrorText)}
                buttonLabel={t(translations.WizardFinishButtonLabel)}
                onCloseClick={onCloseClick}
                variant='error'
              />
            ),
          }
        }
        case 'loading': {
          return {
            loading: true,
            children: null,
          }
        }
        default: {
          return {
            mobileCenterAlign: true,
            children: (
              <WizardFinishedStep
                title={t(translations.requestInformationWizardErrorTitle)}
                message={t(translations.requestInformationWizardErrorText, {
                  machine: props.machine?.name,
                })}
                buttonLabel={t(translations.WizardFinishButtonLabel)}
                onCloseClick={onCloseClick}
                variant='error'
              />
            ),
          }
        }
      }
    }, [
      step,
      t,
      onCloseClick,
      submitData,
      formik.isValid,
      formik.dirty,
      formik.handleChange,
      formik.handleBlur,
      formik.values,
      formik.touched.phone,
      formik.touched.mail,
      formik.touched.firstName,
      formik.touched.lastName,
      formik.errors.phone,
      formik.errors.mail,
      formik.errors.firstName,
      formik.errors.lastName,
      handleCountryCodeChange,
      handleBudgetChange,
      handleTimingChange,
      handleReasonChange,
      props.machine?.name,
    ])

    return (
      <WizardOverlay
        open={props.open}
        onCloseClick={onCloseClick}
        machineImage={props.machineImage}
        mobileHeaderTitle={`${props.machine?.brand} ${props.machine?.model}`}
        mobileHeaderPrice={formattedPrice ?? t(translations.priceOnRequest)}
        mobileHeaderInfo={`id: ${props.machine?.machineGroupId}`}
        {...wizardContent}
      />
    )
  })

RequestContactWizard.displayName = 'RequestContactWizard'
