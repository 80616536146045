import { SvgIconComponent } from '@mui/icons-material'
import { Button } from '@renderer-ui-library/atoms'
import { Typography } from '@renderer-ui-library/mui'
import { useMediaQuery } from '@renderer-ui-library/mui/utils/mediaQuery/useMediaQuery'
import { localeNamespace } from '@internal/utils/machine/i18n/Locale'
import classNames from 'classnames'
import { translations } from 'i18n/translations'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import React, { useMemo } from 'react'
import styles from './serviceItem.module.scss'

interface Props {
  title: string
  content: string
  url?: string
  buttonLabel?: string
  onClick?: () => void
  icon: SvgIconComponent
  inline?: boolean
}

export const ServiceItem: React.FC<Props> = React.memo(
  ({ inline, ...props }) => {
    const { t } = useTranslation(localeNamespace.common)
    const { isTablet } = useMediaQuery()
    const IconComponent = props.icon

    const content = useMemo(
      () => (
        <div
          className={classNames(styles.container, {
            [styles.reverseDirection]: !inline,
          })}
        >
          <div
            className={classNames(styles.icon, {
              [styles.noBorder]: !inline,
            })}
          >
            <IconComponent
              color={isTablet ? undefined : 'disabled'}
              fontSize='inherit'
            />
          </div>

          {isTablet ? (
            <Typography
              textAlign={inline ? 'left' : 'center'}
              variant={!inline ? 'body2' : 'body1'}
            >
              {props.title}
            </Typography>
          ) : (
            <>
              <Typography
                variant='h6'
                textAlign={inline ? 'center' : 'left'}
                gutterBottom={inline}
              >
                {props.title}
              </Typography>
              <Typography
                variant='caption'
                color='textSecondary'
                textAlign='center'
              >
                {props.content}
              </Typography>
            </>
          )}
        </div>
      ),
      [IconComponent, inline, isTablet, props.content, props.title]
    )

    if (props.url) {
      return (
        <Link
          href={props.url}
          passHref
          prefetch={false}
          onClick={props.onClick}
        >
          <div
            className={classNames(styles.wrapper, {
              [styles.fullWidth]: !inline,
            })}
          >
            {content}
            {!isTablet && (
              <div className={styles.button}>
                <Button variant='outlined' size='small' noMinWidth>
                  {props.buttonLabel
                    ? props.buttonLabel
                    : t(translations.headerMainNavServiceItemButtonDefault)}
                </Button>
              </div>
            )}
          </div>
        </Link>
      )
    }

    return (
      <div
        className={classNames(styles.wrapper, {
          [styles.fullWidth]: !inline,
        })}
      >
        {content}
      </div>
    )
  }
)

ServiceItem.displayName = 'ServiceItem'
