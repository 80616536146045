import { MachineContext } from '@internal/utils/machine/MachineContext'
import { UserContext } from '@internal/utils/user/UserContext'
import { useContext } from 'react'

export const useIsNotRegisterableInItaly = () => {
  const { user } = useContext(UserContext)
  const { machine } = useContext(MachineContext)

  return user.country === 'IT' && machine?.serp.countries['IT'] === false
}
