import { TSalesManager } from '@internal/services/TSalesManager'
import { Typography } from '@renderer-ui-library/mui'
import React from 'react'
import { getResponsiveSmallMediaSrc } from 'utils/media/getMediaSrc'
import styles from './salesManagerContact.module.scss'

interface Props {
  salesManager: TSalesManager
}

export const SalesManagerContact: React.FC<Props> = React.memo(
  ({ salesManager }) => {
    return (
      <div className={styles.salesConatiner}>
        <img
          loading='lazy'
          className={styles.image}
          key={salesManager.avatarImage.data.id}
          alt={salesManager.avatarImage.data.attributes.alternativeText}
          {...getResponsiveSmallMediaSrc(salesManager.avatarImage, {
            ratio: 1 / 1,
            desktopViewportPercentage: 3,
          })}
          sizes='400px'
        />
        <Typography variant='body2'>
          {salesManager.firstName} {salesManager.lastName}
        </Typography>

        <Typography variant='caption' color='textSecondary'>
          {salesManager.jobTitle}
        </Typography>
      </div>
    )
  }
)

SalesManagerContact.displayName = 'SalesManagerContact'
