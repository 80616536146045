import { SearchModel } from '@internal/blocks/SearchResultsBlock/TSearchParams'
import { OverlayColor } from '@renderer-ui-library/mui/base/useCreateTheme'
import { localeNamespace } from '@internal/utils/machine/i18n/Locale'
import { ModelSelectFilter } from 'blocks/SearchCoverBlock/ModelSelectFilter'
import { useSearchParams } from 'blocks/SearchResultsBlock/useSearchParams'
import { useSearchResultsRouting } from 'blocks/SearchResultsBlock/useSearchResultsRouting'
import { translations } from 'i18n/translations'
import { useTranslation } from 'next-i18next'
import React, { useCallback } from 'react'
import { tracker } from 'utils/tracking/tracker'
import { SearchFilterCategory } from './SearchFilterCategory/SearchFilterCategory'

export const ModelFilter: React.FC = React.memo(() => {
  const { model, brands, machineType, ...searchParams } = useSearchParams()
  const { gotoSearchResults } = useSearchResultsRouting()
  const { t } = useTranslation(localeNamespace.common)

  const handleModelSelectOnChange = useCallback(
    (model: SearchModel | null) => {
      if (model) {
        tracker.trackEvent({
          name: 'filter-applied',
          filter_placement: 'serp',
          filter_category: 'model',
          filter_model: model.name,
          is_interaction: true,
        })
      }
      gotoSearchResults({
        ...searchParams,
        brands,
        machineType,
        model: model ?? undefined,
      })
    },
    [brands, gotoSearchResults, machineType, searchParams]
  )

  return (
    <SearchFilterCategory
      title={t(translations.searchResultsFiltersModelLabel)}
    >
      <ModelSelectFilter
        brands={brands.map((b) => ({
          id: b,
          label: b,
        }))}
        colorOverlay={OverlayColor.Bright}
        model={model ?? null}
        onChange={handleModelSelectOnChange}
        disabled={brands.length !== 1}
      />
    </SearchFilterCategory>
  )
})

ModelFilter.displayName = 'ModelFilter'
