import { IGAFormFields } from '@internal/utils/form'
import { BalerSubType, MachineType } from '@internal/utils/machine/MachineType'
import { TFilterableMachineAttribute } from '@internal/utils/search/TFilterableMachineAttribute'

export enum NotificationFrequency {
  Daily = 'Daily',
  Weekly = 'Weekly',
}

export type TNotificationFrequency = keyof typeof NotificationFrequency

export enum SearchRequestStatus {
  Enabled = 'Enabled',
  Disabled = 'Disabled',
}

export enum SearchRequestSourceTypes {
  SearchRequest = 'SearchRequest',
  SearchRequestServiceCard = 'SearchRequestServiceCard',
  SearchRequestLessNoOfMachines = 'SearchRequestServiceCard',
  SearchRequestLandingPage = 'SearchRequestLandingPage',
}

export enum SearchRequestFirstMediumTypes {
  SearchRequest = 'Search Alert SERP Button',
  SearchRequestServiceCard = 'Search Alert SERP PromotionCard',
  SearchRequestLessNoOfMachines = 'Search Alert SERP MoreMachineBox',
  SearchRequestLandingPage = 'Search Alert Landing Page',
}

export type TSearchRequestStatus = keyof typeof SearchRequestStatus

export type TDriveWheels = 'Crawler tracks' | '4WD' | '2WD'
export type TCoupled =
  | 'Trailed'
  | 'Semi mounted'
  | 'rear mounted'
  | 'front mounted'
export type TPloughType = 'Single wheel' | 'Mounted' | 'Wheeled' | 'Front'
export type TBaleChamber =
  | 'FIXED_CHAMBER'
  | 'SEMI_VARIABLE_CHAMBER'
  | 'VARIABLE_CHAMBER'
export type TBalerType =
  | 'BALER_WRAPPER_COMBINATION'
  | 'HIGH_DENSITY_BALER'
  | 'OTHER'
  | 'ROUND_BALER'
  | 'SQUARE_BALER'
export type THeaderType =
  | 'FRONT_END_EXTENSION'
  | 'GPS_CUTTING_UNIT'
  | 'GRAIN_HEADER'
  | 'GRASS_SEED_HEADER'
  | 'HEADER'
  | 'MAIZE_HEADER'
  | 'MAIZE_PICKER'
  | 'OTHER'
  | 'PICKER'
  | 'PICK_UP'
  | 'RAPESEED_HEADER'
  | 'SUNFLOWER_HEADER'
export type TFeatures = 'Feature lists'

export enum SearchRequestLanguages {
  English = 'English',
  Albanian = 'Albanian',
  Bosnian = 'Bosnian',
  Bulgarian = 'Bulgarian',
  Castilian = 'Castilian',
  Chinese = 'Chinese',
  Croatian = 'Croatian',
  Czech = 'Czech',
  Danish = 'Danish',
  French = 'French',
  German = 'German',
  Greek = 'Greek',
  Greek_Modern = 'Greek (modern)',
  Hungarian = 'Hungarian',
  Italian = 'Italian',
  Other = 'Other',
  Polish = 'Polish',
  Portuguese = 'Portuguese',
  Romanian = 'Romanian',
  Russian = 'Russian',
  Serbian = 'Serbian',
  Slovenian = 'Slovenian',
  Spanish = 'Spanish',
  Ukrainian = 'Ukrainian',
}

export interface ISearchRequestRangeFilters {
  yearFrom?: string
  yearTo?: string
  maxPrice?: string
  minPrice?: string
  hpFrom?: number
  hpTo?: number
  engineHoursFrom?: number
  engineHoursTo?: number
  drumHoursMax?: number
  drumHoursMin?: number
  cuttingWidthMax?: number
  cuttingWidthMin?: number
  numberOfMaxBodies?: number
  numberOfMinBodies?: number
  liftingHeightMax?: number
  liftingHeightMin?: number
  numberOfDiscsMax?: number
  numberOfDiscsMin?: number
  workingWidthMax?: number
  workingWidthMin?: number
  capacityMax?: number
  capacityMin?: number
  pickupWidthMax?: number
  pickupWidthMin?: number
  balesTotalNumberMax?: number
  balesTotalNumberMin?: number
  rowNumberMax?: number
  rowNumberMin?: number
  maxSpeed?: number
  minSpeed?: number
  liftingCapacityMax?: number
  liftingCapacityMin?: number
  furrowsNumberMax?: number
  furrowsNumberMin?: number
  tankCapacityMax?: number
  tankCapacityMin?: number
  headerYearOfProductionMax?: number
  headerYearOfProductionMin?: number
}
export interface ISearchRequest extends IGAFormFields {
  searchRequestId: string
  status: TSearchRequestStatus
  newsletter?: boolean
  smsAgreement?: boolean
  notificationFrequency?: TNotificationFrequency
  machineCategory?: MachineType
  brands?: string[]
  locations?: string[]
  yearFrom?: string
  yearTo?: string
  maxPrice?: string
  minPrice?: string
  hpFrom?: number
  hpTo?: number
  engineHoursFrom?: number
  engineHoursTo?: number
  drumHoursMax?: number
  drumHoursMin?: number
  driveWheels?: TDriveWheels
  cuttingWidthMax?: number
  cuttingWidthMin?: number
  numberOfShakers?: string
  numberOfMaxBodies?: number
  numberOfMinBodies?: number
  liftingHeightMax?: number
  liftingHeightMin?: number
  numberOfDiscsMax?: number
  numberOfDiscsMin?: number
  coupled?: TCoupled
  ploughType?: TPloughType
  workingWidthMax?: number
  workingWidthMin?: number
  capacityMax?: number
  capacityMin?: number
  pickupWidthMax?: number
  pickupWidthMin?: number
  balesTotalNumberMax?: number
  balesTotalNumberMin?: number
  rowNumberMax?: number
  rowNumberMin?: number
  maxSpeed?: number
  minSpeed?: number
  liftingCapacityMax?: number
  liftingCapacityMin?: number
  baleChamber?: TBaleChamber
  balerType?: BalerSubType
  headerType?: THeaderType
  furrowsNumberMax?: number
  furrowsNumberMin?: number
  tankCapacityMax?: number
  tankCapacityMin?: number
  headerYearOfProductionMax?: number
  headerYearOfProductionMin?: number
  headerY?: number
  features?: TFilterableMachineAttribute[]
  model?: string
  machineSubType?: BalerSubType
  success?: boolean
}

export interface IDeleteSearchRequestPayload {
  searchRequestId: string
  feedback: string
}

export interface IUpdateSearchRequestPreferenceReq
  extends Omit<ISearchRequest, 'notificationFrequency' | 'machineSubType'> {
  searchRequestId: string
  status: TSearchRequestStatus
  notificationFrequency?: TNotificationFrequency
}

export interface ICreateSearchRequestRequestPayload
  extends Omit<ISearchRequest, 'searchRequestId' | 'status'> {
  email: string
  phoneNumber?: string
  firstName: string
  lastName: string
  language: SearchRequestLanguages
  machineOfferPeriod?: TNotificationFrequency
  source?: SearchRequestSourceTypes
  pageSource: string
  status?: TSearchRequestStatus
  model?: string
}
