import { Machine } from '@internal/utils/machine/Machine'
import { TLocale } from '@internal/utils/machine/i18n/TLocale'
import { useRouter } from 'next/router'
import { SystemPageKey } from 'page/SystemPageKey'
import { useCallback } from 'react'
import { useMachineTypeName } from 'utils/machine/useMachineTypeName'
import { buildMachineUrlPathParams } from '../buildMachineUrlPathParams'
import { urlFor } from '../urls/urlFor'

export const useMachineUrl = () => {
  const { getMachineTypeName } = useMachineTypeName()
  const {
    query: { ip },
  } = useRouter()
  const getMachineUrl = useCallback(
    (locale: TLocale, machine: Machine) =>
      urlFor(locale, {
        key: SystemPageKey.PDP,
        pathParams: {
          ...buildMachineUrlPathParams(locale, {
            machineGroupId: machine.machineGroupId,
            brand: machine.brand,
            model: machine.model,
            machineTypeName: getMachineTypeName(machine.machineType, 'plural'),
          }),
        },
        queryParams: { ip },
      }),
    [getMachineTypeName, ip]
  )

  return { getMachineUrl }
}
