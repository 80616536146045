import { Machine } from '@internal/utils/machine/Machine'
import { MachineType } from '@internal/utils/machine/MachineType'
import { noop } from 'lodash'
import { createContext } from 'react'

export type TComparisonAlertType =
  | 'reachedMaxMachines'
  | 'changedMachineType'
  | null

type TMachineComparisonContext = {
  addMachine: (machine: Machine) => void
  removeMachine: (machineGroupId: string) => void
  enterCompareMode: (machineType: MachineType) => void
  leaveCompareMode: () => void
  toggleCompareMode: (machineType: MachineType) => void
  isCompareModeEnabled: boolean
  machines: Machine[]
  setCurrentMachineType: (machineType: MachineType) => void
  isSelectedForComparison: (machine: Machine) => boolean
  comparisonAlertType: TComparisonAlertType
  dismissAlert: () => void
  maxMachinesToCompare: number
  readyForComparison: boolean
}
export const MachineComparisonContext =
  createContext<TMachineComparisonContext>({
    addMachine: noop,
    removeMachine: noop,
    enterCompareMode: noop,
    leaveCompareMode: noop,
    toggleCompareMode: noop,
    isCompareModeEnabled: false,
    machines: [],
    setCurrentMachineType: noop,
    isSelectedForComparison: () => false,
    comparisonAlertType: null,
    dismissAlert: noop,
    maxMachinesToCompare: 0,
    readyForComparison: false,
  })
