import { loadChunkSize } from '@internal/blocks/SearchResultsBlock/loadChunkSize'
import { maxSearchResults } from '@internal/blocks/SearchResultsBlock/maxSearchResults'
import { SearchResultsSortKey } from '@internal/blocks/SearchResultsBlock/SearchResultsSortKey'
import { TRangeFiltersMap } from '@internal/blocks/SearchResultsBlock/TRangeFiltersMap'
import {
  mappedModelSuffix,
  SearchModel,
} from '@internal/blocks/SearchResultsBlock/TSearchParams'
import { SystemPageKey } from '@internal/page/SystemPageKey'
import { TLocale } from '@internal/utils/machine/i18n/TLocale'
import { MachineType, SubTypes } from '@internal/utils/machine/MachineType'
import { TFilterableBaleChamberOption } from '@internal/utils/search/TFilterableBalerChamberOption'
import { TFilterableHeaderTypeOption } from '@internal/utils/search/TFilterableHeaderTypeOption'
import { TFilterableMachineAttribute } from '@internal/utils/search/TFilterableMachineAttribute'
import { TSearchResultsRouteParams } from './routingTable'
import { urlFor } from './urls/urlFor'

export type SearchResultsParams = Omit<
  TSearchResultsRouteParams['queryParams'],
  | 'machineSubType'
  | 'baleChamber'
  | 'headerType'
  | 'amount'
  | 'brand'
  | 'location'
  | 'feature'
  | 'model'
> & {
  amount?: number
  baleChamber?: TFilterableBaleChamberOption
  headerType?: TFilterableHeaderTypeOption
  rangeFilters?: TRangeFiltersMap
  brands?: string[]
  locations?: string[]
  features?: TFilterableMachineAttribute[]
  sort?: SearchResultsSortKey
  machineType?: MachineType
  machineSubType?: SubTypes[keyof SubTypes]
  model?: SearchModel | undefined
}

const rangeFilterToQuery = (
  attribute: TFilterableMachineAttribute,
  data: {
    min?: number
    max?: number
  }
) => {
  let value =
    data.min || data.max
      ? `${data.min ? Math.floor(data.min) : ''}-${
          data.max ? Math.floor(data.max) : ''
        }`
      : null

  if (!value) {
    return null
  }

  return {
    key: machineAttributeToSlug(attribute),
    value,
  }
}

const machineAttributeToSlug = (attribute: TFilterableMachineAttribute) =>
  attribute.toLowerCase().replace(/_/g, '-')

export const getSearchResultsUrl = (
  locale: TLocale,
  params: Omit<SearchResultsParams, 'machineType'> & {
    machineTypeName?: string
  }
) => {
  const queryParams: TSearchResultsRouteParams['queryParams'] = {
    brand: params.brands,
    amount:
      params.amount && params.amount !== loadChunkSize
        ? Math.min(maxSearchResults, params.amount).toString()
        : undefined,
    location: params.locations?.map((location) => location.toLowerCase()),
    feature: params.features?.map(machineAttributeToSlug),
    sort:
      params.sort !== SearchResultsSortKey.RelevanceAsc
        ? params.sort
        : undefined,
  }

  if (params.brands && params.brands.length === 1 && params.model) {
    if (params.model.type === 'base') {
      queryParams.baseModel = params.model.name
    } else {
      queryParams.model =
        params.model.type === 'mapped'
          ? `${params.model.name}${mappedModelSuffix}`
          : params.model.name
    }
  }

  if (params.machineSubType) {
    queryParams.machineSubType = params.machineSubType.toString()
  }

  if (params.baleChamber) {
    queryParams.baleChamber = params.baleChamber.toString()
  }

  if (params.headerType) {
    queryParams.headerType = params.headerType.toString()
  }

  if (params.rangeFilters) {
    Object.keys(params.rangeFilters).forEach((attribute) => {
      const data = params.rangeFilters![attribute]
      const query = rangeFilterToQuery(
        attribute as TFilterableMachineAttribute,
        data
      )
      if (query) {
        ;(queryParams as any)[query.key] = query.value
      }
    })
  }

  const url = urlFor(locale, {
    key: SystemPageKey.SearchResults,
    machineTypeName: params.machineTypeName,
    queryParams,
  })

  return url
}
