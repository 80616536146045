import { MachineType } from './MachineType'
import { Machine } from './Machine'

export enum FinancingProvider {
  Dll = 'dll',
  EFarm = 'e-farm',
}

export const countries = [
  { countryCode: 'ES', provider: FinancingProvider.Dll },
  { countryCode: 'LT', provider: FinancingProvider.EFarm },
  // { countryCode: 'RO', provider: FinancingProvider.EFarm },
  { countryCode: 'IT', provider: FinancingProvider.Dll },
]

export const getLeasingProvider = (countryCode: string | null | undefined) => {
  if (!countryCode) {
    return null
  }
  return (
    countries.find(({ countryCode: code }) => code === countryCode)?.provider ??
    null
  )
}

export const isLeasingQualifiedCountry = (
  countryCode: string | null | undefined
) =>
  !!countryCode
    ? countries.some(({ countryCode: code }) => code === countryCode)
    : false

// requirements for leasing qualification for all countries but Romania
const getMachineQualificationRequirements = (
  machineType: MachineType,
  engineHp: number
): {
  maxFinancialLeasingAge: number
  maxHours?: number
} | null => {
  switch (machineType) {
    case MachineType.TRACTOR:
      return engineHp > 140
        ? { maxHours: 13200, maxFinancialLeasingAge: 20 }
        : { maxHours: 8200, maxFinancialLeasingAge: 20 }

    case MachineType.COMBINE_HARVESTER:
      return { maxHours: 1800, maxFinancialLeasingAge: 20 }

    case MachineType.BALER:
      return { maxFinancialLeasingAge: 12 }

    case MachineType.FORAGE_HARVESTER_SELF_PROPELLED:
      return { maxHours: 3300, maxFinancialLeasingAge: 12 }

    case MachineType.SPRAYER:
      return { maxFinancialLeasingAge: 15 }

    case MachineType.TELEHANDLER:
      return { maxHours: 5000, maxFinancialLeasingAge: 15 }

    case MachineType.BEET_HARVESTING_EQUIPMENT:
      return { maxFinancialLeasingAge: 12 }

    case MachineType.POTATO_HARVESTER:
      return { maxFinancialLeasingAge: 12 }

    case MachineType.GRAPE_HARVESTER:
      return { maxFinancialLeasingAge: 12 }

    case MachineType.WHEEL_LOADER:
      return { maxFinancialLeasingAge: 15 }
  }

  return null
}

const isQualified = (machine: Machine, userCountry: string) => {
  if (
    !machine.enginePower ||
    !machine.yearOfProduction ||
    !machine.engineHours
  ) {
    return false
  }
  const currentYear = new Date().getFullYear()
  const machineAge = currentYear - machine.yearOfProduction

  if (userCountry === 'IT' && !machine.serp.countries['IT']) {
    return false
  }

  // if (userCountry === 'RO') {
  //   if (machineAge <= 7) {
  //     switch (machine.machineType) {
  //       case MachineType.COMBINE_HARVESTER:
  //         return machine.engineHours <= 2500
  //       default:
  //         return machine.engineHours <= 5000
  //     }
  //   }

  //   return false
  // }

  const minContractDuration = 3
  const requirements = getMachineQualificationRequirements(
    machine.machineType,
    machine.enginePower
  )

  if (!requirements) {
    return false
  }

  const { maxHours, maxFinancialLeasingAge } = requirements
  const machineAgeOk = machineAge < maxFinancialLeasingAge - minContractDuration

  if (!maxHours && machineAgeOk) {
    return true
  }
  if (maxHours && machineAgeOk && machine.engineHours <= maxHours) {
    return true
  }

  return false
}

const getMontylyRate = (machinePrice: number, country: string) => {
  let interestRate = 0.0689
  switch (country) {
    case 'RO':
      interestRate = 0.07
      break
    case 'LT':
      interestRate = 0.03
      break
  }

  const downPaymentPrecentage = 0.1
  const interestRatePerMonth = interestRate / 12
  const numberOfLeasingYears = 4
  const financingVolume = machinePrice - machinePrice * downPaymentPrecentage
  const dllPaymentProtectionPrecentagePrice = (financingVolume * 0.009) / 12
  const pvif = Math.pow(1 + interestRatePerMonth, numberOfLeasingYears * 12)

  return (
    (interestRatePerMonth * (financingVolume * pvif)) / (pvif - 1) +
    dllPaymentProtectionPrecentagePrice
  )
}

export type FinancingData = { provider: FinancingProvider; monthlyRate: number }
export type FinancingCountriesMap = Record<string, FinancingData>
export const getLeasingQualification = (
  machine: Machine
): FinancingCountriesMap => {
  return countries.reduce<FinancingCountriesMap>(
    (acc, { countryCode, provider }) => {
      if (machine.price && isQualified(machine, countryCode)) {
        return {
          ...acc,
          [countryCode]: {
            provider,
            monthlyRate: getMontylyRate(machine.price, countryCode),
          },
        }
      }
      return acc
    },
    {}
  )
}
