import { SettingsContext } from '@internal/page/SettingsContext'
import { TSalesManager } from '@internal/services/TSalesManager'
import { MailOutlined, PhoneCallbackOutlined } from '@mui/icons-material'
import { Button } from '@renderer-ui-library/atoms'
import { useMediaQuery } from '@renderer-ui-library/mui/utils/mediaQuery/useMediaQuery'
import { ContactWizardContext } from '@renderer-ui-library/organisms'
import { localeNamespace } from '@internal/utils/machine/i18n/Locale'
import { TLocale } from '@internal/utils/machine/i18n/TLocale'
import { translations } from 'i18n/translations'
import { useTranslation } from 'next-i18next'
import React, { SyntheticEvent, useCallback, useContext, useMemo } from 'react'
import { MachineContext } from 'utils/machine/MachineContext'
import { TPageType } from 'utils/page/TPageType'
import { tracker } from 'utils/tracking/tracker'
import styles from './contactButtons.module.scss'

interface Props {
  locale: TLocale
  pageType: TPageType
  salesManager: TSalesManager | null
  availability?: boolean
}

export const ContactButtons: React.FC<Props> = React.memo(
  ({ salesManager, locale }) => {
    const { t } = useTranslation(localeNamespace.common)
    const { isMobile } = useMediaQuery()

    const { openContactUs, openCallBackOption } =
      useContext(ContactWizardContext)

    const { machine } = useContext(MachineContext)

    const handlePhoneClick = React.useCallback(
      (e: SyntheticEvent) => {
        tracker.trackEvent({
          name: 'call-click',
          target_name: 'floating_button',
          machine_group_id: machine?.machineGroupId,
          is_interaction: true,
        })
      },
      [machine]
    )

    const handleMessageButtonClick = React.useCallback(
      (e: SyntheticEvent) => {
        tracker.trackEvent({
          name: 'click',
          target_name: 'contact.floating_button.message',
          machine_group_id: machine?.machineGroupId,
          is_interaction: true,
        })
        openContactUs()
      },
      [machine, openContactUs]
    )

    const renderMessageOrRequestInfoButton = useMemo(() => {
      return salesManager?.acceptsMessages ? (
        <Button variant='outlined' fullWidth onClick={handleMessageButtonClick}>
          <MailOutlined className={styles.iconButton} />
          {t(translations.sendMessageButtonText)}
        </Button>
      ) : null
    }, [salesManager?.acceptsMessages, handleMessageButtonClick, t])

    const handleOpenCallBackOption = useCallback(() => {
      openCallBackOption('callback-form-contact-cta')
    }, [openCallBackOption])

    const { customerServiceNumbers } = useContext(SettingsContext)
    const serviceNumber = customerServiceNumbers[locale]

    return (
      <div className={styles.buttonsContainer}>
        {renderMessageOrRequestInfoButton}

        {salesManager && isMobile && (
          <Button
            variant='outlined'
            fullWidth
            onClick={handleOpenCallBackOption}
          >
            <PhoneCallbackOutlined className={styles.iconButton} />
            {t(translations.requestCallbackButtonText)}
          </Button>
        )}

        <Button
          fullWidth
          variant='outlined'
          onClick={handlePhoneClick}
          href={`tel:${serviceNumber?.linkNumber}`}
          component='div'
        >
          <MailOutlined className={styles.iconButton} />
          <span>
            {`${t(translations.machineDetailsContactCallText)} ${
              serviceNumber?.displayNumber ?? ''
            }`}
          </span>
        </Button>
      </div>
    )
  }
)

ContactButtons.displayName = 'ContactButtons'
