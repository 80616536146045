import { TLocale } from '@internal/utils/machine/i18n/TLocale'
import React from 'react'
import { TNavigation } from 'utils/navigation/TNavigation'
import { TSearchParams } from '../SearchOverlay/SearchOverlay'
import { MobileLogoBar } from './MobileLogoBar/MobileLogoBar'
import { MobileNavigationBar } from './MobileNavigationBar/MobileNavigationBar'

interface Props {
  primaryNavigation: TNavigation
  secondaryNavigation: TNavigation
  footerNavigation: TNavigation
  brandsNavigation: TNavigation
  locale: TLocale
  searchParams: TSearchParams
}

export const MobileHeader: React.FC<Props> = React.memo((props) => {
  const {
    locale,
    primaryNavigation,
    secondaryNavigation,
    footerNavigation,
    brandsNavigation,
    searchParams,
  } = props

  return (
    <>
      <MobileLogoBar locale={locale} />
      <MobileNavigationBar
        primaryNavigation={primaryNavigation}
        secondaryNavigation={secondaryNavigation}
        brandsNavigation={brandsNavigation}
        footerNavigation={footerNavigation}
        locale={locale}
        searchParams={searchParams}
      />
    </>
  )
})

MobileHeader.displayName = 'MobileHeader'
