import { TSalesManager } from '@internal/services/TSalesManager'
import { Typography } from '@renderer-ui-library/mui'
import { lightPetrol } from '@renderer-ui-library/mui/base/colors.module.scss'
import { localeNamespace } from '@internal/utils/machine/i18n/Locale'
import { TLocale } from '@internal/utils/machine/i18n/TLocale'
import { translations } from 'i18n/translations'
import { useTranslation } from 'next-i18next'
import React from 'react'
import { getResponsiveSmallMediaSrc } from 'utils/media/getMediaSrc'
import { MachineDetailsContactButtons } from './MachineDetailsContactButtons'
import styles from './machineDetailsContact.module.scss'

interface Props {
  salesManagers: TSalesManager[]
  machineGroupId: string
  locale: TLocale
}

export const MachineDetailsContactInfo: React.FC<Props> = React.memo(
  ({ salesManagers, machineGroupId, locale }) => {
    const { t } = useTranslation(localeNamespace.common)

    return (
      <div className={styles.contactWrapper}>
        <div className={styles.headlineWrapper}>
          <Typography
            variant='h6'
            className={styles.contactHeadline}
            sx={{ textTransform: 'uppercase' }}
          >
            {salesManagers.length > 1
              ? t(translations.yourContacts)
              : t(translations.yourContact)}
          </Typography>
        </div>

        <div className={styles.contactInfoContainerDesktop}>
          {salesManagers.map((contact, index) => (
            <div className={styles.contactInfo} key={index}>
              <div className={styles.imageWrapper}>
                <img
                  loading='lazy'
                  key={contact.avatarImage.data.id}
                  alt={contact.avatarImage.data.attributes.alternativeText}
                  {...getResponsiveSmallMediaSrc(
                    {
                      data: {
                        id: contact.avatarImage.data.id,
                        attributes: contact.avatarImage.data.attributes,
                      },
                    },
                    {
                      ratio: 1 / 1,
                    }
                  )}
                  sizes='400px'
                />
              </div>
              <div>
                <Typography
                  variant='h6'
                  color='primary'
                  textTransform='uppercase'
                  className={styles.contactPersonName}
                >
                  {`${contact.firstName} ${contact.lastName}`}
                </Typography>
                <Typography variant='subtitle2' color={lightPetrol}>
                  {contact.jobTitle}
                </Typography>
              </div>
            </div>
          ))}

          {salesManagers.length > 0 && (
            <MachineDetailsContactButtons
              isMobile={false}
              contact={salesManagers[0]}
              machineGroupId={machineGroupId}
              locale={locale}
            />
          )}
        </div>
      </div>
    )
  }
)

MachineDetailsContactInfo.displayName = 'MachineDetailsContactInfo'
