import { RadioOption } from '@renderer-ui-library/atoms'
import { localeNamespace } from '@internal/utils/machine/i18n/Locale'
import { SearchResultsSortKey } from 'blocks/SearchResultsBlock/SearchResultsSortKey'
import { translations } from 'i18n/translations'
import { useTranslation } from 'next-i18next'
import { useMemo } from 'react'

export const useFilterBarOptions = () => {
  const { t } = useTranslation(localeNamespace.common)

  const options = useMemo<RadioOption[]>(
    () => [
      {
        value: SearchResultsSortKey.RelevanceAsc,
        label: t(translations.searchResultsSortingRelevance),
      },
      {
        value: SearchResultsSortKey.PriceDesc,
        label: t(translations.searchResultsSortingPriceDesc),
      },
      {
        value: SearchResultsSortKey.PriceAsc,
        label: t(translations.searchResultsSortingPriceAsc),
      },
      {
        value: SearchResultsSortKey.HoursAsc,
        label: t(translations.searchResultsSortingHoursAsc),
      },
      {
        value: SearchResultsSortKey.YearAsc,
        label: t(translations.searchResultsSortingYearAsc),
      },
      {
        value: SearchResultsSortKey.YearDesc,
        label: t(translations.searchResultsSortingYearDesc),
      },
      {
        value: SearchResultsSortKey.Latest,
        label: t(translations.searchResultsSortingLatest),
      },
    ],
    [t]
  )

  return { options }
}
