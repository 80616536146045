import { TMachineCategory } from '@internal/utils/machine/useMachineCategoryMap'
import { useMachineTypeName } from '@internal/utils/machine/useMachineTypeName'
import { PageTypeKey } from '@internal/utils/page/PageTypeKey'
import { urlFor } from '@internal/utils/routing/urls/urlFor'
import { Typography } from '@renderer-ui-library/mui'
import { TLocale } from '@internal/utils/machine/i18n/TLocale'
import Link from 'next/link'
import React from 'react'
import styles from './machineCategoryPagesListingBlock.module.scss'

type MachineCategoryEntryProps = {
  category: TMachineCategory
  locale: TLocale
}
export const MachineCategoryEntry: React.FC<MachineCategoryEntryProps> =
  React.memo(({ category, locale }) => {
    const { getMachineTypeName } = useMachineTypeName()
    return (
      <div className={styles.entryContainer}>
        <h2 key={category.name}>{category.name}</h2>
        <ul className={styles.list}>
          {category.machines.map((machine) => (
            <li key={machine.name}>
              <Link
                href={urlFor(locale, {
                  key: PageTypeKey.MachineCategoryPage,
                  machineTypeName: getMachineTypeName(
                    machine.machineType,
                    'plural'
                  ),
                })}
              >
                <Typography
                  variant='body1'
                  className={styles.link}
                  component='span'
                >
                  {machine.name}
                </Typography>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    )
  })

MachineCategoryEntry.displayName = 'MachineCategoryEntry'
