import { TSalesManager } from '@internal/services/TSalesManager'
import { useMediaQuery } from '@renderer-ui-library/mui/utils/mediaQuery/useMediaQuery'
import { TLocale } from '@internal/utils/machine/i18n/Locale'
import React from 'react'
import { createPortal } from 'react-dom'
import { MachineDetailsContactButtons } from './MachineDetailsContactButtons'
import { MachineDetailsContactInfo } from './MachineDetailsContactInfo'
import styles from './machineDetailsContact.module.scss'

interface Props {
  salesManagers: TSalesManager[]
  machineGroupId: string
  locale: TLocale
}

export const MachineDetailsContact: React.FC<Props> = React.memo(
  ({ salesManagers, machineGroupId, locale }) => {
    const { isMobile } = useMediaQuery()

    if (salesManagers.length === 0) {
      return null
    }

    return (
      <>
        {!isMobile ? (
          <MachineDetailsContactInfo
            salesManagers={salesManagers}
            machineGroupId={machineGroupId}
            locale={locale}
          />
        ) : (
          <>
            <MachineDetailsContactButtons
              isMobile
              contact={salesManagers[0]}
              machineGroupId={machineGroupId}
              locale={locale}
            />
            {createPortal(
              <div className={styles.mobilButtonsOverlay} />,
              document.body
            )}
          </>
        )}
      </>
    )
  }
)

MachineDetailsContact.displayName = 'MachineDetailsContact'
