import { Language, Mail, Phone } from '@mui/icons-material'
import { Grid, Typography } from '@renderer-ui-library/mui'
import { BlockWrapper } from '@renderer-ui-library/scaffolding/BlockWrapper/BlockWrapper'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import { BlockProps } from 'blocks/BlockProps'
import classNames from 'classnames'
import { memo, useCallback, useContext, useMemo } from 'react'
import { ISpecialistColumnsBlockData } from './ISpecialistColumnsBlockData'
import styles from './specialistColumnsBlock.module.scss'

import { SettingsContext } from '@internal/page/SettingsContext'
import { BlockTopText, Media, TMediaData } from '@renderer-ui-library/molecules'
import { capitalize } from 'lodash'
import Link from 'next/link'
import { MachineContext } from 'utils/machine/MachineContext'
import { tracker } from 'utils/tracking/tracker'

export const SpecialistColumnsBlock: React.FC<
  BlockProps<ISpecialistColumnsBlockData>
> = memo((props) => {
  const { machine } = useContext(MachineContext)

  const handlePhoneClick = useCallback(
    () =>
      tracker.trackEvent({
        name: 'call-click',
        target_name: 'specialist',
        is_interaction: true,
        machine_group_id: machine?.machineGroupId,
      }),
    [machine]
  )

  const { customerServiceNumbers } = useContext(SettingsContext)

  const columns = useMemo(
    () =>
      props.data.columns
        .map((column) => {
          const managerAttributes = column.salesManager.data?.attributes

          if (managerAttributes === undefined) {
            return null
          }

          const serviceNumber =
            customerServiceNumbers[managerAttributes.countries[0]?.locale]
          const languages = managerAttributes.languagesSpoken
            .split(',')
            .map((l) => capitalize(l.trim()))
            .join(', ')

          const avatarData: TMediaData = {
            id: '',
            videoUrl: null,
            files: { data: [managerAttributes.avatarImage.data] },
          }
          return {
            column,
            serviceNumber,
            languages,
            avatarData,
            managerAttributes,
          }
        })
        .filter(Boolean),
    [props.data.columns, customerServiceNumbers]
  )

  return (
    <BlockWrapper blockData={props.data}>
      {(props.data.topText || props.data.title) && (
        <BlockTopText
          title={props.data.title}
          text={props.data.topText}
          highlight={props.data.highlight}
        />
      )}
      <div className={styles.gridSlider}>
        <Splide
          options={{
            type: 'loop',
            heightRatio: 2 / 3,
            pagination: true,
            perPage: 3,
            gap: '2.5rem',
            pauseOnHover: true,
            pauseOnFocus: true,
            height: 'auto',
            autoplay: false,
            rewind: true,
            arrows: false,
            breakpoints: {
              768: {
                perPage: 1,
                gap: '1rem',
                padding: {
                  right: '4rem',
                },
              },
            },
          }}
        >
          {columns.filter(Boolean).map((data) => {
            const {
              avatarData,
              column,
              languages,
              serviceNumber,
              managerAttributes,
            } = data!

            return (
              <SplideSlide key={column.id}>
                <Grid
                  item
                  xs={12}
                  md={4}
                  key={column.id}
                  className={classNames(styles.gridItem, {
                    [styles.highlight]: props.data.highlight,
                  })}
                >
                  <div className={styles.cardContainer}>
                    <div>
                      <div className={styles.profilePictureContainer}>
                        <Media
                          media={avatarData}
                          ratio={1}
                          desktopViewportPercentage={4}
                        />
                      </div>

                      <div className={styles.titleContainer}>
                        <Typography
                          variant='h6'
                          textAlign='center'
                          className={styles.title}
                        >
                          {managerAttributes.bioTitle}
                        </Typography>
                      </div>
                      <Typography variant='body1' textAlign='center'>
                        {managerAttributes.bioText}
                      </Typography>
                    </div>

                    <div className={styles.contactInfoContainer}>
                      <div className={styles.contactInfoItem}>
                        <div className={styles.contactInfoKey}>
                          <Language className={styles.contactInfoIcon} />
                          <Typography variant='body2'>{languages}</Typography>
                        </div>
                      </div>

                      {serviceNumber && (
                        <div className={styles.contactInfoItem}>
                          <div
                            className={styles.contactInfoKey}
                            onClick={handlePhoneClick}
                          >
                            <Phone className={styles.contactInfoIcon} />
                            <Typography variant='body2'>
                              <Link
                                href={`tel:${serviceNumber.linkNumber}`}
                                className={styles.link}
                              >
                                {serviceNumber.displayNumber}
                              </Link>
                            </Typography>
                          </div>
                        </div>
                      )}

                      <div className={styles.contactInfoItem}>
                        <div className={styles.contactInfoKey}>
                          <Mail className={styles.contactInfoIcon} />
                          <Typography variant='body2'>
                            <Link
                              href={`mailto:${managerAttributes.email}`}
                              className={styles.link}
                            >
                              {managerAttributes.email}
                            </Link>
                          </Typography>
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>
              </SplideSlide>
            )
          })}
        </Splide>
      </div>
    </BlockWrapper>
  )
})

SpecialistColumnsBlock.displayName = 'SpecialistColumnsBlock'
