import { translations } from '@internal/i18n/translations'
import { RequestSubmissionFormType } from '@internal/utils/tracking/TTrackingEvent'
import { DynamicMultipleFloatingButton } from '@renderer-ui-library/molecules'
import { localeNamespace } from '@internal/utils/machine/i18n/Locale'
import { TLocale } from '@internal/utils/machine/i18n/TLocale'
import { useTranslation } from 'next-i18next'
import React, { useCallback, useContext, useState } from 'react'
import { IForms } from 'services/convertFormsPayload'
import { SalesManagerContext } from 'utils/SalesManager/SalesManagerContext'
import { MachineContext } from 'utils/machine/MachineContext'
import { TPageType } from 'utils/page/TPageType'
import { trackConversionIntention } from 'utils/tracking/trackConversionIntention'
import { tracker } from 'utils/tracking/tracker'
import { DynamicCallBackWizard } from '../CallBackFormWizard/dynamicCallBackWizard'
import { MachinePreviewImage } from '../MachinePreviewImage/MachinePreviewImage'
import { DynamicRequestContactWizard } from '../RequestContactWizard/dynamicRequestContactWizard'
import { DynamicRequestLeasingOptionWizard } from '../RequestLeasingOptionWizard/dynamicRequestLeasingOptionWizard'
import { ContactWizardContext } from './ContactWizardContext'

interface ContactWizardProviderProps {
  locale: TLocale
  pageType: TPageType
  formsData: IForms
  children?: React.ReactNode
}

export const ContactWizardProvider: React.FC<ContactWizardProviderProps> =
  React.memo(({ children, locale, pageType, formsData }) => {
    const { machine } = useContext(MachineContext)
    const { salesManagers } = useContext(SalesManagerContext)
    const { t } = useTranslation(localeNamespace.common)

    const [requestInfoOpen, setRequestInfoOpen] = useState(false)
    const [contactUsOpen, setContactUsOpen] = useState(false)
    const [callbackOpen, setCallbackOptionOpen] = useState(false)
    const [showCallbackOption, setShowCallbackOption] = useState(false)
    const [leasingOptionOpen, setLeasingOptionOpen] = useState(false)

    const [trackingPage, setTrackingPage] = useState('pdp')
    const [trackingCallBackOptionType, setTrackingCallBackOptionType] =
      useState<RequestSubmissionFormType>('callback-form-popup')

    const openRequestInfo = useCallback(() => {
      setRequestInfoOpen(true)
      if (machine) {
        trackConversionIntention(machine)
      }
      tracker.trackEvent({
        name: 'click',
        target_name: 'contact.pdp.message',
        is_interaction: true,
      })
    }, [machine])
    const closeRequestInfo = useCallback(() => setRequestInfoOpen(false), [])

    const openContactUs = useCallback(() => setContactUsOpen(true), [])
    const closeContactUs = useCallback(() => setContactUsOpen(false), [])

    const openLeasingOption = useCallback(() => setLeasingOptionOpen(true), [])
    const closeLeasingOption = useCallback(
      () => setLeasingOptionOpen(false),
      []
    )

    const openCallBackOption = useCallback(
      (trackingEventName: RequestSubmissionFormType) => {
        setCallbackOptionOpen(true)
        setShowCallbackOption(true)
        if (machine) {
          trackConversionIntention(machine)
        }

        setTrackingCallBackOptionType(trackingEventName)

        tracker.trackEvent({
          name: 'click',
          target_name: 'contact.pdp.callback',
          is_interaction: true,
        })
      },
      [machine]
    )

    const closeCallBackOption = useCallback(() => {
      setCallbackOptionOpen(false)
      setTimeout(() => setShowCallbackOption(false), 500)
    }, [])

    const trackPageSource = useCallback(
      (page: string) => setTrackingPage(page),
      []
    )

    return (
      <ContactWizardContext.Provider
        value={{
          openRequestInfo,
          openContactUs,
          openLeasingOption,
          trackPageSource,
          openCallBackOption,
        }}
      >
        {children}
        <DynamicMultipleFloatingButton
          locale={locale}
          salesManagers={salesManagers}
          pageType={pageType}
          trackPageSource={trackPageSource}
        />

        {contactUsOpen && (
          <DynamicRequestContactWizard
            open={contactUsOpen}
            locale={locale}
            machine={machine ?? undefined}
            machineImage={
              machine && (
                <MachinePreviewImage machine={machine} locale={locale} />
              )
            }
            onCloseClick={closeContactUs}
          />
        )}

        {leasingOptionOpen && machine && (
          <DynamicRequestLeasingOptionWizard
            open={leasingOptionOpen}
            locale={locale}
            machine={machine}
            machineImage={
              <MachinePreviewImage machine={machine} locale={locale} />
            }
            onCloseClick={closeLeasingOption}
          />
        )}

        {requestInfoOpen && machine && (
          <DynamicRequestContactWizard
            open={requestInfoOpen}
            locale={locale}
            machine={machine}
            machineImage={
              <MachinePreviewImage machine={machine} locale={locale} />
            }
            onCloseClick={closeRequestInfo}
          />
        )}

        {salesManagers &&
          salesManagers.length > 0 &&
          formsData &&
          showCallbackOption && (
            <DynamicCallBackWizard
              locale={locale}
              open={callbackOpen}
              pageType={pageType}
              salesManager={salesManagers[0]}
              callBackTopics={formsData.callBackTopics}
              title={t(translations.requestCallBackWizardSubTitle)}
              description={t(translations.requestCallBackWizardDescription)}
              onClose={closeCallBackOption}
              machineGroupId={machine?.machineGroupId}
              trackingPage={trackingPage}
              trackingCallBackOptionType={trackingCallBackOptionType}
            />
          )}
      </ContactWizardContext.Provider>
    )
  })

ContactWizardProvider.displayName = 'ContactWizardProvider'
