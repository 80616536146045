import { KnownBrands } from '@internal/utils/machine/KnownBrands'
import React, { useMemo } from 'react'
import { TNavigation } from 'utils/navigation/TNavigation'
import { navigationToNavigationEntriesKeyMap } from 'utils/navigation/navigationToNavigationEntriesKeyMap'
import { BrandButton } from '../../atoms/BrandButton/BrandButton'
import styles from './popularTractorBrands.module.scss'

type Props = {
  navigation: TNavigation
  onEntryClick: () => void
}

const data = {
  fendt: KnownBrands.Fendt,
  claas: KnownBrands.Claas,
  'massey-ferguson': KnownBrands.Massey_Ferguson,
  'new-holland': KnownBrands.New_Holland,
  'case-ih': KnownBrands.Case_IH,
  'deutz-fahr': KnownBrands.Deutz_Fahr,
  kubota: KnownBrands.Kubota,
  'john-deere': KnownBrands.John_Deere,
}

type TBrandKeys = keyof typeof data

export const PopularTractorBrands: React.FC<Props> = ({
  navigation,
  onEntryClick,
}) => {
  const map = useMemo(
    () => navigationToNavigationEntriesKeyMap<TBrandKeys>(navigation),
    [navigation]
  )
  return (
    <div className={styles.wrapper}>
      {Object.keys(data).map((key) => {
        const brandKey = key as TBrandKeys
        const navigationEntry = map[brandKey]

        return (
          <React.Fragment key={brandKey}>
            {navigationEntry && (
              <div className={styles.button}>
                <BrandButton
                  variant='outlined'
                  onClick={onEntryClick}
                  brand={data[brandKey]}
                  route={navigationEntry.route}
                />
              </div>
            )}
          </React.Fragment>
        )
      })}
    </div>
  )
}
