import { useFeatureFlag } from '@internal/utils/featureFlags/useFeatureFlag'
import { useMachineTypeName } from '@internal/utils/machine/useMachineTypeName'
import { PageTypeKey } from '@internal/utils/page/PageTypeKey'
import { urlFor } from '@internal/utils/routing/urls/urlFor'
import { Menu } from '@mui/icons-material'
import { TLocale } from '@internal/utils/machine/i18n/TLocale'
import { MachineType } from '@internal/utils/machine/MachineType'
import React, { useCallback, useMemo, useState } from 'react'
import { createPortal } from 'react-dom'
import { TNavigation } from 'utils/navigation/TNavigation'
import { MobileMenu } from '../../MobileMenu/MobileMenu'
import { SearchAutosuggest } from '../../SearchBar/SearchAutosuggest/SearchAutosuggest'
import { SearchOverlay, TSearchParams } from '../../SearchOverlay/SearchOverlay'
import styles from './mobileNavigationBar.module.scss'

interface Props {
  primaryNavigation: TNavigation
  secondaryNavigation: TNavigation
  footerNavigation: TNavigation
  brandsNavigation: TNavigation
  locale: TLocale
  searchParams: TSearchParams
}

export const MobileNavigationBar: React.FC<Props> = React.memo((props) => {
  const {
    locale,
    primaryNavigation,
    secondaryNavigation,
    footerNavigation,
    brandsNavigation,
    searchParams,
  } = props

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const showMobileMenu = useCallback(() => setMobileMenuOpen(true), [])
  const hideMobileMenu = useCallback(() => setMobileMenuOpen(false), [])
  const { getMachineTypeName } = useMachineTypeName()
  const allOffersUrl = useMemo(
    () =>
      urlFor(locale, {
        key: PageTypeKey.MachineCategoryPage,
        machineTypeName: getMachineTypeName(MachineType.TRACTOR, 'plural'),
      }),
    [getMachineTypeName, locale]
  )
  const { hasFeature } = useFeatureFlag()
  const isHeaderSearchInputEnabled = hasFeature('headerSearchInput')

  return (
    <>
      <header className={styles.mobileNavigationBar}>
        <div
          className={styles.mobileMenuButton}
          tabIndex={1}
          onClick={showMobileMenu}
        >
          <Menu color='inherit' fontSize='inherit' />
        </div>
        {isHeaderSearchInputEnabled && <SearchAutosuggest locale={locale} />}
        {!isHeaderSearchInputEnabled && (
          <SearchOverlay searchParams={searchParams} />
        )}
      </header>
      {createPortal(
        <MobileMenu
          open={mobileMenuOpen}
          onCloseClick={hideMobileMenu}
          primaryNavigation={primaryNavigation}
          secondaryNavigation={secondaryNavigation}
          brandsNavigation={brandsNavigation}
          footerNavigation={footerNavigation}
          locale={props.locale}
          allOffersUrl={allOffersUrl}
        />,
        document.body
      )}
    </>
  )
})

MobileNavigationBar.displayName = 'MobileNavigationBar'
