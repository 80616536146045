import { SystemPageKey } from '@internal/page/SystemPageKey'
import { getGeoLocation } from '@internal/utils/geoLocation/getGeoLocation'
import { logger } from '@internal/utils/logger/logger'
import { DefaultQueryParams } from '@internal/utils/routing/urls/DefaultQueryParams'
import { TLocale } from '@internal/utils/machine/i18n/TLocale'
import Cookies from 'cookies'
import type { GetServerSideProps, NextPage } from 'next'
import { BasePage } from 'page/BasePage'
import { TPageProps } from 'page/TPageProps'
import React from 'react'
import { RequestCombiner } from 'services/RequestCombiner'
import { getRedirect } from 'services/cmsClient'
import { defaultLocale, undefinedLocale } from 'utils/constants'
import { initFeatureFlags } from 'utils/featureFlags/initFeatureFlags'
import { getMachineTypeForMachineTypeSlug } from 'utils/machine/getMachineTypeForMachineTypeSlug'
import { PageTypeKey } from 'utils/page/PageTypeKey'
import { getPageProps } from 'utils/page/getPageProps'
import { getRequestIp, isValidIp } from 'utils/request/getRequestIp'
import * as router from 'utils/routing/router'
import { urlFor } from 'utils/routing/urls/urlFor'

export const getServerSideProps: GetServerSideProps<
  TPageProps,
  DefaultQueryParams
> = async (context) => {
  const { slugs, ff, ...restQueryParams } = context.query as DefaultQueryParams
  const queryParams = restQueryParams
  // allow override of country via query param for testing
  const countryOverride = queryParams.country?.toUpperCase() ?? null
  // allow override of ip via query param for testing
  const derivedIp = queryParams.ip ?? getRequestIp(context.req)
  const ip = isValidIp(derivedIp ?? '') ? derivedIp : null
  const host = context.req.headers.host ?? null
  const locale =
    context.locale === undefinedLocale
      ? (defaultLocale as TLocale)
      : (context.locale as TLocale)
  const location = getGeoLocation(locale)
  const requestCombiner = new RequestCombiner()

  try {
    const url = context.resolvedUrl.split('?')[0]
    const slug = Array.from(slugs ?? []).join('/')

    const featureFlags = initFeatureFlags(
      new Cookies(context.req, context.res),
      ff
    )

    // 1 SYSTEM PAGE ____________________________________________________________
    const systemPageRoutingResult = router.matchSystemPage(
      urlFor(locale, url),
      queryParams,
      {
        exclude: [SystemPageKey.SearchResults],
      }
    )

    if (systemPageRoutingResult) {
      if (systemPageRoutingResult.systemPageKey === SystemPageKey.PDP) {
        const machineType = getMachineTypeForMachineTypeSlug(
          locale,
          systemPageRoutingResult.pathParams.machineType
        )

        if (machineType) {
          return getPageProps({
            type: PageTypeKey.SystemPage,
            key: SystemPageKey.PDP,
            context,
            queryParams: context.query,
            pathParams: systemPageRoutingResult.pathParams,
            ip,
            host,
            requestCombiner,
            featureFlags,
            location,
            machineType,
            countryOverride,
          })
        }
      } else if (
        systemPageRoutingResult.systemPageKey !== SystemPageKey.SearchResults
      ) {
        return getPageProps({
          type: PageTypeKey.SystemPage,
          key: systemPageRoutingResult.systemPageKey,
          context,
          queryParams: context.query,
          pathParams: systemPageRoutingResult.pathParams,
          ip,
          host,
          requestCombiner,
          featureFlags,
          location,
          countryOverride,
        })
      }
    }

    // 2 MACHINE CATEGORY PAGE __________________________________________________
    const machineCategoryPageRoutingResult = router.matchMachineCategoryPage(
      urlFor(locale, url),
      locale,
      queryParams
    )

    if (machineCategoryPageRoutingResult) {
      const machineType = getMachineTypeForMachineTypeSlug(
        locale,
        machineCategoryPageRoutingResult.params.pathParams.machineTypeSlug
      )

      if (machineType) {
        const pageProps = await getPageProps({
          type: PageTypeKey.MachineCategoryPage,
          context,
          machineType,
          queryParams: context.query,
          pathParams: machineCategoryPageRoutingResult.params.pathParams,
          ip,
          host,
          requestCombiner,
          featureFlags,
          location,
          countryOverride,
        })

        if (!(pageProps as any).notFound) {
          return pageProps
        }
      }
    }

    // 3 SEARCH RESULTS _________________________________________________________
    const serpPageRoutingResult = router.matchSystemPage(
      urlFor(locale, url),
      queryParams,
      {
        include: [SystemPageKey.SearchResults],
      }
    )

    if (
      serpPageRoutingResult &&
      serpPageRoutingResult.systemPageKey === SystemPageKey.SearchResults
    ) {
      const machineType = serpPageRoutingResult.pathParams.machineTypeSlug
        ? getMachineTypeForMachineTypeSlug(
            locale,
            serpPageRoutingResult.pathParams.machineTypeSlug
          )
        : null

      if (machineType) {
        const pageProps = await getPageProps({
          type: PageTypeKey.SystemPage,
          key: SystemPageKey.SearchResults,
          context,
          machineType,
          queryParams: context.query,
          pathParams: serpPageRoutingResult.pathParams,
          ip,
          host,
          requestCombiner,
          featureFlags,
          location,
          countryOverride,
        })

        if (!(pageProps as any).notFound) {
          return pageProps
        }
      }
    }

    // 4 SHORT PDP Redirect _____________________________________________________
    const shortPDPRedirectRoutingResult = await router.matchRedirect({
      type: 'short-pdp',
      url: urlFor(locale, url),
      queryParams,
      locale,
      ip,
    })

    if (shortPDPRedirectRoutingResult) {
      return {
        redirect: {
          statusCode: 301,
          destination: shortPDPRedirectRoutingResult.target,
        },
      }
    }

    // 5 CMS PAGE _______________________________________________________________
    const pageProps = await getPageProps({
      type: PageTypeKey.Page,
      slug: encodeURIComponent(slug),
      context,
      queryParams: context.query,
      pathParams: {},
      ip,
      host,
      requestCombiner,
      featureFlags,
      location,
      countryOverride,
    })

    if (!('notFound' in pageProps) || !pageProps.notFound) {
      return pageProps
    }

    // 6 SERP REDIRECTS _________________________________________________________
    const serpRedirectRoutingResult = await router.matchRedirect({
      type: 'serp',
      url: urlFor(locale, url),
      queryParams,
      locale,
      ip,
    })

    if (serpRedirectRoutingResult) {
      return {
        redirect: {
          statusCode: 301,
          destination: serpRedirectRoutingResult.target,
        },
      }
    }

    // 7 CMS REDIRECTS __________________________________________________________
    const redirect = await getRedirect(
      encodeURIComponent(`/${locale}/${slug}/`)
    )
    if (redirect) {
      return {
        redirect: {
          statusCode: 301,
          destination: redirect,
        },
      }
    }

    // 8 ALL OTHER INTERNAL REDIRECTS ___________________________________________
    const redirectRoutingResult = await router.matchRedirect({
      type: 'other',
      url: urlFor(locale, url),
      queryParams,
      locale,
      ip,
    })

    if (redirectRoutingResult) {
      return {
        redirect: {
          statusCode: 301,
          destination: redirectRoutingResult.target,
        },
      }
    }

    // 9 NOT FOUND ______________________________________________________________
    return {
      notFound: true,
    }
  } catch (err) {
    logger.error(new Error('Getting data for page failed', { cause: err }))

    context.res.statusCode = 500

    return getPageProps({
      type: PageTypeKey.SystemPage,
      key: SystemPageKey.InternalServerError,
      context,
      queryParams,
      pathParams: {},
      ip,
      host,
      requestCombiner,
      featureFlags: {},
      location,
      countryOverride,
    })
  }
}

const Page: NextPage<TPageProps> = (props) => <BasePage {...props} />

export default React.memo(Page)
