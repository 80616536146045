import { TFilterOption } from '@renderer-ui-library/molecules'
import { MachineType } from '@internal/utils/machine/MachineType'
import { useMemo } from 'react'
import { AvailableMachineSubTypes } from 'utils/machine/AvailableMachineSubTypes'
import { useMachineSubTypeName } from 'utils/machine/useMachineSubTypeName'

export const useMachineSubTypeOptions = (machineType: MachineType) => {
  const { getMachineSubTypeName } = useMachineSubTypeName()

  const machineSubTypeOptions = useMemo<TFilterOption[]>(() => {
    const subTypes = Object.values(
      AvailableMachineSubTypes[machineType as MachineType]
    )

    const machineSubTypes = subTypes.map((type) => ({
      label: getMachineSubTypeName(type, 1),
      id: type,
    }))

    return [...machineSubTypes]
  }, [getMachineSubTypeName, machineType])

  return { machineSubTypeOptions }
}
