import { Locale } from '@internal/utils/machine/i18n/Locale'
import { TLocalizedUrlMap } from 'i18n/TLocalizedUrlMap'
import { SystemPageKey } from 'page/SystemPageKey'
import { urlFor } from './urlFor'

const localizations: TLocalizedUrlMap = {
  [Locale.de]: urlFor(Locale.de, { key: SystemPageKey.StartPage }),
  [Locale.en]: urlFor(Locale.en, { key: SystemPageKey.StartPage }),
  [Locale.fr]: urlFor(Locale.fr, { key: SystemPageKey.StartPage }),
  [Locale.el]: urlFor(Locale.el, { key: SystemPageKey.StartPage }),
  [Locale.it]: urlFor(Locale.it, { key: SystemPageKey.StartPage }),
  [Locale.pl]: urlFor(Locale.pl, { key: SystemPageKey.StartPage }),
  [Locale.ro]: urlFor(Locale.ro, { key: SystemPageKey.StartPage }),
  [Locale.ru]: urlFor(Locale.ru, { key: SystemPageKey.StartPage }),
  [Locale.hu]: urlFor(Locale.hu, { key: SystemPageKey.StartPage }),
  [Locale.es]: urlFor(Locale.es, { key: SystemPageKey.StartPage }),
  [Locale.bg]: urlFor(Locale.bg, { key: SystemPageKey.StartPage }),
  [Locale.sl]: urlFor(Locale.sl, { key: SystemPageKey.StartPage }),
  [Locale.sr]: urlFor(Locale.sr, { key: SystemPageKey.StartPage }),
  [Locale.da]: urlFor(Locale.da, { key: SystemPageKey.StartPage }),
  [Locale.cs]: urlFor(Locale.cs, { key: SystemPageKey.StartPage }),
  [Locale.lt]: urlFor(Locale.lt, { key: SystemPageKey.StartPage }),
}

export const getLocalizedUrlMapForStartPage = () => localizations
